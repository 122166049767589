import axios from "axios";

const getDashboardDataApi =
    "https://qcaefqcyp9.execute-api.ap-south-1.amazonaws.com/prod/fetchzoneall";

export const getDashboardData = async () => {
    try {
        let data = await axios.get(getDashboardDataApi);
        return data;
    } catch (error) {
        return error;
    }
};
