import { combineReducers } from "redux";
import dashboard from "./dashboardReducer";
import camera from "../../modals/CameraManagement";
import rule from "../../modals/RuleManagement";
import zone from "../../modals/ZoneManagement";
import rules from "./rulesReducer";
import apiCallStatus from "./apiStatusReducer";
import workForce from "../../modals/workForceManagement";
import appData from "../../modals/app";
import users from "../../modals/userManagement";
import shift from "../../modals/ShiftManagement";
import notificationgroup from "../../modals/NotificationGroupManagement";

const rootReducer = combineReducers({
  dashboard,
  rules,
  zone,
  camera,
  apiCallStatus,
  workForce,
  appData,
  users,
  rule,
  shift,
  notificationgroup,
});

export default rootReducer;
