import React, { useEffect, useState } from "react";
import { useStyles } from "../styles/layoutStyles";
import "./preview-camera.css";
import Spinner from "../common/Spinner";
import Axios from "axios";
import { Link } from "react-router-dom";
import { useParams } from "react-router";
import moment from "moment";
import { Modal } from "antd";
import { ViewZoneContainer } from "../preview-zone/styles";

import Table from 'react-bootstrap/Table';
import SearchBar from "../common/searchBar";
import { ArrowBackIos } from "@material-ui/icons";

export function PreviewCamera({ camera_id, history }) {
  const [camera, setCamera] = useState(null);
  const [cnotification, setCnotification] = useState(null);
  const [notifications, setNotifications] = useState(null);
  const [localvalue, setLocalvalue] = useState("");
  const [isShowSpinner, setIsShowSpinner] = useState(false);
  const [info, setInfo] = useState(null);
  camera_id = useParams();
  console.log("abhi zone", camera_id.camera_id);

  const [isShowModal, setIsShowModal] = useState(false);
  const [apiError, setAPIError] = useState(null);

  useEffect(() => {
    if(apiError && isShowModal != true) {
      setIsShowModal(true);
    }
  }, [apiError]);

  useEffect(() => {
    if(isShowModal) {
      Modal.error({
        wrapClassName: 'error-modal',
        title: 'Oops!',
        content: "We're sorry, but something went wrong.",
        onOk() {
          setIsShowModal(false);
        }
      });
    }
  },[isShowModal]);

  useEffect(() => {
    if (localStorage.getItem("role_id") == undefined) history.push("/login");
    else setLocalvalue(localStorage.getItem("role_id"));
  }, []);

  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };

  const formatDate = (date) => {
    if (date) {
      return (
        date.getMonth() + 1 + "/" + date.getDate() + "/" + date.getFullYear()
      );
    }
  };

  const formatTime = (date) => {
    if (date) {
      console.log("abhi ", date);
      let hours = date.getHours();
      let minutes = date.getMinutes();
      let ampm = hours >= 12 ? "pm" : "am";
      hours = hours % 12;
      hours = hours ? hours : 12; // the hour '0' should be '12'
      minutes = minutes < 10 ? "0" + minutes : minutes;
      let strTime = hours + ":" + minutes + " " + ampm;
      return strTime;
    }
  };

  useEffect(() => {
    Axios.post(
      `https://qcaefqcyp9.execute-api.ap-south-1.amazonaws.com/prod/fetchnotificationbycameraid`,
      {
        id: camera_id.camera_id,
      }
    )
      .then((res) => {
        console.log("Preview camera ", res.data.data);
        setCnotification(res.data.data);
        setNotifications(res.data.data);
      })
      .catch((err) => {
        setAPIError(err);
      });
    //Notification
  }, [20000]);

  useEffect(() => {
    setIsShowSpinner(true);
    Axios.post(
      `https://qcaefqcyp9.execute-api.ap-south-1.amazonaws.com/prod/fetchcamerabyid`,
      {
        id: camera_id.camera_id,
      }
    )
      .then((res) => {
        console.log("Preview camera ", res.data.data);
        setIsShowSpinner(false);
        if(res.data && res.data.data && res.data.data.length > 0) {
          setCamera(res.data.data);
        }else{
          setInfo("No Data!");
        }
        
      })
      .catch((err) => {
        setAPIError(err);
      });
    //Notification
  }, [20000]);

  const searchFilter = (searchText) => {
    searchText = searchText.toLowerCase();
    if(cnotification && cnotification.length) {
      const result = cnotification.filter(item => (item.location.toLowerCase()).includes(searchText) 
        || (item.camera_name.toLowerCase()).includes(searchText) 
        || (String(item.camera_id).toLowerCase()).includes(searchText)
        || (String(item.notification_id).toLowerCase()).includes(searchText));
      setNotifications(result);
    }
  }

  const handleGoBack = () => {
    history.push("/dashboard");
  }

  console.log("zone ===>", camera);
  return (
    <ViewZoneContainer>
      {camera && camera.length > 0 && (
        <main className={classes.content}>
          <div className="SMS-LRF-1">
            <ArrowBackIos onClick={() => handleGoBack()} fontSize="large" className="iiq-back-btn"/>
            Pending Notifications - Camera
          </div>
          <div className="preview" style={{paddingTop: 0}}>
            <div>
              {/* <div className="iiq-header">All Pending Anomalies</div> */}
              <div className="iiq--header-w-search-bar">
                <div className="preview-main">
                  <div>
                    <div className="tr">
                      <div className="name">Location</div>
                      <div className="colon">:</div>
                      <div className="val">
                        {camera[0].location ? camera[0].location : "No loaction"}
                      </div>
                    </div>
                    <div className="tr">
                      <div className="name">Camera Name</div>
                      <div className="colon">:</div>
                      <div className="val">
                        {camera[0].camera_name ? camera[0].camera_name : "BRM"}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="iiq-searchbar">
                  <SearchBar changeAction={searchFilter} />
                </div>
              </div>

              <Table striped hover variant="dark" className="notification-table">
                <thead>
                  <tr>
                    <th>Notification Id</th>
                    <th>Event Time</th>
                    {/* <th>Location</th>
                    <th>Camera Name</th> */}
                    {/* <th>Camera</th> */}
                    {/* <th>Thummnail</th> */}
                  </tr>
                </thead>
                <tbody>
                  {notifications && notifications.length ? notifications.map((card) => {
                    return (
                      <tr>
                        <td>
                          <Link to={"/NotificationDescription/" + card.notification_id}>
                            {card.notification_id}
                          </Link>
                        </td>
                        <td>{moment
                            .utc(card.generated_time)
                            .local()
                            .format("DD/MM/YYYY, hh:mm:ss a")} </td>
                        {/* <td>{card.location}</td>
                        <td>{card.camera_name}</td> */}
                        
                        {/* <td>{card.camera_id}</td> */}
                        {/* <td><img src={"https://jswsalem-s3bucket.s3.amazonaws.com/" + card.resource_url}/></td> */}
                        
                      </tr>
                    );
                  })
                  :
                  <tr>
                    <td colSpan="2">
                      <Spinner />
                    </td>
                  </tr>
                }
                </tbody>
              </Table>
            </div>
          </div>
        </main>
      )}
      {isShowSpinner ? <Spinner /> : <div className="flex show-info">{info && info}</div>}
    </ViewZoneContainer>
  );
}

export default PreviewCamera;
