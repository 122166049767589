import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { Modal } from "antd";
import Axios from "axios";
import { useSelector } from "react-redux";
import ImgLogo from "../../assets/JSW-logo.png";
import ImgMenu from "../../assets/menu.jpg";
import ImgUser from "../../assets/user.png";
import ImgArrowDown from "../../assets/arrow-down.jpg";
import Button from "@material-ui/core/Button";
import UserImg from "../../assets/user.png";
import "./Header.css";
import styled from "styled-components";
import { DropdownMenu, MenuItem } from "react-bootstrap-dropdown-menu";
import { ErrorMsg } from "./styles";

import { Refresh, ExitToApp } from "@material-ui/icons";
import moment from "moment";


function Header(props) {
  const [visible, setVisible] = useState(false);
  const [password, setPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [changePassword, setChangePassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [customError, setCustomError] = useState(null);
  // const notifications = useSelector((state) => state.appData.notifications);

  // useEffect(() => {
  //   console.log("notifications => ",  notifications);
  // },[notifications]);
  
  const showModal = () => {
    setVisible(true);
    setLoading(false);
  };
  const handleOk = () => {};

  const handleSubmit = () => {
    setCustomError("");
    setLoading(true);
    if (!changePassword || changePassword.length > 6) {
      setCustomError("Password must contain atleast 6 characters");
      setLoading(false);
      return;
    }
    if (changePassword != confirmPassword) {
      setCustomError("Password does not match");
      setLoading(false);
      return;
    }
    let obj = {
      password: changePassword,
      username: localStorage.getItem("user_name"),
    };
    Axios.post(
      "https://qcaefqcyp9.execute-api.ap-south-1.amazonaws.com/prod/updateplatformuserpassword",
      obj,
      {}
    )
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
    setTimeout(() => {
      setLoading(false);
      setVisible(false);
    }, 3000);
  };
  const handleCancel = (e) => {
    setVisible(false);
  };

  const showChangePasswordModal = () => {
    setVisible(true);
    setPassword(true);
    setLoading(false);
  };

  const handleConfirmPassword = (e) => {
    let value = e.target.value;
    setConfirmPassword(value);
  };
  const handlechangepasswordCancel = (e) => {
    setPassword(false);
  };
  const handleChangePassword = (e) => {
    let value = e.target.value;
    setChangePassword(value);
  };

  const [liveDate, setLiveDate] = useState(null);
  useEffect(() => {
    const interval = setInterval(() => {
      setLiveDate(moment().format('ddd DD-MM-YYYY, h:mm:ss A') + " IST");
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  const bg = {
    overlay: {
      background: "#FFFF00",
    },
  };
  return (
    <div>
      <header className={`header flex ${props.navOpen} `}>
        <div className="left flex">
          <button className="menu" onClick={props.onMenuClick}>
            <img className="lines" src={ImgMenu} alt="Menu Button" />
            <img className="arrow" src={ImgArrowDown} alt="Menu Button" />
          </button>
          {/* <NavLink to="/dashboard" className="logo"> */}
          <img
            src={ImgLogo}
            style={{ width: "90px", marginLeft: "30px" }}
            alt="JSW | Salem Works"
          />
          {/* </NavLink> */}
        </div>
        <div className="right flex" style={{padding: '15px 0'}}>
          <div className="flex" style={{fontSize: '16px'}}>
            { liveDate && liveDate }
          </div>
          <div
            className="refresh-notification-btn flex tooltip-custom"
            onClick={props.refreshNotification}
          >
            <span className="tooltiptext-custom">Refresh</span>
            <Refresh fontSize="large"/>
          </div>
          <div
            className="notification flex"
            onClick={props.onNotificationsClick}
          >
            <div className="icon">
              <svg viewBox="0 0 24 24" aria-hidden="true">
                <path d="M12 22c1.1 0 2-.9 2-2h-4c0 1.1.89 2 2 2zm6-6v-5c0-3.07-1.64-5.64-4.5-6.32V4c0-.83-.67-1.5-1.5-1.5s-1.5.67-1.5 1.5v.68C7.63 5.36 6 7.92 6 11v5l-2 2v1h16v-1l-2-2z"></path>
              </svg>
              {props.notificationCount > 0 && (
                <span className="count flex">{props.notificationCount}</span>
              )}
            </div>
          </div>
          <div className="flex" style={{
            height: '100%',
            marginLeft: '15px'
          }}>
            <div>{localStorage.getItem("user_name")}</div>
          </div>
          <div className="flex" style={{
            height: '100%',
            marginLeft: '15px',
            borderLeft: '1px solid #151a30'
          }}>
            <NavLink
                  onClick={props.onLogOut}
                  to="/login"
                  className="user flex"
            >
              <ExitToApp />
            </NavLink>
          </div>
        </div>
      </header>
      <Modal
        title="Profile"
        visible={visible}
        onOk={handleOk}
        onCancel={handleCancel}
        center
        styles={bg}
        footer={[
          <Button key="back" onClick={handleCancel}>
            close
          </Button>,
        ]}
      >
        <table>
          <tr>
            <td style={{ width: "30%", color: "black" }}>User Name</td>
            <td style={{ width: "30%", color: "black" }}>:</td>
            <td style={{ width: "40%", color: "black" }}>
              {" "}
              {localStorage.getItem("user_name")}
            </td>
          </tr>
          <tr>
            <td style={{ width: "30%", color: "black" }}>Department</td>
            <td style={{ width: "30%", color: "black" }}>:</td>
            <td style={{ width: "430%", color: "black" }}>
              {" "}
              {localStorage.getItem("Dept")}
            </td>
          </tr>
          <tr>
            <td style={{ width: "30%", color: "black" }}>Role Name</td>
            <td style={{ width: "30%", color: "black" }}>:</td>
            <td style={{ width: "40%", color: "black" }}>
              {localStorage.getItem("role_name")}
            </td>
          </tr>
          <div className="change-password">
            <a onClick={showChangePasswordModal}>Change Password</a>
          </div>
        </table>
      </Modal>
      {password && (
        <Modal
          title="Change Password"
          visible={visible}
          onCancel={handlechangepasswordCancel}
          footer={[
            <Button key="back" onClick={handlechangepasswordCancel}>
              close
            </Button>,
            <Button
              key="submit"
              type="primary"
              loading={loading}
              onClick={handleSubmit}
            >
              Submit
            </Button>,
          ]}
        >
          <div className="tr">
            <div style={{ width: "40%", color: "black" }}>Password</div>
            <div style={{ width: "10%", color: "black" }}>:</div>
            <input
              style={{ width: "50%", color: "black" }}
              className="val"
              type="password"
              name="password"
              onChange={handleChangePassword}
            />{" "}
            <ErrorMsg>{customError}</ErrorMsg>
          </div>
          <div className="tr">
            <div style={{ width: "40%", color: "black" }}>Confirm Password</div>
            <div style={{ width: "10%", color: "black" }}>:</div>
            <input
              style={{ width: "50%", color: "black" }}
              className="val"
              type="password"
              name="password"
              onChange={handleConfirmPassword}
            />
            <ErrorMsg>{customError}</ErrorMsg>
          </div>
        </Modal>
      )}
    </div>
  );
}

export default Header;
