import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { connect } from "react-redux";
import { useSelector } from "react-redux";
import { Route, Redirect } from "react-router-dom";
import moment from "moment";
import { Modal } from "antd";
import {
  appFetchNotifications,
  appFetchZonesData,
  appFetchDailyNotifications,
} from "../../modals/app/thunk";

import Header from "../Header/Header";
import LeftNavigation from "../LeftNavigation/LeftNavigation";
import Dashboard from "../dashboard/dashboard";
// import MGInPunchDashboard from "../dashboard/MGInPunchDashboard";
// import TGOutPunchDashboard from "../dashboard/TGOutPunchDashboard";
import FaceAuthDashboardList from "../dashboard/faceAuthDashboardList";
import Anomalies from "../dashboard/anomalies";
import Plant from "../dashboard/plant";
import Layout from "../layout/layout";
import PreviewZone from "../preview-zone/preview-zone";
import PreviewCamera from "../preview-camera/preview-camera";
import Users from "../users/users";
import WorkerManager from "../workForce";
import UserManager from "../userManagement";
import SidePanel from "../common/panel";
import PlatformUserManger from "../PlatformUserManagement";
import PlatformSettings from "../PlatformSettings/PlatformSettings";
import PlatformSettingsIIQ from "../PlatformSettingsIIQ/platformsettingsIIQ";
import CameraManagement from "../CameraManagement/CameraManagement";
import RuleManagement from "../RuleManagement/RuleManagement";
import ZoneManagement from "../ZoneManagement/ZoneManagement";
import NotificationGroupManagement from "../NotificationGroupManagement/NotificationGroupManagement";
import NotificationPause from "../NotificationPause";
import notificationReport from "../notification/notificationReport/notificationReport";
// import AttendanceReport from "../AttendanceReport/AttendanceReport.js";
import Report from "../NotificationReport/NotificationReport";
import NotificationDescription from "../notification/notificationDescription/notificationDescription";
import ShiftManagement from "../ShiftManagement/ShiftManagement";
import Tab from "../dashboard/tab";
import { BodySection, MainSection, SubSections } from "./styles";
import SidePanelNotifications from "../notification/sidePanelNotifications";
import NotificationBar from "../common/pushNotification";
import RoomIcon from "@material-ui/icons/Room";
import NotificationsIcon from "@material-ui/icons/Notifications";
import QueryBuilderIcon from "@material-ui/icons/QueryBuilder";
import Reports from "../Reports/Reports";
import Usermaster from "../Usermaster/Usermaster";
import PlatformInitilization from "../PlatformInitilization/PlatformInitilization";
import AddPlantForm from "../PlatformInitilization/AddPlantForm/AddPlantForm";
import ViewPlantForm from "../PlatformInitilization/ViewPlantForm/ViewPlantForm";
import WipMaintenance from "../Wip/WipManagement";
import About from "../about/about";

import Analytics from "../../container/analytics";

function Container({
  fetchNotifications,
  fetchDailyNotifications,
  fetchZones
}) {
  const [navOpen, setNavOpen] = useState("");
  const [notificationsOpen, setNotificationsOpen] = useState(null);
  const [isShowModal, setIsShowModal] = useState(false);
  const notifications = useSelector((state) => state.appData.notifications);
  const dailyNotifications = useSelector(
    (state) => state.appData.dailyNotifications
  );

  const apiError = useSelector(
    (state) => state.appData.apiError
  );


  useEffect(() => {
    fetchNotifications();
    const interval = setInterval(() => {
      fetchNotifications();
    }, 300000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    fetchDailyNotifications();
    const interval = setInterval(() => {
      fetchDailyNotifications();
    }, 300000);
    return () => clearInterval(interval);
  }, []);

  const refreshNotification = () => {
    fetchNotifications();
    fetchDailyNotifications();
  }

  useEffect(() => {
    dailyNotifications &&
      dailyNotifications.map((not) => {
        const { location, message, generated_time, notification_id } = not;
        if (notification_id != localStorage.getItem("notificationID")) {
          localStorage.setItem("notificationID", notification_id);
          toast.info(
            ` ${location} ${message} ${moment(generated_time).format(
              "DD/MM/YYYY, h:mm a"
            )}`
          );
        }
      });
  }, [dailyNotifications]);

  useEffect(() => {
    if(apiError && isShowModal != true) {
      setIsShowModal(true);
    }
  }, [apiError]);

  useEffect(() => {
    if(isShowModal) {
      Modal.error({
        wrapClassName: 'error-modal',
        title: 'Oops!',
        content: "We're sorry, but something went wrong.",
        onOk() {
          setIsShowModal(false);
        }
      });
    }
  },[isShowModal]);

  return (
    <MainSection>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        newestOnTop={true}
        hideProgressBar={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
      />
      <Header
        onMenuClick={() => setNavOpen(navOpen === "" ? "open" : "")}
        onLogOut={() => localStorage.clear()}
        onNotificationsClick={() =>
          setNotificationsOpen(notificationsOpen === null ? "open" : null)
        }
        navOpen={navOpen}
        notificationCount={notifications.length}
        refreshNotification={refreshNotification}
      />
      <NotificationBar />
      <BodySection>
        <LeftNavigation navOpen={navOpen} />
        <SidePanel
          isPanelOpen={Boolean(notificationsOpen)}
          Heading={"Notifications"}
          closeAction={setNotificationsOpen}
        >
          <table>
            <tr>
              <td style={{ fontSize: 12, width: "35%", textAlign: "center" }}>
                <NotificationsIcon />
              </td>
              <td style={{ fontSize: 12, width: "35%", textAlign: "center" }}>
                <RoomIcon />
              </td>
              <td style={{ fontSize: 12, width: "30%", textAlign: "center" }}>
                <QueryBuilderIcon />
              </td>
            </tr>
          </table>

          <SidePanelNotifications notifications={notifications} />
        </SidePanel>
        <SubSections>
          <Route
            path="/dashboard"
            render={(props) => <Dashboard {...props} navOpen={navOpen} />}
          />
          <Route
            path="/add-zone"
            render={(props) => <Layout {...props} navOpen={navOpen} />}
          />
          <Route
            path="/ZoneCameraRule"
            render={(props) => <Layout {...props} navOpen={navOpen} />}
          />
          <Route exact path="/users" component={Users} />
          <Route path="/preview-zone/:zone_id" component={PreviewZone} />
          <Route path="/preview-camera/:camera_id" component={PreviewCamera} />
          <Route exact path="/manage-work-force" component={WorkerManager} />
          <Route exact path="/manage-user" component={UserManager} />
          <Route
            exact
            path="/dashboard-list"
            component={FaceAuthDashboardList}
          />
          <Route path="/about" component={About} />
          <Route
            exact
            path="/Platform-user-management"
            component={PlatformUserManger}
          />
          <Route exact path="/PlatformSettings" component={PlatformSettings} />
          <Route
            exact
            path="/SuperAdminSettings"
            component={PlatformSettingsIIQ}
          />
          <Route exact path="/CameraManagement" component={CameraManagement} />
          <Route exact path="/RuleManagement" component={RuleManagement} />
          <Route exact path="/ZoneManagement" component={ZoneManagement} />
          <Route exact path="/ShiftManagement" component={ShiftManagement} />
          <Route
            exact
            path="/NotificationGroupManagement"
            component={NotificationGroupManagement}
          />
          <Route path="/notification" component={notificationReport} />
          {/* <Route path="/mginpunch" component={MGInPunchDashboard} />
          <Route path="/tgoutpunch" component={TGOutPunchDashboard} /> */}
          <Route path="/report/:id" component={Report} />
          <Route path="/anomalies" component={Anomalies} />
          <Route path="/Reports" component={Reports} />
          <Route path="/Usermaster" component={Usermaster} />
          <Route path="/plant" component={Plant} />
          <Route path="/notificationpause" component={NotificationPause} />
          <Route
            path="/platforminitilization"
            component={PlatformInitilization}
          />
          <Route path="/AddPlantForm" component={AddPlantForm} />
          <Route path="/ViewPlantForm" component={ViewPlantForm} />
          <Route path="/wipmaintenance" component={WipMaintenance} />
          <Route
            path="/NotificationDescription/:notification_id"
            component={NotificationDescription}
          />
          <Route path="/tab/:tab_id" component={Tab} />
          {/* <Route path="/AttendanceReport" component={AttendanceReport} /> */}
          <Route
            exact
            path="/"
            render={(props) => <Redirect {...props} to="/dashboard" />}
          />

          <Route path="/analytics" component={Analytics} />   
        </SubSections>
      </BodySection>
    </MainSection>
  );
}

const mapStateToProps = (state) => {
  return {
    notifications: state.appData,
  };
};

const mapDispatchToProps = {
  fetchNotifications: appFetchNotifications,
  fetchDailyNotifications: appFetchDailyNotifications,
  fetchZones: appFetchZonesData,
};

export default connect(mapStateToProps, mapDispatchToProps)(Container);
