import React from "react";
import { Link } from "react-router-dom";
import ImgNotification from "../../../assets/notification.jpg";
import ImgCamera from "../../../assets/video-camera.png";

import ImgAnomalies from "../../../assets/noanomalies.png";
import { Tooltip } from '@material-ui/core';
import { LocationOnSharp, VideoLibrary, Notifications, VideocamOutlined } from "@material-ui/icons";
import "./Card.css";

export default function DashboardCard({ card, toggleWipScheduler }) {
  
  return (
    <div className={card.notification_count > 0 ? `card ${card.severity}` : `card`} >
      <Link to={"/NotificationDescription/" + card.notification_id}>
        
        <div style={{ textAlign: "center", fontSize: "16px", margin: "5px 0"}}>
          {card.camera_rule ? card.camera_rule : "SMS"} </div>

        <img
          title={card.default_camera_url}
          style={{ WebkitUserSelect: "none", width: "100%", height: "200px" }}
          width="100%"
          src={card.resource_url ?  card.resource_url : ImgAnomalies}
        />

      </Link>

      <div className="info">
        <div className="iiq-card-footer flex-row">
            <div className="flex-column">
              <div className="flex-right-align flex-row">
                <Link to={"/NotificationDescription/" + card.notification_id}>
                  { card.notification_id && card.notification_id }
                </Link>
              </div>
              <div className="flex-right-align flex-row">
                <Link to={"/preview-camera/" + card.camera_id} style={{display: 'inherit'}}>
                  <Notifications style={{ color: '#b4b4b4' }} fontSize="small" />
                  <span className="count flex-right-align m-L4">
                    {card.notification_count ? card.notification_count : 0}
                  </span>
                </Link>
              </div>
            </div>
            <div className="flex-column flex-end">
              <div>
                <Link to={"/preview-camera/" + card.camera_id} className="m-L8">
                  <LocationOnSharp style={{ color: '#b4b4b4' }} fontSize="medium" /> {card.location}
                </Link>
              </div>
              <div className="flex-row">
                <VideocamOutlined style={{ color: '#b4b4b4' }} fontSize="small" />
                <Link to={"/preview-camera/" + card.camera_id} className="m-L8">
                  {card.name ? card.name : "SMS"}
                </Link>
              </div>
            </div>
        </div>
      </div>
    </div>
  );
}
