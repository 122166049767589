import React, { Component } from "react";
import Dialog from "@material-ui/core/Dialog";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import sha256 from "sha256";
import { eye } from "react-icons-kit/feather/eye";
import { eyeOff } from "react-icons-kit/feather/eyeOff";
import { Icon } from "react-icons-kit";
import "./login.css";
import src from "../assets/shark-logo.png";
import Warning from "../../assets/warning.png";

import Termsandcondition from "./termsandcondition/termsandcondition";

import iiqlogo from "../../assets/iiq-logo.png";

class SignIn extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      password: "",
      message: "",
      isDisabled: true,
      Dialogopen: false,
      agreement: false,
      open: false,
      error_message: "ccccccccc",
      type: "password",
      icon: eyeOff,
    };
  }

  handleToggle = (e) => {
    if (this.state.type === "password") {
      this.setState({
        icon: eye,
        type: "text",
      });
    } else {
      this.setState({
        icon: eyeOff,
        type: "password",
      });
    }
  };

  handleChange = (e) => {
    this.setState({
      isDisabled: false,
      agreement: e.target.checked,
    });
  };

  setUsername = (event) => {
    this.setState({
      isDisabled: false,
      username: event.target.value,
    });
  };

  setDialog = (event) => {
    this.setState({
      Dialogopen: true,
    });
  };

  closeDialog = (e) => {
    this.setState({
      Dialogopen: false,
    });
  };
  setPassword = (event) => {
    this.setState({
      isDisabled: false,
      password: event.target.value,
    });
  };

  signIn = (e) => {
    e.preventDefault();
    if (this.state.password == "" || this.state.username == "") {
      console.log("Username or password should not be blank");
      this.setState({
        open: true,
        message: "Username or password should not be blank",
      });
      return;
    } else if (
      this.state.username != "" &&
      this.state.password != "" &&
      this.state.agreement == false
    ) {
      this.setState({
        open: true,
        message: "Termns and condition should not be blank",
      });
      return;
    }

    let passhash = sha256(this.state.password);
    let userInfo = {
      username: this.state.username,
      password: this.state.password,
      checkboxstatus: this.state.checked,
    };
    fetch(
      "https://qcaefqcyp9.execute-api.ap-south-1.amazonaws.com/prod/fetchplatformuser",
      {
        method: "POST",
        headers: {
          "Content-type": "application/json",
          "x-api-key": "rWXVJAlSWl1VNVT22ppuG2OVhhZnKk04MGi21KP7",
        },
        body: JSON.stringify(userInfo),
      }
    )
      .then((r) => r.json())
      .then((res) => {
        localStorage.setItem("role_id", res.data.role);
        localStorage.setItem("user_name", res.data.username);
        this.setState({
          open: true,
          message: "You have successfully Logged In!",
        });
        if (res.data.block_access == true) {
          this.setState({
            open: true,
            message:
              "Your Application License has Expired. Please contact System Administrator!!",
            header: "License Expired",
          });
        } else {
          this.props.history.push("/dashboard");
        }
      })
      .catch((error) => {
        this.setState({
          open: true,
          message: "Incorrect Username or Password!",
          header: "Login Error",
        });
        console.error("There was an error!", error);
      });
  };
  componentDidMount() {
    document.addEventListener("contextmenu", (e) => {
      e.preventDefault();
    });
  }
  handleClose = () => {
    this.setState({
      open: false,
    });
  };

  render() {
    return (
      <div className="welcome-screen">
        <div className="split left mobile-hidden">
          <div className="info">
            <img
              src={src}
              class="logo"
              style={{ height: "145px", width: "435px" }}
              alt="JSW | Salem Works"
            />
            <span
              style={{ position: "relative", fontSize: "140%", top: "-1.2em" }}
            >
              &trade;
            </span>

            <h1
              style={{ color: "white", paddingLeft: "100px", fontSize: "20px" }}
            >
              AI Enabled Safety Platform
            </h1>
            <h4
              style={{
                color: "white",
                position: "absolute",
                bottom: "5px",

                marginLeft: "68%",
                fontSize: "16px",
              }}
            >
              <img src={iiqlogo} style={{ width: "96px" }} />{" "}
              <span
                style={{
                  position: "relative",
                  fontSize: "140%",
                  top: "-0.2em",
                }}
              >
                &trade;
              </span>
              &copy; 2020. All rights reserved.
            </h4>
          </div>
        </div>

        <div className="split right">
          <div className="info">
            <div className="mobile">
              <img
                src={src}
                style={{ height: "145px", width: "435px" }}
                alt="JSW | Salem Works"
              />
            </div>
            <form className="LoginForm" onSubmit={this.signIn}>
              <h2 className="login-title">
                Login
              </h2>
              <div className="username">
                <TextField
                  variant="standard"
                  placeholder="Username"
                  margin="normal"
                  onChange={this.setUsername}
                  value={this.state.username}
                  inputProps={{ color: "white" }}
                />
              </div>
              <div className="password" style={{ display: "flex" }}>
                <TextField
                  className="text-field"
                  variant="standard"
                  placeholder="Password"
                  margin="normal"
                  // required
                  type={this.state.type}
                  onChange={this.setPassword}
                  value={this.state.password}
                />
                <span
                  style={{ paddingTop: "3px", cursor: "pointer" }}
                  onClick={this.handleToggle}
                >
                  <Icon icon={this.state.icon} />
                </span>
              </div>
              <label style={{ paddingTop: "5px", cursor: "pointer" }}>
                <input
                  type="checkbox"
                  checked={this.state.agreement}
                  onChange={this.handleChange}
                  style={{ cursor: "pointer" }}
                />{" "}
                &nbsp;
                <span onClick={this.setDialog} style={{ cursor: "pointer" }}>
                  I Accept Terms of Usage
                </span>
              </label>
              <div className="button">
                <Button
                  type="submit"
                  variant="contained"
                  disabled={
                    this.state.agreement == true &&
                    this.state.username != "" &&
                    this.state.password != ""
                      ? this.state.isDisabled
                      : " "
                  }
                >
                  Log In
                </Button>
              </div>
            </form>
          </div>
          <Dialog
            open={this.state.open}
            onClose={this.handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            PaperProps={{
              style: {
                backgroundColor: "#111728",
                boxShadow: "none",
              },
            }}
          >
            <DialogTitle
              id="alert-dialog-title"
              style={{ backgroundColor: "#111728" }}
            >
              <span style={{ color: "white" }}>{this.state.header}</span>
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                <img
                  src={Warning}
                  width="100px"
                  style={{ marginLeft: "35%" }}
                />
                <p style={{ color: "white" }}> {this.state.message}</p>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={this.handleClose} autoFocus>
                <span style={{ color: "white" }}>Ok</span>
              </Button>
            </DialogActions>
          </Dialog>

          <Dialog
            className="dialog"
            open={this.state.Dialogopen}
            onClose={this.handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            PaperProps={{
              style: {
                backgroundColor: "#111728",
                boxShadow: "none",
              },
            }}
            style={{
              color: "green",
            }}
          >
            <DialogTitle
              id="alert-dialog-title"
              style={{ backgroundColor: "#111728" }}
            >
              <span style={{ color: "white" }}>{this.state.header}</span>
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {<Termsandcondition />}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={this.closeDialog} autoFocus>
                <span style={{ color: "white" }}>Ok</span>
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      </div>
    );
  }
}

export default SignIn;
