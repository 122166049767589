import React, { Component } from "react";
import { SearchContainer, SelectBox } from "./styles";
import {Sort, FilterList} from "@material-ui/icons";
import './style.css'
class SearchBar extends Component {
  render() {
    const { changeAction, options, type, placeholder="", value="" } = this.props;
    return (
      <SearchContainer className="form-control-sort-by">
        {type == "sort" ? <Sort /> : <FilterList />}
        <select 
            className="iiq-select-filter" 
            placeholder={placeholder}
            onChange={(e) => changeAction(e.target.value)}
            value={value}
        >
          {options && options.length > 0 ? options.map(item => <option value={item.value}>{item.label}</option>) : <option>No Options</option>}
        </select>
      </SearchContainer>
    );
  }
}

export default SearchBar;
