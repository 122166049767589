import { createSelector } from "reselect";
import { compare } from "../../Utils";

export const selectNotificationGroup = ({ notificationgroup }) => {
	//console.log("Selector NotificationGroup :",notificationgroup);
	return	notificationgroup;
	}
export const NotificationGroupSelectSearchFilter = ( {

    filters: { nameFilter },

}) => {
  return nameFilter;
};

export const NotificationGroupSelectFilteredList = createSelector(
  [selectNotificationGroup, NotificationGroupSelectSearchFilter],
  (notificationgroup, nameFilter) => {
    if (!notificationgroup.length) return notificationgroup;
    notificationgroup.sort(compare);
    if (!nameFilter) return notificationgroup;
    const filterednotificationgroup = notificationgroup.filter(
      ({ name }) => name.toUpperCase().indexOf(nameFilter.toUpperCase()) > -1
    );
    return filterednotificationgroup;
  }
);
