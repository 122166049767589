import * as types from "./types.js";


export const setRules = (rule) => {
  console.log("Set Rules : ", rule);
  return {
    type: types.SET_TOTAL_RULES,
    rule,
  }
};

export const RuleSetNameFilter = (nameFilter) => ({
  type: types.RULE_SET_NAME_FILTER,
  nameFilter,
});