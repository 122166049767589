import React, { Component } from "react";
import { connect } from "react-redux";
import Table from "../ShiftManagement/userTable";
import AddShiftForm from "../ShiftManagement/AddShiftForm"
import { ManagementSection, BannerHeading } from "./styles";
import Panel from "../common/panelUser";
import { selectShift} from "../../modals/ShiftManagement/selectors";
import { ShiftSetNameFilter } from "../../modals/ShiftManagement/actions";
import {
    fetchShift,
    deleteShift,
    addShift,
    updateShift,
} from "../../modals/ShiftManagement/thunk";
import {
    appFetchAllUnits,
    appFetchAllRoles,
} from "../../modals/app/thunk";

class ShiftManagement extends Component {
    state = {
        panelOpenType: "",
        activeUser: null,
    };
    componentDidCatch(error, errorInfo) {
        console.log(error, errorInfo, "==>");
    }

    componentDidMount() {
        const { fetchShift } = this.props;
        fetchShift();
    }

    toggleUserPanel = (panelOpenType) => {
        console.log("panel function inside ==>");
        if (!panelOpenType) this.setUser(null);
        this.setState({ panelOpenType });
        console.log('Panel Open Type :', panelOpenType);
    };
    setUser = (shift) => {
        this.setState({ activeUser: null });
      };    

    editShift = (shift) => {
        this.setState({ panelOpenType: "edit", activeUser: shift });
    };

    handleDelete = (status) => {
        this.setState({ showconfirmation: status });
    };

    confirmDelete = (shift) => {
        const { deleteShift } = this.props;
        deleteShift(shift);
        this.handleDelete(false);
    };

    render() {
        const { panelOpenType, activeUser } = this.state;
        const {
            addShift,
            deleteShift,
            updateShift,
            fetchUnits,
            fetchRoles,
            shift,
            setSearchFilter,
        } = this.props;
        return (
            <ManagementSection>
                <BannerHeading>Shift Management</BannerHeading>
                <Table
                    action={this.toggleUserPanel}
                    rowData={shift.shift }

                    requiredElements={[
                        "name",
                        "start_time",
                        "end_time",
                        "is_active",
                    ]}
                    columnsElements={[
                        "Shift Name",
                        "Start Time",
                        "End Time",
                        "Active"                     
                    ]}
                    panelType={panelOpenType}
                    editShift={this.editShift}
                    deleteShift={this.confirmDelete}
                    setSearchFilter={setSearchFilter}
                    perPage={10}
                />
                <Panel
                    panelType={panelOpenType}
                    isPanelOpen={Boolean(panelOpenType)}
                    Heading={
                        panelOpenType === "edit"
                            ? "Update Shift details"
                            : panelOpenType === "add"
                                ? "Add Shift"
                                : panelOpenType === "searchBy"
                                    ? "Search By"
                                    : null
                    }
                    closeAction={this.toggleUserPanel}
                >

                    {panelOpenType && (
                        <AddShiftForm
                            cancelAction={this.toggleUserPanel}
                            addShift={addShift}
                            deleteShift={deleteShift}
                            fetchUnits={fetchUnits}
                            fetchRoles={fetchRoles}
                            preLoadedValues={activeUser}
                            isEditing={panelOpenType === "edit"}
                            updateShift={updateShift}
                        />
                    )}
                </Panel>
            </ManagementSection>
        );
    }
}

const mapStateToProps = (state) => ({
    shift: selectShift(state),
});

const mapDispatchToProps = {
    addShift: addShift,
    updateShift: updateShift,
    fetchShift: fetchShift,
    deleteShift: deleteShift,
    fetchUnits: appFetchAllUnits,
    fetchRoles: appFetchAllRoles,
    setSearchFilter: ShiftSetNameFilter,
};

export default connect(mapStateToProps, mapDispatchToProps)(ShiftManagement);
