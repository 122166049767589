import React from 'react'
import Button from 'react-bootstrap/Button';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import xlsxlogo from "../../../src/assets/excel-icon.png";
export const ExportCSV = ({csvData, fileName}) => {

    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';

    const exportToCSV = (csvData, fileName) => {
        const ws = XLSX.utils.json_to_sheet(csvData);
        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], {type: fileType});
        FileSaver.saveAs(data, fileName + fileExtension);
    }

    // return (
    //     <button style = {{backgroundColor: "#66d1ac",marginTop: "0",padding: "5px 20px",borderRadius: "5px" }}variant="warning" onClick={(e) => exportToCSV(csvData,fileName)}>Export</button>
    // )

    return (<img style={{width: '35px', cursor: 'pointer'}} src={xlsxlogo} onClick={(e) => exportToCSV(csvData,fileName)}></img>);
}

export default ExportCSV