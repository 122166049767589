const baseUrl = `https://qcaefqcyp9.execute-api.ap-south-1.amazonaws.com/prod`;

export const fetchZoneUrl = `${baseUrl}/fetchzoneall`;

export const fetchWipUrl = `${baseUrl}/viewwip`;

export const fetchunitsUrl = `${baseUrl}/fetchunitsall`;

export const addWipUrl = `${baseUrl}/createwip`;

