import { selectShift } from "./selectors";
import { setShifts } from "./actions";
import axios from "axios";
import { fetchShiftUrl, deleteShiftUrl, addShiftUrl, updateShiftUrl } from "./api";
import { appShowPushNotification } from "../app/thunk";

export const deleteShift = (shift) => async (dispatch, getState) => {
  console.log(shift);
  const respone = await axios.post(deleteShiftUrl, {
    shift_id: shift.shift_id,
  });
  dispatch(fetchShift());
  console.log("api data ==>", respone);
  dispatch(appShowPushNotification("successfully deleted user"));
};

export const fetchShift = () => async (dispatch, getState) => {
  const { data: shift } = await axios.get(fetchShiftUrl);
  console.log("Shifts fetched data ==>", shift.data);
  dispatch(setShifts(shift ? shift.data : []));
};

export const addShift = (shift) => async (dispatch, getState) => {
  try {
    console.log("add shift data before post ==>", shift);
    dispatch(appShowPushNotification("adding shift in Shift management"));
    console.log('shift from thunk', JSON.stringify(shift));
    const response = await axios.post(addShiftUrl, shift);
    console.log(response);
    dispatch(fetchShift());
    dispatch(appShowPushNotification("successfully added Shift"));
  } catch (err) {
    dispatch(appShowPushNotification("failed to add Shift, try again"));
    console.error(`failed to add Shift with ${err}`);
  }
};

export const updateShift = (shift) => async (dispatch, getState) => {
  dispatch(appShowPushNotification("updating Shift"));
  try {
    console.log("updating Shift ==>", shift);
    const updateResponse = await axios.post(updateShiftUrl, shift);
    dispatch(fetchShift());
    dispatch(appShowPushNotification("successfully updated Shift"));
    console.log("updated response ==>", updateResponse);
  } catch (err) {
    dispatch(appShowPushNotification("failed to update Shift, try again"));
    console.error(`failed to update Shift with ${err}`);
  }
};
