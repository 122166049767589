import React, { Component } from "react";
import Columns from "./columns";
import { TableContainer, TableActionsBar } from "./styles";
import ActionsBar from "./actionsBar";
import Rows from "./rows";
import axios from "axios";
import { connect } from "react-redux";
import { selectUsers, } from "../../../modals/userManagement/selectors";
import Pagination from '@material-ui/lab/Pagination';
import SearchIcon from "@material-ui/icons/Search";
import { fetchUsers, } from "../../../modals/userManagement/thunk";
import { fetchWorkForceWorkers, fetchWorkForceUserByAlphabet, fetchWorkForceUserByName } from "../../../modals/workForceManagement/thunk";
import userManagement from "..";
import SearchBar from "../searchBar";
let search = "";
class Table extends Component {
  state = { searchInput: '', alphabet: '' };

  getInitialState = () => {
    return { input: '' };
  }


  onSearchInputChange = (e) => {
    // this.setState({ searchInput: e.target.value })
    //  var len = len();
    //var input = (e.target.value);
    console.log("In the click event", search)
    const { fetchWorkForceUserByName } = this.props;
    fetchWorkForceUserByName(search)
    this.setState({ alphabet: e.target.value })


  }
  handleChange = (e) => {
    search = e.target.value;
    console.log("search String", search);
    //this.setState({ input: e.target.value });
  }
  fetchWorkForceUserByName = (key) => {
    console.log(key);

    axios.post(
      `https://qcaefqcyp9.execute-api.ap-south-1.amazonaws.com/prod/fetchworkuserbyname`,
      {
        "name": key
      }
    )
      .then((res) => {
        console.log("User Management ", res.data);
        this.rowData = res.data;

      })
      .catch((err) => { console.log(err) });
    //   const { data: users } = axios.post("https://qcaefqcyp9.execute-api.ap-south-1.amazonaws.com/prod/fetchusers", {

    //  "name_start": key
    //  });
    //  console.log("Users fetched data ==>", users);
    // dispatch(setUsers(users ? users.data : []));
  };
  onAlphabetClick = (e) => {
    console.log("In the click event", e.target.value)
    const { fetchWorkForceWorkers } = this.props;
    fetchWorkForceWorkers(e.target.value)
    this.setState({ alphabet: e.target.value })
  }
  prepareAlphabets = () => {
    let result = [];
    for (let i = 65; i < 91; i++) {
      result.push(
        <button type="button" key={i} onClick={this.onAlphabetClick} value={String.fromCharCode(i)} style={{ color: "white", backgroundColor: "#111728", marginRight: "10px" }}>{String.fromCharCode(i)}</button>
      )
    }
    return result;
  }
  elementContainsSearchString = (searchInput, element) => (searchInput ? element.name.toLowerCase().includes(searchInput.toLowerCase()) : false);
  filterItems = (itemList) => {
    let result = [];
    const { searchInput, alphabet } = this.state;
    if (itemList && (searchInput || alphabet)) {
      result = itemList.filter((element) => (element.name.charAt(0).toLowerCase() === alphabet.toLowerCase()) ||
        this.elementContainsSearchString(searchInput, element));
    } else {
      result = itemList || [];
    }
    result = result.map((item) => (<li>{item.name}</li>))
    return result;
  }
  fetchWorkForceUserByAlphabet = (key) => {
    axios.post(
      `https://qcaefqcyp9.execute-api.ap-south-1.amazonaws.com/prod/fetchworkforceusers`,
      {
        "name_start": key
      }
    )
      .then((res) => {
        console.log("User Management ", res.data);
        this.rowData = res.data;

      })
      .catch((err) => { console.log(err) });
    //   const { data: users } = axios.post("https://qcaefqcyp9.execute-api.ap-south-1.amazonaws.com/prod/fetchusers", {

    //  "name_start": key
    //  });
    //  console.log("Users fetched data ==>", users);
    // dispatch(setUsers(users ? users.data : []));
  };
  render() {

    const {
      rowData,
      columnsElements,
      action,
      editUser,
      panelType,
      requiredElements,
      deleteUser,
      fetchWorkForceUserByAlphabet,
      confirmDelete,
      setSearchFilter,
      setNameSearchFilter
    } = this.props;
    const filteredList = this.filterItems(rowData);
    const searchFilter = (text) => {
      setNameSearchFilter(text);
    };
    return (

      <TableContainer>

        <TableActionsBar>
          {/* <ActionsBar clickAction={action} panelType={panelType} setSearchFilter={setSearchFilter}/> */}
          <ActionsBar clickAction={action} panelType={panelType} />
          <div style={{ marginLeft: "30px" }}>


            <table>
              <tr>
                <td>
                  <SearchIcon style={{ marginBottom: "-5px", marginRight: "10px" }} />
                </td>
                <td>
                  {this.prepareAlphabets()}
                </td>
                {/* <td>
                  <span> <SearchIcon onClick={(e) => this.onSearchInputChange(e)} style={{ marginBottom: "-5px", marginRight: "10px" }} /></span>
                  <span>
                    <input type="search" placeholder="Search by Name" onChange={(e) => this.handleChange(e)} style={{ background: "#111728", color: "white" }} /></span>
                </td> */}
              </tr>
            </table>




          </div>

        </TableActionsBar>
        {rowData.length == 0 &&
          <h2  style={{ color: "white", marginLeft: "40%", marginTop: "10%" }}> No Data found
            
          </h2>
        }
        {rowData.length > 0 &&
          <Columns columnsElements={columnsElements} />
        }
        <Rows
          rowData={rowData}
          confirmDelete={confirmDelete}
          deleteUser={deleteUser}
          requiredElements={requiredElements}
          columnsElements={columnsElements}
          editUser={editUser}
          setSearchFilter={setSearchFilter}
          fetchWorkForceUserByAlphabet={fetchWorkForceUserByAlphabet}
        />
      </TableContainer>
    );
  }
}
const mapStateToProps = (state) => ({
  users: selectUsers(state),

});
const mapDispatchToProps = {

  fetchWorkForceWorkers: fetchWorkForceWorkers,
  fetchWorkForceUserByAlphabet: fetchWorkForceUserByAlphabet,
  fetchWorkForceUserByName: fetchWorkForceUserByName,

};
export default connect(mapStateToProps, mapDispatchToProps)(Table);
