const baseUrl = `http://shark-api-v2.herokuapp.com/api`;

export const fetchWorkeForceUrl = `https://qcaefqcyp9.execute-api.ap-south-1.amazonaws.com/prod/fetchworkforceusers`;

export const deleteWorkerUrl = `${baseUrl}/work_force/delete`;

export const addWorkForceWorkerUrl = `${baseUrl}/work_force/create`;

export const updateWorkForceWorkerUrl = `${baseUrl}/work_force/update`;

export const fetchWorkForcebyNameUrl = `https://qcaefqcyp9.execute-api.ap-south-1.amazonaws.com/prod/fetchworkuserbyname`;
