import React from "react";
import { NavLink } from "react-router-dom";
import ImgLink4 from "../../assets/truck.png";
import ImgLink6 from "../../assets/attendance.png";
import ImgLink8 from "../../assets/report.png";
import "./Report.css";
function Reports(props) {
  return (
    <div className="reports">
      <h1
        style={{
          color: "white",
          zIndex: "999",
          position: "relative",
          margin: "15px",
          fontSize: "30px",
        }}
      >
        Reports
      </h1>
      <div class="row">
        <div class="col-md-2">
          <NavLink className="flex1" to="/notification">
            <div class="card" style={{ width: "10rem", height: "15rem" }}>
              <img
                src={ImgLink4}
                alt="Link 4"
                style={{
                  cursor: "pointer",
                  paddingLeft: "20px",
                  width: "100%",
                  height: "150px",
                }}
              />
              <div className="card-body">
                <div className="title_text" style={{}}>
                  Vehicle Report
                </div>
              </div>
            </div>
          </NavLink>
        </div>
        {/* 
        <div class="col-md-2" style={{ paddingTop: "15px" }}>
          <div class="card" style={{ width: "10rem", height: "15rem" }}>
            <NavLink className="flex1" to="/AttendanceReport">
              <img
                src={ImgLink6}
                alt="Link 6"
                class="card-img-top"
                style={{
                  cursor: "pointer",
                  width: "100%",
                  height: "115px",
                  alignItems: "center",
                }}
              />
              <div className="card-body">
                <div className="title_text" style={{}}>
                  {" "}
                  Attendance Report{" "}
                </div>
              </div>
            </NavLink>
          </div>
        </div> */}

        <div class="col-md-2" style={{ paddingTop: "15px" }}>
          <div class="card" style={{ width: "10rem", height: "15rem" }}>
            <NavLink className="flex1" to="/report/0">
              <img
                src={ImgLink8}
                alt="Link 6"
                class="card-img-top"
                style={{ cursor: "pointer", width: "100%", height: "115px" }}
              />
              <div className="card-body">
                <div className="title_text">Anomalies Report</div>
              </div>
            </NavLink>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Reports;
