import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Red from "../../assets/red.png";
import Yellow from "../../assets/yellow.png";
import Amber from "../../assets/amber.png";
import Blue from "../../assets/blue.png";
import "./dashboard.css";
import DashboardCard from "./card/dashboardCard";
import Spinner from "../common/Spinner";
import Maps from "./Maps";
import BarDefaultSMS from "../BarDefaultSMS/BarDefaultSMS";
import { DashboardSection, ZoneOptions, MapImage, MainMapView } from "./styles";
import WipScheduler from "./scheduleWIP";
import { Modal } from "antd";
import {
  appFetchAllEquipments,
  appFetchACamerasData,
  zoneScheduleWIP,
  zoneFecthWIPdetails,
} from "../../modals/app/thunk";
import SearchBar from "../common/searchBar";
import SortBy from "../common/sortBy";
import {
  appSelectFilteredACameras,
  appSelectNotifications,
} from "../../modals/app/selectors";
import { appSetZonesFilter } from "../../modals/app/actions";
import _ from 'lodash';
import Axios from "axios";

export function Dashboard({
  fetchEquipment,
  fetchACameras,
  setSearchFilter,
  acameras,
  history,
  createWIP,
  getWipDetails,
}) {
  const [schedulingZone, setSechudulingZone] = useState({});
  const [equipments, setEquipments] = useState([]);
  const [isMapView, setIsMapView] = useState(false);
  const [localvalue, setLocalvalue] = useState("");
  const [cameraItems, setCameraItems] = useState([]);
  const [severity, setSeverity] = useState(null);
  const [sortOptions, setSortOptions] = useState([{
    label: 'Latest',
    value: 'generated_time'
  },{
    label: 'Anomaly name',
    value: 'name'
  },{
    label: 'Anomaly count',
    value: 'notification_count'
  },{
    label: 'Location',
    value: 'location'
  },{
    label: 'Camera',
    value: 'camera_rule'
  },{
    label: 'Severity(High to Low)',
    value: 'high'
  },{
    label: 'Severity(Low to High)',
    value: 'low'
  }]);
  const [allZone, setAllZone] = useState([]);
  const [selectedZone, setSelectedZone] = useState(null);
  const [message, setMessage] = useState(null);
  const [searchText, setSearchText] = useState(null);
  const [sortText, setSortText] = useState("generated_time");
  const [isShowModal, setIsShowModal] = useState(false);
  const [apiError, setAPIError] = useState(null);

  useEffect(() => {
    if(apiError && isShowModal != true) {
      setIsShowModal(true);
    }
  }, [apiError]);

  useEffect(() => {
    if(isShowModal) {
      Modal.error({
        wrapClassName: 'error-modal',
        title: 'Oops!',
        content: "We're sorry, but something went wrong.",
        onOk() {
          setIsShowModal(false);
        }
      });
    }
  },[isShowModal]);

  useEffect(() =>{
    const items = _.orderBy(acameras, "generated_time", "desc");
    setCameraItems(items);
  }, [acameras]);

  let interval = 0;
  useEffect(() => {
    if (localStorage.getItem("role_id") == undefined) history.push("/login");
    else setLocalvalue(localStorage.getItem("role_id"));
  }, []);

  const fetchREquiredData = async () => {
    fetchACameras();
  };

  const filterCamerasItems = (searchText) => {
    searchText = searchText.toLowerCase();
    setSearchText(searchText);
  }

  const handleSeverity = (severity) => {
    severity = severity.toLowerCase();
    if (severity !== 'all') {
      setSeverity(severity);
    } else {
      setSeverity(null);
    }
  }

  const searchFilter = (text) => {
    //setSearchFilter(text);
    filterCamerasItems(text);
  };

  const handleSort = (text) => {
    setSortText(text);
  };   

  useEffect(() => {
    let items = acameras && acameras.length > 0 ? [...acameras] : [];

    if(selectedZone && selectedZone != -1) {
      items = items.filter(item => item.zone_id == selectedZone);
    }
    if(severity) {
      items = items.filter(item => item.severity && item.severity.toLowerCase() === severity);
    }
    if(searchText) {
      items = items.filter(item => (item.location.toLowerCase()).includes(searchText) 
        || (item.camera_rule.toLowerCase()).includes(searchText) 
        || (item.name.toLowerCase()).includes(searchText)
        || (String(item.notification_count).toLowerCase()).includes(searchText)
        || (String(item.notification_id).toLowerCase()).includes(searchText));
    }
    if(sortText){
      if(sortText == 'low') {
        items = _.orderBy(items, 'severity', 'desc');
      } else {
        let text = sortText == 'high' ? 'severity' : sortText;
        let filterBy = text == 'generated_time' ? 'desc' : 'asc';
        items = _.orderBy(items, text, filterBy);
      }
    }
    if(acameras && items.length == 0) {
      setMessage("No Data! Please try with different filter.");
    }
    setCameraItems(items);
  },[selectedZone, sortText, severity, searchText]);

  const handleChangeZone = (text) => {
    setSelectedZone(text);
  }

  useEffect(() => {
    Axios.get(
      `https://qcaefqcyp9.execute-api.ap-south-1.amazonaws.com/prod/fetchzoneall`
    )
      .then((res) => {
        console.log("fetchzoneall" , res.data.data);
        
        if(res && res.data && res.data.data) {
          let zones = res.data.data.map(item => {return {label: item.name, value: item.zone_id}});
          zones = [{label: 'Filter by', value: "-1"}].concat(zones) 
          setAllZone(zones)
        }
      })
      .catch((err) => {
        console.log(err);
        setAPIError(err);
      });
  }, []);

  useEffect(() => {
    fetchREquiredData();
    /*  interval = setInterval(() => {
      fetchREquiredData();
    }, 30000);
 */
  }, []);

  /*  useEffect(() => {
     interval = setInterval(() => {
       fetchACameras();
     }, 25000);
     
   }, []); */

  return (
    <DashboardSection>
      <BarDefaultSMS />
      {!isMapView ? (
        <ZoneOptions>
          <div className="iiq-searchbar flex">
            <SearchBar changeAction={searchFilter} />
            <SortBy value={sortText} changeAction={handleSort} options={sortOptions} type="sort" placeholder="Sort by"/>
            <SortBy value={selectedZone} changeAction={handleChangeZone} options={allZone} type="filter" placeholder="Filter by"/>
          </div>
          <div display="flex" style={{
              width: '100%'
            }}>
            <div className="flex-row" style={{
              justifyContent: 'right',
              width: '100%'
            }}>
              <img src={Red} width={15} height={15} onClick={() => handleSeverity('high')}/>
              <h4
                style={{
                  color: "white",
                  marginLeft: "5px",
                  marginRight: "10px",
                  fontSize: "15px",
                  cursor: 'pointer',
                  borderBottom: severity == "high" ? "1px solid #fff" : 0
                }}
                onClick={() => handleSeverity('high')}
              >
                High
              </h4>
              <img src={Amber} width={15} height={15} onClick={() => handleSeverity('medium')}/>
              <h4
                style={{
                  color: "white",
                  marginLeft: "5px",
                  marginRight: "10px",
                  fontSize: "15px",
                  cursor: 'pointer',
                  borderBottom: severity == "medium" ? "1px solid #fff" : 0
                }}
                onClick={() => handleSeverity('medium')}
              >
                Medium
              </h4>

              <img src={Yellow} width={15} height={15} onClick={() => handleSeverity('low')}/>
              <h4
                style={{
                  color: "white",
                  marginLeft: "5px",
                  marginRight: "10px",
                  fontSize: "15px",
                  cursor: 'pointer',
                  borderBottom: severity == "low" ? "1px solid #fff" : 0
                }}
                onClick={() => handleSeverity('low')}
              >
                Low
              </h4>
              <img src={Blue} width={15} height={15} onClick={() => handleSeverity('all')}/>
              <h4
                style={{
                  color: "white",
                  marginLeft: "5px",
                  marginRight: "10px",
                  fontSize: "15px",
                  cursor: 'pointer',
                  borderBottom: !severity ? "1px solid #fff" : 0
                }}
                onClick={() => handleSeverity('all')}
              >
                All
              </h4>
            </div>
            <div className="flex-row" style={{
              justifyContent: 'right',
              marginRight: '8px',
              color: '#aeaeae'
            }}>
              {acameras && cameraItems && cameraItems.length + " of " + acameras.length}</div>
          </div>
        </ZoneOptions>
      ) : null}
      {isMapView ? (
        <MainMapView>
          <Maps
            id="e691172598f04ea8881cd2a4adaa45ba"
            style={{ height: "100vh" }}
          />
        </MainMapView>
      ) : cameraItems && cameraItems.length ? (
        <>
          <div className="cards flex">
            {cameraItems.map((card) => {
              return (
                <DashboardCard
                  key={card.camera_id}
                  card={card}
                  severity={card.severity}
                  toggleWipScheduler={setSechudulingZone}
                />
              );
            })}
          </div>
          <WipScheduler
            schedulingZone={schedulingZone}
            toggleWipScheduler={setSechudulingZone}
            fetchEquipment={fetchEquipment}
            equipments={equipments}
            createWIP={createWIP}
            getWipDetails={getWipDetails}
          />
        </>
      ) : (
        <div>
          { message ? 
          <div style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: '#ffffff',
            fontSize: '18px'
          }}> {message} </div>
          :
          <Spinner />
        }
        </div>
      )}
    </DashboardSection>
  );
}

const mapStateToProps = (state, ownProps) => ({
  acameras: appSelectFilteredACameras(state),
  notifications: appSelectNotifications(state),
  apiCallsInProgress: state.apiCallsInProgress,
});

const mapDispatchToProps = {
  fetchACameras: appFetchACamerasData,
  setSearchFilter: appSetZonesFilter,
  createWIP: zoneScheduleWIP,
  getWipDetails: zoneFecthWIPdetails,
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
