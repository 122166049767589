import React, { Component } from "react";
import { Cell } from "../columns/styles";
import {
  TableRowsContainer,
  TableRow,
  RowActionsWrapper,
  RowActionsAligner,
  Action,
} from "./styles";
import { Switch } from "antd";

class Row extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activePage: 15
    };
  }

  handlePageChange(pageNumber) {
    console.log(`active page is ${pageNumber}`);
    this.setState({activePage: pageNumber});
  }


  render() {
    const {
      rowData,
      requiredElements = [
        "emp_id",
        "name",
        "phone_number",
        "unit_id",
        "designation_id",
        "contractor_name",
        "status",
        "is_active",
      ],
      deleteUser,
      editUser,
    } = this.props;
    return (
      <TableRowsContainer>
        {rowData.map((row) => {
          return (
            <TableRow>
              {requiredElements.map((element, i) => (

                <Cell size={i === 3 ? 15 : 10}>
                  { element === "is_active" ? (
                    <Switch defaultChecked />
                  ) : (
                    `${row[element]}`
                  )}
                </Cell>
              ))}
              <RowActionsWrapper>
                <RowActionsAligner>
                  <Action onClick={() => editUser(row)}>Edit</Action>
                </RowActionsAligner>
              </RowActionsWrapper>
            </TableRow>
          );
        })}
        
      </TableRowsContainer>
    );
  }
}

export default Row;
