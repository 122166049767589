import * as types from "./types";

export const initialState = {
  NotificationGroup: [],
};

export default function NotificationGroupManagementReducer(
  state = initialState,
  action
) {
  switch (action.type) {
    case types.SET_TOTAL_NOTIFICATIONGROUPS:
      {
        const { notificationgroup } = action;
        state = { ...state, notificationgroup };
      }
      break;
    case types.NOTIFICATIONGROUP_SET_NAME_FILTER: {
      const { nameFilter } = action;
      state = { ...state, filters: { ...state.filters, nameFilter } };
    }
    // case y:
    //   // code block
    //   break;
    default:
      return state;
  }
  // console.log("State Variable :",state);
  return state;
}
