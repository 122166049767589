import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';

const useStyles = makeStyles({
  root: {
    maxWidth: 413,
  },
  media: {
    height: 140,
  },
});

export default function AnalyticsCard({title = '', content, footer}) {
  const classes = useStyles();

  return (
    <Card className={classes.root}>
      <CardActionArea>
        <div className="iiq-card-title">
            {title}
        </div>
        <CardContent>
          {content}
        </CardContent>
      </CardActionArea>
      <CardActions>
        <div className="iiq-card-footer">
          {footer}
        </div>
      </CardActions>
    </Card>
  );
}
