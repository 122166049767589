import * as types from './actionTypes';
import * as ruleApi from '../../api/rules';
import { apiCallError, beginApiCall } from "../../redux/actions/apiStatusAction";

export function updateRulesSuccess(data) {
    return { type: types.UPDATE_RULES_SUCCESS, data }
}

export function updateRuleData(data, zone_id) {
    return function (dispatch) {
        dispatch(updateRulesSuccess(data));

    }
}