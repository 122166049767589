import React from "react";
import { NavLink } from "react-router-dom";
import ImgLink7 from "../../assets/construction-worker.png";
import ImgLink8 from "../../assets/report.png";
import GroupIcon from "@material-ui/icons/Group";
import ImgLink1 from "../../assets/dashboard.png";
import Wip from "../../assets/wip.png";
import ImgLink4 from "../../assets/truck.png";
import ImgLink6 from "../../assets/attendance.png";
import Settings from "../../assets/settings.png";
import FaceRecogDashboard from "../../assets/face-recognition.png";
import { Tooltip } from "@material-ui/core";
import "./LeftNavigation.css";
import { WifiSharp } from "@material-ui/icons";
import About from "../../assets/about.png";
import User from "../../assets/usermaster.png";
import iiqlogo from "../../assets/iiq-logo.png";

function LeftNavigation(props) {
  const dashboard = "Dashboard";
  const { currenLocation } = props;
  const isActive = (location) => {
    return location === currenLocation;
  };
  return (
    <nav className={`nav ${props.navOpen}`}>
      <ul>
        <li>
          <NavLink to="/dashboard">
            <Tooltip title="Dashboard" aria-label="Dashboard">
              <img src={ImgLink1} alt="Link 1" style={{ cursor: "pointer" }} />
            </Tooltip>
            <h1
              // className={`nav ${props.navOpen}`}
              style={{
                color: "white",
                fontSize: "14px",
                margin: "5px 20px",
              }}
            >
              Dashboard
            </h1>
          </NavLink>
        </li>

        {(() => {
          if (localStorage.getItem("role_id") != 6) {
            return (
              <li>
                <NavLink to="/report/0">
                  <Tooltip title="Reports" aria-label="Reports">
                    <img
                      src={ImgLink8}
                      alt="Link 4"
                      style={{ cursor: "pointer" }}
                    />
                  </Tooltip>
                  <h1
                    // className={`nav ${props.navOpen}`}
                    style={{
                      color: "white",
                      fontSize: "14px",
                      margin: "5px 20px",
                      alignItems: "center",
                    }}
                  >
                    Reports
                  </h1>
                </NavLink>
              </li>
            );
          }
        })()}

        {(() => {
          if (localStorage.getItem("role_id") != 6) {
            return (
              <li>
                <NavLink to="/Usermaster">
                  <Tooltip title="User Master" aria-label="User Master">
                    <img
                      src={User}
                      alt="Link 1"
                      style={{ cursor: "pointer" }}
                    />
                  </Tooltip>
                  <h1
                    // className={`nav ${props.navOpen}`}
                    style={{
                      color: "white",
                      fontSize: "14px",
                      margin: "5px 20px",
                    }}
                  >
                    User Master
                  </h1>
                </NavLink>
              </li>
            );
          }
        })()}

        {/* {(() => {
          if (localStorage.getItem("role_id") != 6) {
            return (
              <li>
                <NavLink className="flex1" to="/mginpunch">
                  <Tooltip
                    title="Authentication Dashboard"
                    aria-label="Authentication Dashboard"
                  >
                    <img
                      src={FaceRecogDashboard}
                      alt="Link 6"
                      style={{ cursor: "pointer" }}
                    />
                  </Tooltip>
                </NavLink>
              </li>
            );
          }
        })()} */}

        {(() => {
          if (localStorage.getItem("role_id") != 6) {
            return (
              <li>
                <NavLink to="/SuperAdminSettings">
                  <Tooltip
                    title="Super Admin Settings"
                    aria-label="Super Admin Settings"
                  >
                    <img
                      src={Settings}
                      alt="Link 6"
                      style={{
                        cursor: "pointer",
                        width: "20px",
                        height: "20px",
                      }}
                    />
                  </Tooltip>
                  <h1
                    // className={`nav ${props.navOpen}`}
                    style={{
                      color: "white",
                      fontSize: "14px",
                      display: "inline-flex",
                      alignItems: "center",
                      margin: "5px 20px"
                    }}
                  >
                    Super Admin Settings
                  </h1>
                </NavLink>
              </li>
            );
          } else if (localStorage.getItem("role_id") != 6) {
            return (
              <li>
                <NavLink to="/SuperAdminSettings">
                  <Tooltip
                    title="Super Admin Settings"
                    aria-label="Super Admin Settings"
                  >
                    <img
                      src={Settings}
                      alt="Link 6"
                      style={{ cursor: "pointer" }}
                    />
                  </Tooltip>
                  <h1
                    // className={`nav ${props.navOpen}`}
                    style={{
                      color: "white",
                      fontSize: "14px",
                      margin: "5px 20px",
                    }}
                  >
                    Super Admin Settings
                  </h1>
                </NavLink>
              </li>
            );
          }
        })()}

        {(() => {
          if (localStorage.getItem("role_id") != 6) {
            return (
              <li>
                <NavLink to="/wipmaintenance">
                  <Tooltip title="WIP" aria-label="WIP">
                    <img src={Wip} alt="Link 6" style={{ cursor: "pointer" }} />
                  </Tooltip>
                  <h1
                    // className={`nav ${props.navOpen}`}
                    style={{
                      color: "white",
                      fontSize: "14px",
                      margin: "5px 20px",
                    }}
                  >
                    WIP
                  </h1>
                </NavLink>
              </li>
            );
          }
        })()}

        {(() => {
          if (localStorage.getItem("role_id") != 6) {
            return (
              <li>
                <NavLink to="/about">
                  <Tooltip title="About" aria-label="About">
                    <img
                      src={About}
                      alt="Link 6"
                      style={{ cursor: "pointer" }}
                    />
                  </Tooltip>
                  <h1
                    // className={`nav ${props.navOpen}`}
                    style={{
                      color: "white",
                      fontSize: "14px",
                      margin: "5px 20px",
                    }}
                  >
                    About
                  </h1>
                </NavLink>
              </li>
            );
          }
        })()}
        {/* <li>
          {localStorage.getItem("role_id") == 1 ? (
            <NavLink className="flex1" to="/SuperAdminSettings">
              <Tooltip
                title="Super Admin Settings"
                aria-label="Super Admin Settings"
              >
                <img
                  src={Settings}
                  alt="Link 6"
                  style={{ cursor: "pointer" }}
                />
              </Tooltip>
            </NavLink>
          ) : (
            <NavLink className="flex1" to="/PlatformSettings">
              <Tooltip title="Platform Settings" aria-label="Platform Settings">
                <img
                  src={Settings}
                  alt="Link 6"
                  style={{ cursor: "pointer" }}
                />
              </Tooltip>
            </NavLink>
          )}
        </li> */}
        {/* <li>
          <NavLink className="flex1" to="/wipmaintenance">
            <Tooltip title="WIP" aria-label="WIP">
              <img src={Wip} alt="Link 6" style={{ cursor: "pointer" }} />
            </Tooltip>
          </NavLink>
        </li> */}
      </ul>
      {props.navOpen ? 
        <h4
          style={{
            color: "white",
            position: "fixed",
            bottom: "0",
            fontSize: "14px",
            marginLeft: '18px',
            fontWeight: 'normal'
          }}
        >
          <img src={iiqlogo} style={{ width: "96px" }} />{" "}
          <span
            style={{
              position: "relative",
              fontSize: "18px",
              top: "-0.2em",
            }}
          >
            &trade;
          </span>
          <br /> &copy; 2020. All rights reserved.
        </h4>
        :
        <h4
          style={{
            color: "white",
            position: "absolute",
            bottom: "0",
            fontSize: "14px",
            display: 'flex',
            width: 'inherit',
            justifyContent: 'center'
          }}
        >
          <span style={{
            fontFamily: 'initial',
            fontSize: '16px',
            letterSpacing: '2px'
          }}>
            <span>I</span>
            <span style={{color: 'red'}}>IQ</span>
          </span>
          <span
            style={{
              position: "relative",
              fontSize: "18px",
              top: "-0.2em",
            }}
          >
            &trade;
          </span>
        </h4>
      }
    </nav>
  );
}

export default LeftNavigation;
