import * as types from "./types.js";

// export const appSetIsLoggedIn = (isLoggedIn) => ({
//   type: types.APP_SET_LOGIN_STATUS,
//   isLoggedIn,
// });

// export const appSetUserDetails = (userDetails) => ({
//   type: types.APP_SET_USER_DETAILS,
//   userDetails,
// });

// export const appSetUserJwt = (JWT) => ({
//   type: types.APP_SET_JWT,
//   JWT,
// });

export const appSetAvailableTabs = (tabs) => ({
  type: types.APP_SET_AVAIALABLE_TABS,
  tabs,
});

export const appSetNotifications = (notifications) => ({
  type: types.APP_SET_NOTIFICATIONS,
  notifications,
});

export const appSetDailyNotifications = (dailyNotifications) => ({
  type: types.APP_SET_DAILY_NOTIFICATIONS,
  dailyNotifications,
});

export const appSetAvailableZones = (zones) => ({
  type: types.APP_SET_AVAILABLE_ZONES,
  zones,
});

export const appSetAvailableParentZones = (parentzones) => ({
  type: types.APP_SET_AVAILABLE_PARENT_ZONES,
  parentzones,
});

export const appSetAvailableCameras = (cameras) => ({
  type: types.APP_SET_AVAILABLE_CAMERAS,
  cameras,
});

export const appSetAvailableACameras = (acameras) => ({
  type: types.APP_SET_AVAILABLE_ACAMERAS,
  acameras,
});

export const appSetZonesFilter = (filterText) => ({
  type: types.APP_SET_ZONES_FILTER,
  filter: filterText,
});

export const appSetUsersFilter = (filterText) => ({
  type: types.APP_SET_USERS_FILTER,
  filter: filterText,
});

export const appSetHodList = (hodList) => ({
  type: types.APP_SET_HOD_LIST,
  hodList,
});

export const appSetPushNotifications = (msg) => ({
  type: types.APP_SET_PUSH_NOTIFICATION,
  msg,
});

export const appSetPushNotifTimeOut = (timeOut) => ({
  type: types.APP_SET_PUSH_NOTIF_TIMEOUT,
  timeOut,
});

export const appAPIError = (apiError) => ({
  type: types.APP_API_ERROR,
  apiError,
});
