import * as types from "./types.js";


export const setShifts = (shift) => {
  console.log("Set Shifts : ", shift);
  return {
    type: types.SET_TOTAL_SHIFTS,
    shift,
  }
};

export const ShiftSetNameFilter = (nameFilter) => ({
  type: types.SHIFT_SET_NAME_FILTER,
  nameFilter,
});