import React, { Component } from 'react';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import XLSX from 'xlsx';
import { make_cols } from './make-columns';
import { SheetJSFT } from './types';
import {connect} from "react-redux";
import axios from 'axios';
import {
    fetchUsers,

  } from "../../modals/userManagement/thunk";

class ExcelReader extends Component {
    constructor(props) {
        super(props);
        this.state = {
            file: {},
            data: [],
            cols: []
        }
        this.filename = '';
        this.handleFile = this.handleFile.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.addRoaster = this.addRoaster.bind(this);
       
    }
	
	addRoaster(data){
              
		axios.post('https://qcaefqcyp9.execute-api.ap-south-1.amazonaws.com/prod/bulkuploadworkforce',data).then(resp => {
            toast.success(
                `Users Added`
              );		
        const { fetchUsers } = this.props;
        fetchUsers();
	});
	}

    handleChange(e) {
        const files = e.target.files;
        if (files && files[0]) this.setState({ file: files[0] });
        
        this.filename = files[0].name;
        console.log('filename :',this.filename);
    };

    handleFile() {
        if (this.filename)
        {
        const reader = new FileReader();
        const rABS = !!reader.readAsBinaryString;

        reader.onload = (e) => {
            const bstr = e.target.result;
            const wb = XLSX.read(bstr, { type: rABS ? 'binary' : 'array', bookVBA : true });
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];
            const data = XLSX.utils.sheet_to_json(ws);
            this.setState({ data: data, cols: make_cols(ws['!ref']) }, () => {
			   this.addRoaster(JSON.stringify(this.state.data, null, 2));
			    console.log('FUN ', JSON.stringify(this.state.data, null, 2));
            });

        };

        if (rABS) {
            reader.readAsBinaryString(this.state.file);
        } else {
            reader.readAsArrayBuffer(this.state.file);
        };
        }
        else{
            toast.error("File Not selected");
        };
    }

    render() {
        return (
            <div >
                <ToastContainer
                    position="top-right"
                    autoClose={1000}
                    newestOnTop={true}
                    hideProgressBar={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                />
                <input style = {{background: "#66d1ac",padding:"4px 15px",borderRadius:"5px",color:"white",width:"25%",marginLeft:"10%",marginTop:"0px"}} type="file" className="form-control" id="file" accept={SheetJSFT} onChange={this.handleChange} />
                <input style = {{background: "#66d1ac",padding:"4px 15px",borderRadius:"5px",color:"white",marginLeft:"100px"}} type='submit' value="Upload" onClick={this.handleFile} />
            </div>

        )
    }
}

const mapStateToProps = (state, ownProps) => ({

});

const mapDispatchToProps = {
   fetchUsers: fetchUsers,
};


export default connect(mapStateToProps, mapDispatchToProps)(ExcelReader);