import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { Container } from "@material-ui/core";
import {
  CustomInput,
  GenderSelection,
  GenderLable,
  ActionButtons,
  SubmitSection,
  ErrorMsg,
  AligningWrapper
} from "./styles";
import { Radio, Select } from "antd";

const { Option } = Select;

const AddUserForm = ({
  cancelAction,
  addUser,
  fetchUnits,
  fetchAllWorkAreas,
  preLoadedValues,
  isEditing,
  updateUser,
}) => {
  const [gender, setGender] = useState("male");
  const [workAreas, setworkAreas] = useState([]);
  const [units, setUnits] = useState([]);
  const [selectedUnitId, setSelectedUnit] = useState(null);
  const [selectedWorkAreaId, setSelectedWorkAreaId] = useState(null);
  const [customError, setCustomError] = useState(null);
  const { handleSubmit, register, errors, reset } = useForm({
    defaultValues: preLoadedValues || {},
  });

  const onSubmit = async (values) => {
    const worker = {
      ...values,
      gender,
      unit_id: isEditing ? preLoadedValues.unit_id : selectedUnitId,
      emp_id: `${new Date().getTime()}`,
      workarea_id: isEditing
        ? preLoadedValues.workarea_id
        : selectedWorkAreaId,
    };
    if (!worker.unit_id || !worker.workarea_id) {
      setCustomError("select unit and designation");
      return;
    }
       isEditing
      ? updateUser({
          ...preLoadedValues,
          ...worker,
        })
      : addUser(worker);
  };

  const handleUnitChange = (value) => {
    setSelectedUnit(value);
    if (selectedUnitId && selectedWorkAreaId) setCustomError(null);
  };

  const handleWorkAreaChange = (value) => {
    setSelectedWorkAreaId(value);
    if (selectedUnitId && selectedWorkAreaId) setCustomError(null);
  };

  useEffect(() => {
    fetchComponentData();
    console.log("preLoadedValues",preLoadedValues);
  }, []);


  const fetchComponentData = async () => {
    const units = await fetchUnits();
    const workAreas = await fetchAllWorkAreas();
    setUnits(units);
    setworkAreas(workAreas);
  };
  return (
    <Container>
      <form onSubmit={handleSubmit(onSubmit)}>
        <CustomInput
          name="name"
          placeholder={"Name"}
          ref={register({
            required: "Required",
            validate: (value) => value !== "admin" || "Nice try!",
          })}
        />
        <ErrorMsg>{errors.name && errors.name.message}</ErrorMsg>

        <CustomInput
          name="emp_id"
          placeholder={"Contract Employee ID"}
          ref={register({
            required: "Required",
            validate: (value) => value.length === 8,
          })}
        />
        <ErrorMsg>{errors.emp_id && "Provide a valid Contract Employee ID"}</ErrorMsg>
        
        <CustomInput
          type="number"
          placeholder={"Phone Number"}
          maxLength="10"
          name="phone_number"
          ref={register({
            required: "Required",
            validate: (value) => value.length === 10,
          })}
        />
        <ErrorMsg>{errors.phone_number && "Provide a valid phone number"}</ErrorMsg>

        <GenderSelection>
          <GenderLable>Gender</GenderLable>
          <Radio.Group
            onChange={(e) => setGender(e.target.value)}
            value={gender}
            defaultValue={isEditing ? preLoadedValues.gender : "male"}
          >
            <Radio value={"male"}>Male</Radio>
            <Radio value={"female"}>Female</Radio>
          </Radio.Group>
        </GenderSelection>

        <CustomInput
          name="contractor_name"
          placeholder={"Contractor Name"}
          ref={register({
            required: "Required",
            validate: (value) => value !== "admin" || "Nice try!",
          })}
        />
        <ErrorMsg>
          {errors.contractor_name && errors.contractor_name.message}
        </ErrorMsg>

      



        <CustomInput
          type="number"
          maxLength="10"
          name="contractor_phone_number"
          placeholder={"Contractor Phone Number"}
          ref={register({
            required: "Required",
            validate: (value) => value.length === 10,
          })}
        />
        <ErrorMsg>
          {errors.contractor_phone_number && "Provide a valid Phone number"}
        </ErrorMsg>

        <AligningWrapper>
          <Select
            style={{ width: "100%" }}
            placeholder="Select Unit"
            onChange={handleUnitChange}
            defaultValue={
              isEditing
                ? preLoadedValues.unit_id
                : units.length
                ? units[0].unit_id
                : null
            }
          >
            {units.length &&
              units.map(({ unit_id: id, name }) => (
                <Option value={id}>{name}</Option>
              ))}
          </Select>
        </AligningWrapper>

        <AligningWrapper>
          <Select
            style={{ width: "100%" }}
            placeholder="Select workarea"
            onSelect={handleWorkAreaChange}
            defaultValue={
              isEditing
                ? preLoadedValues.workarea_id
                : workAreas.length
                ? workAreas[0].workarea_id
                : null
            }
          >
            {workAreas.length &&
              workAreas.map(({ workarea_id: id, workarea_name }) => (
                <Option value={id}>{workarea_name}</Option>
              ))}
          </Select>
        </AligningWrapper>
        <ErrorMsg>{customError}</ErrorMsg>
        <SubmitSection>
          <ActionButtons onClick={() => cancelAction(null)}>
            Cancel
          </ActionButtons>
          <ActionButtons isSubmit type="submit">
            {isEditing ? "Update" : "Submit"}
          </ActionButtons>
        </SubmitSection>
      </form>
    </Container>
  );
};

export default AddUserForm;
