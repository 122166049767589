import * as types from "./types.js";


export const setCameras = (camera) => {
  console.log("Set Cameras : ", camera);
  return {
    type: types.SET_TOTAL_CAMERAS,
    camera,
  }
};

export const CameraSetNameFilter = (nameFilter) => ({
  type: types.CAMERA_SET_NAME_FILTER,
  nameFilter,
});