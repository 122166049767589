import React, { Component } from "react";
import { connect } from "react-redux";
import Table from "../PlatformInitilization/userTable";
import { ManagementSection, BannerHeading } from "./styles";
import {Redirect} from 'react-router-dom';
import { selectZone } from "../../modals/PlatformInitilization/selectors";
import { ZoneSetNameFilter } from "../../modals/PlatformInitilization/actions";
import styled from "styled-components";
import {
    fetchPlant,
    deleteZone,
    addZone,
    updateZone,
} from "../../modals/PlatformInitilization/thunk";
import {
    appFetchAllUnits,
    appFetchAllRoles,
} from "../../modals/app/thunk";
import AddPlantForm from "./AddPlantForm/AddPlantForm";

class PlatformInitilization extends Component {
    
    state = {
        panelOpenType: "",
        activeUser: null,
    };
    componentDidCatch(error, errorInfo) {
        console.log(error, errorInfo, "==>");
    }

    componentDidMount() {
        const { fetchPlant } = this.props;
        fetchPlant();
    }

    toggleUserPanel = () => {
      alert('hello');
    };

    editZone = (zone) => {
        this.setState({ panelOpenType: "edit", activeUser: zone });
    };

    handleDelete = (status) => {
        this.setState({ showconfirmation: status });
    };

    confirmDelete = (zone) => {
        const { deleteZone } = this.props;
        deleteZone(zone);
        this.handleDelete(false);
    };

    render() {
        const Button = styled.button`
  width: 200px;
  height: 40px;
  background-color: green;
  border-radius: 6px;
`;    
        const { panelOpenType, activeUser } = this.state;
        const {
            addZone,
            deleteZone,
            updateZone,
            fetchUnits,
            fetchRoles,
            zone,
            setSearchFilter,
        } = this.props;
        return (
            <ManagementSection>
                <BannerHeading>Platform Initilization</BannerHeading>
                <a href={'/AddPlantForm'} >
                <button style={{width :'200px',  fontSize: '16px', height:'50px', color: 'white', backgroundColor:'#2dd1ac', marginLeft:'30px'}}>
                  
                    Add New Plant</button> </a>
                <Table
                    //action={this.toggleUserPanel}
                    rowData={zone.zone}

                    requiredElements={[
                        "plant_name",
                        "plant_admin",
                        "phone_number",
                        "email",
                        "role_name",
                        "unit_name"

                    ]}
                    columnsElements={[
                        "Plant Name",
                        "Admin Name",
                        "Phone Number",
                        "Email",
                        "Role",
                        "Unit"
                    ]}
                    
                    perPage={10}
                />
                
            </ManagementSection>
        );
    }
}
const mapStateToProps = (state) => ({
    zone: selectZone(state),
});

const mapDispatchToProps = {
    addZone: addZone,
    updateZone: updateZone,
    fetchPlant: fetchPlant,
    deleteZone: deleteZone,
    fetchUnits: appFetchAllUnits,
    fetchRoles: appFetchAllRoles,
    setSearchFilter: ZoneSetNameFilter,
};

export default connect(mapStateToProps, mapDispatchToProps)(PlatformInitilization);
