import React, { Component } from "react";
import { ColumnContainer, Cell, ColumnName, ColumnActions } from "./styles";

class Column extends Component {
  render() {
    const {
      columnsElements = [
        "Employee ID",
        "Name",
        "Phone Number",
        "Unit",
        "Designation",
        "Contractor name",
        "Setup Status",
        "Active",
      ],
      filterFunction,
    } = this.props;
    return (
      <ColumnContainer>
        {columnsElements.map((element, i) => {
          return (
            <Cell size={i === 3 ? 15 : 10}>
              <ColumnName>
               {element} 
              </ColumnName>
              <ColumnActions></ColumnActions>
            </Cell>
          );
        })}
      </ColumnContainer>
    );
  }
}

export default Column;
