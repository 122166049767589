import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { Container } from "@material-ui/core";
import Axios from 'axios';
import {
  CustomInput,
  ActionButtons,
  SubmitSection,
  ErrorMsg,
  AligningWrapper,
} from "./styles";
import { Select } from "antd";

const { Option } = Select;

const AddRuleForm = ({
  cancelAction,
  addRule,
  preLoadedValues,
  isEditing,
  updateRule,
}) => {
 
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedZone, setSelectedZone] = useState(null);
  const [selectedUnitId, setSelectedUnitId] = useState(null);
  const [customError, setCustomError] = useState(null);
  const [zones,setZones] = useState({});
  const { handleSubmit, register, errors, reset } = useForm({
    defaultValues: preLoadedValues || {},
  });

  const onSubmit = async (values) => {
    // reset();
    console.log(
      "clicked data submission ===>",
      values,
      selectedCategory,

    );
    const name = values.name;
    const description = values.description;
    const notification = values.notification;
    const category = selectedCategory;
    const zone = selectedZone;
    const unit_id = selectedUnitId;

    const rule = {
      name,
      description,
      notification,
      category,
      unit_id,
    };
  
    isEditing
      ? updateRule({
        ...preLoadedValues,
        ...rule,
  
      })
      : addRule(rule);
  };

  const handleUnitChange = (value) => {
    setSelectedCategory(value);
    if (selectedCategory)
      setCustomError(null);
  };
  const handleZoneChange = (value) => {
    setSelectedUnitId(value);
    if (selectedUnitId)
      setCustomError(null);
  };

  
   useEffect(() => {
    Axios.get(`https://qcaefqcyp9.execute-api.ap-south-1.amazonaws.com/prod/fetchunitsall`
  ).then(
    (response) => {
  
      setZones(response.data.data);
    },
    (error) => {
      console.log(error);
    }
  );
   }, []);

  return (
    <Container>
      <form onSubmit={handleSubmit(onSubmit)}>
        <CustomInput
          name="name"
          placeholder={"Name"}
          ref={register({
            required: "Required",
            validate: (value) => value !== "admin" || "Nice try!",
          })}
        />
        <ErrorMsg>{errors.name && errors.name.message}</ErrorMsg>
        <CustomInput

          name="description"
          placeholder={"Description"}
          ref={register({
            required: "Required",
            validate: (value) => value.length != 0,
          })}
        />
        <ErrorMsg>{errors.description && "Provide Description"}</ErrorMsg>

       

        <AligningWrapper>
          <Select
            style={{ width: "100%" }}
            name = "category"
            placeholder="Select Category"
            onChange={handleUnitChange} >
           
                <Option value={"Security"}>Security</Option>
                <Option value={"Safety"}>Safety</Option>
                <Option value={"PPE"}>PPE</Option>
             
          </Select>
        </AligningWrapper>
        <AligningWrapper>
          <Select
          mode = "multiple"
            style={{ width: "100%" }}
            name = "category"
            placeholder="Select Unit"
            onChange={handleZoneChange} >
           
           {zones.length &&
              zones.map(({ unit_id: id, name }) => (
                <Option value={id}>{name}</Option>
              ))}
             
          </Select>
        </AligningWrapper>

        <CustomInput
            name="notification"
            placeholder={"Notification Message"}
            ref={register({
            required: "Required",
            validate: (value) => value.length != 0,
        })}
        />
        <ErrorMsg>{errors.description && "Provide Notification Message"}</ErrorMsg>

        <SubmitSection>
          <ActionButtons onClick={() => cancelAction(null)}>
            Cancel
          </ActionButtons>
          <ActionButtons isSubmit type="submit">
            Submit
          </ActionButtons>
        </SubmitSection>
      </form>
    </Container>
  );
};

export default AddRuleForm;
