import React, { useState, useEffect } from "react";
import { connect } from "react-redux"; 
import { Select } from "antd"; 
import Axios from "axios";
import styled from "styled-components";
import "../information-style";
import { useForm } from "react-hook-form";
import "../information-style.js";  
import {
useStyles,
ShiftsWrapper,
} from "../information-style";
import {
CustomInput,
ErrorMsg,
} from "./styles";
const { Option } = Select;
const ViewPlantForm = ({
addPlant,
updatePlant,
preLoadedData,
isEditing,
}) => { 
const { handleSubmit, register, errors, reset } = useForm({
defaultValues: preLoadedData || {},
});
isEditing = true;
const { wizardData, saveData, currentZone } ={};
const classes = useStyles();
const [severity, setSeverity] = useState("Active");
const [units, setUnits] = useState([]);
const [roles, setRoles] = useState([]);
const [selectedUnitId, setUnitInfo]  = useState(null);
const [selectedRoleId, setRoleInfo]  = useState(null);
const [preLoadedValues, setPreLoadedValues]  = useState(null);
const [imgUrl,setImageUrl] = useState(null);
const handleUnitChange = (value) => {
setUnitInfo(value);
};
const handleRoleChange = (value) => {
setRoleInfo(value);
}; 
useEffect(() => {
   
      Axios.get("https://qcaefqcyp9.execute-api.ap-south-1.amazonaws.com/prod/fetchuserroleall").then(
      (res) => {
      setRoles(res.data.data);
      }
      );
      Axios.get("https://qcaefqcyp9.execute-api.ap-south-1.amazonaws.com/prod/fetchunitsall").then(
      (res) => {
      setUnits(res.data.data);
      }
      );
      Axios.get("https://qcaefqcyp9.execute-api.ap-south-1.amazonaws.com/prod/fetchplant").then(
         (res) => {
            console.log("data",res.data.data)
         setPreLoadedValues(res.data.data[0]);
         }
         ); 
}, []);

const onSubmit = async (values) => {
// reset();
console.log(
"clicked data submission ===>",
values,
selectedUnitId,
selectedRoleId
);
const plant_name = values.plant_name;
const plant_address = values.plant_address;
const latitude = values.latitude;
const longitude = values.longitude;
const plant_admin = values.plant_admin;
const email = values.Axiosemail;
const unit_id = selectedUnitId;
const role_id = selectedRoleId;
const img_url = imgUrl;
const user = {
plant_name,
plant_address,
latitude,
longitude,
plant_admin,
email,
unit_id,
role_id,
img_url
};
console.log('isEditing :', isEditing)
isEditing
? updatePlant({
...preLoadedValues,
...user,
})
: addPlant(user);
};
return (
   preLoadedValues &&(
<Content>
   <form onSubmit={handleSubmit(onSubmit)}>
      <div className={classes.content}>
         
         <div className={classes.left}>
            <Heading>Platform Initialization</Heading>
            <CustomInput
            name = "plant_name"
            value ={preLoadedValues.plant_name}
            placeholder={"Plant Name"}
            ref={register({
            required: "Required",
            validate: (value) => value.length != 0,
            })}
            />
            <ErrorMsg>{errors.plant_name && "Enter Plant Name"}</ErrorMsg>
            <CustomInput
            name = "plant_address"
            placeholder={"Plant Address"}
            value ={preLoadedValues.plant_address}
            ref={register({
            required: "Required",
            validate: (value) => value.length != 0,
            })}
            />
            <ErrorMsg>{errors.plant_address && "Enter Plant Address"}</ErrorMsg>
            <CustomInput
            name = "latitude"
            type = "number"
            value ={preLoadedValues.latitude}
            placeholder={"Latitude"}
            ref={register({
            required: "Required",
            validate: (value) => value.length != 0,
            })}
            />  
            <ErrorMsg>{errors.latitude && "Enter Plant Latitude"}</ErrorMsg>
            <CustomInput
            name = "longitude"
            placeholder={"Longitude"}
            value ={preLoadedValues.longitude}
            type = "number"
            ref={register({
            required: "Required",
            validate: (value) => value.length != 0,
            })}
            /> 
            <ErrorMsg>{errors.longitude && "Enter Plant Longitude"}</ErrorMsg>
            <CustomInput
            name = "plant_admin"
            placeholder={"Plant Admin"}
            value ={preLoadedValues.plant_admin}
            ref={register({
            required: "Required",
            validate: (value) => value.length != 0,
            })}
            />  
            <ErrorMsg>{errors.plant_admin && "Enter Plant Admin Name"}</ErrorMsg>
            <CustomInput
            name = "phone_number"
            placeholder={"Phone Number"}
            value ={preLoadedValues.phone_number}
            ref={register({
            required: "Required",
            validate: (value) => value.length != 0,
            })}
            />
            <ErrorMsg>{errors.phone_number && "Enter Plant Admin Phone Number"}</ErrorMsg>
            <CustomInput
            name = "email"
            placeholder={"Email Id"}
            value ={preLoadedValues.email}
            ref={register({
            required: "Required",
            validate: (value) => value.length != 0,
            })}
            />   
            <ErrorMsg>{errors.email && "Enter Plant Admin Email"}</ErrorMsg>
            <ShiftsWrapper>
               <Select
               style={{ width: "100%" }}
               placeholder="Select Role"
               onChange={handleRoleChange}
               defaultValue={
               isEditing
               ? preLoadedValues.role_id
               : roles.legth && roles[0].role_id
               }
               >
               {roles.length &&
               roles.map(({ role_id: id, role_name }) => (
               <Option value={id}>{role_name}</Option>
               ))}
               </Select>
            </ShiftsWrapper>
            <ErrorMsg>{errors.role_id && "Select Role"}</ErrorMsg>
            <ShiftsWrapper>
               <Select
               style={{ width: "100%" }}
               placeholder="Select Unit"
               onChange={handleUnitChange}
               defaultValue={
               isEditing
               ? preLoadedValues.unit_id
               : units.legth && units[0].unit_id
               }
               >
               {units.length &&
               units.map(({ unit_id: id, name }) => (
               <Option value={id}>{name}</Option>
               ))}
               </Select>
            </ShiftsWrapper>
            <ErrorMsg>{errors.unit_id && "Select Role"}</ErrorMsg>
            
         </div>
         <div className={classes.right}>
            <img style={{height:'650px',width: 'fit-content' }} className={classes.rightImg} src={preLoadedValues.img_url} alt="Map"></img>
         </div>
      </div>
   </form>
</Content>
   )
);
}
const mapStateToProps = (state) => ({
});
const mapDispatchToProps = {
};
export default connect(mapStateToProps, mapDispatchToProps)(ViewPlantForm);
const Wrapper = styled.div`
padding: 20px;
position: relative;
`;
const Content = styled.div`
${"" /* margin: 24px 32px; */}
`;
const Heading = styled.h1`
margin: 0 0 20px;
color: wheat;
font-size: 26px;
font-weight: normal;
color: white;
`;
const ButtonOuter = styled.div`
background: #111728;
display: flex;
justify-content: flex-end;
padding: 10px 0;
left: 0;
width: 100%;
`;
export const ErrorHighlighter = styled.p`
margin: 1rem;
color: red;
`;