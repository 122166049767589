import styled from "styled-components";

export const ManagementSection = styled.div`
  background: #0f1322;
  height: 100%;
`;

export const BannerHeading = styled.div`
  font-size: 2rem;
  padding: 1rem 1.5rem;
  color: #fff;
`;

export const SwitchStyle = styled.div`
& .ant-switch {
  border: 1px solid #2dd1ac;
}
& .ant-switch-checked {
  background-color: #2dd1ac;
}
`;