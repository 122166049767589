import { selectZone } from "./selectors";
import { setZones } from "./actions";
import axios from "axios";
import { fetchZoneUrl, fetchWipUrl, fetchunitsUrl, addWipUrl } from "./api";
import { appShowPushNotification } from "../app/thunk";
 

export const fetchZone = () => async (dispatch, getState) => {
  try {
    const {
      data: { data: roles },
    } = await axios.get(fetchZoneUrl);
    return roles;
  } catch (err) {
    console.error(`failed to fetch roles with ${err}`);
    return [];
  }
};

export const fetchUnits = () => async (dispatch, getState) => {
  try {
    const {
      data: { data: roles },
    } = await axios.get(fetchunitsUrl);
    return roles;
  } catch (err) {
    console.error(`failed to fetch roles with ${err}`);
    return [];
  }
};
export const fetchWipList = () => async (dispatch, getState) => {
  
  const { data: zone } = await axios.get(fetchWipUrl);
  console.log("fetchWipList fetched data ==>", zone.data);
  dispatch(setZones(zone ? zone.data : []));
};



export const addWip = (zone) => async (dispatch, getState) => {
  try {
   // console.log("add Zone data before post ==>", zone);
    dispatch(appShowPushNotification("Adding Wip Details"));
    console.log('Zone from thunk', JSON.stringify(zone));
    const response = await axios.post(addWipUrl, JSON.stringify(zone));
    //console.log(response);
    dispatch(fetchWipList());
    dispatch(appShowPushNotification("successfully added WIP details"));
  } catch (err) {
    dispatch(appShowPushNotification("failed to add WIP details, try again"));
    console.error(`failed to add Zone with ${err}`);
  }
};

 
