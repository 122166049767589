import { selectCamera } from "./selectors";
import { setCameras } from "./actions";
import axios from "axios";
import { fetchCameraUrl, deleteCameraUrl, addCameraUrl, updateCameraUrl } from "./api";
import { appShowPushNotification } from "../app/thunk";

export const deleteCamera = (camera) => async (dispatch, getState) => {
  //const camera = selectCamera(getState());
  console.log(camera);
  //const updatedCameras = camera.filter((list) => list.id !== camera.id);
  //dispatch(setCameras(updatedCameras));
  //api request
  const respone = await axios.post(deleteCameraUrl, {
    camera_id: camera.camera_id,
  });
  dispatch(fetchCamera());
  console.log("api data ==>", respone);
  dispatch(appShowPushNotification("successfully deleted user"));
};

export const fetchCamera = () => async (dispatch, getState) => {
  const { data: camera } = await axios.get(fetchCameraUrl);
  console.log("Cameras fetched data ==>", camera.data);
  dispatch(setCameras(camera ? camera.data : []));
};

export const addCamera = (camera) => async (dispatch, getState) => {
  try {
    console.log("add Camera data before post ==>", camera);
    dispatch(appShowPushNotification("adding Camera in Camera management"));
    console.log('Camera from thunk', JSON.stringify(camera));
    const response = await axios.post(addCameraUrl, camera);
    console.log(response);
    dispatch(fetchCamera());
    dispatch(appShowPushNotification("successfully added Camera"));
  } catch (err) {
    dispatch(appShowPushNotification("failed to add Camera, try again"));
    console.error(`failed to add Camera with ${err}`);
  }
};

export const updateCamera = (camera) => async (dispatch, getState) => {
  dispatch(appShowPushNotification("updating Camera"));
  try {
    console.log("updating Camera ==>", camera);
    const updateResponse = await axios.post(updateCameraUrl, camera);
    dispatch(fetchCamera());
    dispatch(appShowPushNotification("successfully updated Camera"));
    console.log("updated response ==>", updateResponse);
  } catch (err) {
    dispatch(appShowPushNotification("failed to update Camera, try again"));
    console.error(`failed to update Camera with ${err}`);
  }
};
