import React, { Component } from "react";
import { connect } from "react-redux";
import Table from "../NotificationGroupManagement/userTable";
import AddNotificationGroupForm from "../NotificationGroupManagement/AddNotificationGroupForm"
import { ManagementSection, BannerHeading } from "./styles";
import Panel from "../common/panelUser";

import { selectNotificationGroup} from "../../modals/NotificationGroupManagement/selectors";
import { NotificationGroupSetNameFilter } from "../../modals/NotificationGroupManagement/actions";
import {
    fetchNotificationGroup,
    deleteNotificationGroup,
    addNotificationGroup,
    updateNotificationGroup,
} from "../../modals/NotificationGroupManagement/thunk";
import {
    appFetchAllUnits,
    appFetchAllRoles,
} from "../../modals/app/thunk";


class NotificationGroupManagement extends Component {
    state = {
        panelOpenType: "",
        activeUser: null,
    };
    componentDidCatch(error, errorInfo) {
        console.log(error, errorInfo, "==>");
    }

    componentDidMount() {
        const { fetchNotificationGroup } = this.props;
        fetchNotificationGroup();
    }

    toggleUserPanel = (panelOpenType) => {
        if (!panelOpenType) this.setUser(null);
        this.setState({ panelOpenType });
    };

    editNotificationGroup = (notificationgroup) => {
        this.setState({ panelOpenType: "edit", activeUser: notificationgroup });
    };

    handleDelete = (status) => {
        this.setState({ showconfirmation: status });
    };

    confirmDelete = (notificationgroup) => {
        const { deleteNotificationGroup } = this.props;
        deleteNotificationGroup(notificationgroup);
        this.handleDelete(false);
    };
    setUser = (user) => {
        this.setState({ activeUser: null });
      };

    render() {
        const { panelOpenType, activeUser } = this.state;
        const {
            addNotificationGroup,
            deleteNotificationGroup,
            updateNotificationGroup,
            ngroup,
            fetchRoles,
            notificationgroup,
            setSearchFilter,
        } = this.props;
        return (
            <ManagementSection>
                <BannerHeading>Notification Group Management</BannerHeading>
                <Table
                    action={this.toggleUserPanel}
                    rowData={notificationgroup.notificationgroup }

                    requiredElements={[
                        "name",
                        "no_of_users",
                        "last_edited",
                        "is_active",

                    ]}
                    columnsElements={[
                        "Group Name",
                        "No of Users",
                        "Last edited",
                        "Active"                     
                    ]}
                    panelType={panelOpenType}
                    editNotificationGroup={this.editNotificationGroup}
                    deleteNotificationGroup={this.confirmDelete}
                    setSearchFilter={setSearchFilter}
                    perPage={10}
                />
               
                <Panel
                    panelType={panelOpenType}
                    isPanelOpen={Boolean(panelOpenType)}
                    Heading={
                        panelOpenType === "edit"
                            ? "Update NotificationGroup details"
                            : panelOpenType === "add"
                                ? "Add NotificationGroup"
                                : panelOpenType === "searchBy"
                                    ? "Search By"
                                    : null
                    }
                    closeAction={this.toggleUserPanel}
                >

                    { panelOpenType && (
                        <AddNotificationGroupForm
                            cancelAction={this.toggleUserPanel}
                            addNotificationGroup={addNotificationGroup}
                            deleteNotificationGroup={deleteNotificationGroup}
                            ngroup={notificationgroup}
                            fetchRoles={fetchRoles}
                            preLoadedValues={activeUser}
                            isEditing={panelOpenType === "edit"}
                            updateNotificationGroup={updateNotificationGroup}
                        />
                    )}
                </Panel>
            </ManagementSection>
        );
    }
}

const mapStateToProps = (state) => ({
    notificationgroup: selectNotificationGroup(state),
});

const mapDispatchToProps = {
    addNotificationGroup: addNotificationGroup,
    updateNotificationGroup: updateNotificationGroup,
    fetchNotificationGroup: fetchNotificationGroup,
    deleteNotificationGroup: deleteNotificationGroup,
    fetchUnits: appFetchAllUnits,
    fetchRoles: appFetchAllRoles,
    setSearchFilter: NotificationGroupSetNameFilter,
};

export default connect(mapStateToProps, mapDispatchToProps)(NotificationGroupManagement);
