import React, { Component } from "react";
import { withRouter } from "react-router";
import {
  CardContainer,
  SevierityIndicatior,
  DetailsBody,
} from "./styles";
import moment from "moment";

class NotificationCard extends Component {
  onClickHandler = () => {
    let { notification_id } = this.props.notification;
    this.props.history.push("/NotificationDescription/" + notification_id);
  };
  render() {
    const {
      severity,
      message,
      generated_time,
      location,
    } = this.props.notification;
    return (
      <CardContainer onClick={this.onClickHandler}>
        <SevierityIndicatior severity={severity}></SevierityIndicatior>
        <DetailsBody>
      
          <table>
          <tr>
              <td style={{ fontSize: 12, width: "35%" }}>{message}</td>
              <td style={{ fontSize: 12, width: "35%" }}>{location}</td>
              <td style={{ fontSize: 12, width: "30%" }}>
                {moment
                  .utc(generated_time)
                  .local()
                  .format("DD/MM/YYYY, h:mm a")}
              </td>
            </tr>
          </table>
        </DetailsBody>
      </CardContainer>
    );
  }
}

export default withRouter(NotificationCard);
