import { setNotificationGroups } from "./actions";
import axios from "axios";
import { fetchNotificationGroupUrl, deleteNotificationGroupUrl, addNotificationGroupUrl, updateNotificationGroupUrl } from "./api";
import { appShowPushNotification } from "../app/thunk";

export const deleteNotificationGroup = (notificationgroup) => async (dispatch, getState) => {
  console.log(notificationgroup);
  const respone = await axios.post(deleteNotificationGroupUrl, {
    notificationgroup_id: notificationgroup.notificationgroup_id,
  });
  dispatch(fetchNotificationGroup());
  console.log("api data ==>", respone);
  dispatch(appShowPushNotification("successfully deleted user"));
};

export const fetchNotificationGroup = () => async (dispatch, getState) => {
  const { data: notificationgroup } = await axios.get(fetchNotificationGroupUrl);
  dispatch(setNotificationGroups(notificationgroup ? notificationgroup.data : []));
};

export const addNotificationGroup = (notificationgroup) => async (dispatch, getState) => {
  try {
    console.log("add NotificationGroup data before post ==>", notificationgroup);
    dispatch(appShowPushNotification("adding notificationgroup in NotificationGroup management"));
    console.log('NotificationGroup from thunk', JSON.stringify(notificationgroup));
    const response = await axios.post(addNotificationGroupUrl, notificationgroup);
    console.log(response);
    dispatch(fetchNotificationGroup());
    dispatch(appShowPushNotification("successfully added NotificationGroup"));
  } catch (err) {
    dispatch(appShowPushNotification("failed to add NotificationGroup, try again"));
    console.error(`failed to add NotificationGroup with ${err}`);
  }
};

export const updateNotificationGroup = (notificationgroup) => async (dispatch, getState) => {
  dispatch(appShowPushNotification("updating NotificationGroup"));
  try {
    console.log("updating NotificationGroup ==>", notificationgroup);
    const updateResponse = await axios.post(updateNotificationGroupUrl, notificationgroup);
    dispatch(fetchNotificationGroup());
    dispatch(appShowPushNotification("successfully updated NotificationGroup"));
    console.log("updated response ==>", updateResponse);
  } catch (err) {
    dispatch(appShowPushNotification("failed to update NotificationGroup, try again"));
    console.error(`failed to update NotificationGroup with ${err}`);
  }
};
