import { createSelector } from "reselect";
import { compare } from "../../Utils";

export const selectUsers = ({ users }) => users;

export const platformUsersSelectSearchFilter = ({

    filters: { nameFilter },

}) => {
  return nameFilter;
};

export const platformUsersSelectFilteredList = createSelector(
  [selectUsers, platformUsersSelectSearchFilter],
  (users, nameFilter) => {
    if (!users.length) return users;
    users.sort(compare);
    if (!nameFilter) return users;
    const filteredUsers = users.filter(
      ({ name }) => name.toUpperCase().indexOf(nameFilter.toUpperCase()) > -1
    );
    return filteredUsers;
  }
);
