import React from 'react';
import DateRangePicker from "react-bootstrap-daterangepicker";

import { Info } from "@material-ui/icons";

import './datepicker.css'
const AnalyticsHeader = () => {

    const styles = {
        width: 50,
        display: "block",
        marginBottom: 10,
        format: "DD-MM-YYYY",
    };

    const handleEvent = (event, picker) => {
        console.log(picker.startDate);
    };

    const handleCallback = (start, end, label) => {
        console.log("handleCallback", start);
    };

    return (
        <div className="iiq-analytics-header">
            <div className="flex-row title-row">
                <h1>Analytics</h1>
            </div>
            <div className="flex-row date-picker-row">
                <label>
                    Report Date
                </label>
                <DateRangePicker
                    onEvent={handleEvent}
                    onCallback={handleCallback}
                    size="md"
                    style={styles}
                >
                    <input
                        type="text"
                        className="iiq-form-control-date-picker"
                        style={{ width: "250px", height: "40px" }}
                    />
                </DateRangePicker>
            </div>
        </div>
    );
}

export default AnalyticsHeader;