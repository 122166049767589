import React, { useState, useEffect } from "react";
import Axios from "axios";
import LeftArrow from "../../../assets/left-arrow.png";
import RightArrow from "../../../assets/right-arrow.png";
import Edit from "../../../assets/pencil.png";
import Save from "../../../assets/check-mark.png";
import Cancel from "../../../assets/close-button.png";
import { useStyles } from "../../styles/layoutStyles";
import { Grid } from "@material-ui/core";
import "./notificationDescription.css";
import { ManagementSection, UsersSection, CustomInput, notificationSliderContainer } from "./styles";
import moment from "moment";
import Reject from "../../../assets/reject.png";
import RejectFull from "../../../assets/reject-full.png";
import ApproveFull from "../../../assets/approve-full.png";
import Approve from "../../../assets/like.png";
import { useHistory } from "react-router-dom";
import { ArrowBackIos, Notifications } from "@material-ui/icons";
import _ from "lodash";
import { Modal } from "antd";

export default function NotificationDescription(Notification_id) {
  const classes = useStyles();
  const [details, setDetails] = React.useState([]);
  const [vehiclenr, setVehiclenr] = React.useState("");
  const [message, setMessage] = React.useState(null);
  const [localvalue, setLocalvalue] = React.useState(0);
  const [notification, setNotification] = React.useState();
  const [index, setIndex] = React.useState(0);
  const [hidediv, setHideDiv] = React.useState(false);
  const [rejectFull, setRejectFull] = React.useState(false);
  const [approveFull, setApproveFull] = React.useState(false);
  const [editok, setEditok] = React.useState(false);
  const [showError, setshowError] = React.useState(false);
  const [actionError, setActionError] = React.useState(false);
  const [apiCallInProgress, setapiCallInProgress] = React.useState(false);
  const [notificationArray, setNotificationArray] = React.useState([]);
  const [info, setInfo] = useState(null);
  const [isShowModal, setIsShowModal] = useState(false);
  const [apiError, setAPIError] = useState(null);

  useEffect(() => {
    if(apiError && isShowModal != true) {
      setIsShowModal(true);
    }
  }, [apiError]);

  useEffect(() => {
    if(isShowModal) {
      Modal.error({
        wrapClassName: 'error-modal',
        title: 'Oops!',
        content: "We're sorry, but something went wrong.",
        onOk() {
          setIsShowModal(false);
        }
      });
    }
  },[isShowModal]);

  let history = useHistory();
  let cameraId = 0;

  const handleChange = (event) => {
    setVehiclenr(event.target.value);

    // console.log(event.target.value);
  };

  const messageHandler = (event) => {
    setMessage(event.target.value);
  };

  const handleOnClick = (current_vehiclenr) => {
    setVehiclenr(current_vehiclenr);
    setEditok(true);
  };
  const handleOnClickCancel = () => {
    setEditok(false);
  };
  const handleOnClickSave = () => {
    console.log(vehiclenr);
    setEditok(false);
    Axios.post(
      `https://qcaefqcyp9.execute-api.ap-south-1.amazonaws.com/prod/updatevehiclenumber`,
      {
        id: Notification_id.match.params.notification_id,
        vehicle_number: vehiclenr,
      }
    ).then((response) => {
      console.log(response);
      fetchData();
    }).catch((err) => {
      setAPIError(err);
    });;
  };
  const getIndex = (value, arr) => {
    for (var i = 0; i < arr.length; i++) {
      if (arr[i] == value) {
        setIndex(i);
        console.log("index set");
      }
    }
    return -1;
  };

  React.useEffect(() => {
    if (localStorage.getItem("role_id") == undefined) history.push("/login");
    else setLocalvalue(localStorage.getItem("role_id"));
  }, []);

  const fetchNotificationArray = () => {
    //setCameraId(cameraId);
    setNotification(Notification_id.match.params.notification_id);
    Axios.post(
      `https://qcaefqcyp9.execute-api.ap-south-1.amazonaws.com/prod/getnotificationarray`,
      {
        camera_id: cameraId,
      }
    ).then(
      (response) => {
        setNotificationArray(response.data.data);
        getIndex(
          Notification_id.match.params.notification_id,
          response.data.data
        );
      },
      (error) => {
        setAPIError(error);
      }
    );
  };

  const onSubmitHandler = () => {
    if (approveFull || rejectFull) {
      setshowError(false);
      if (approveFull) {
        if (message === null) {
          setMessage("");
          submitHandler("Approved");
          setActionError(false);
          setMessage("");
        } else {
          submitHandler("Approved");
          setActionError(false);
          setMessage("");
        }
      } else {
        if (message == "" || message == null) {
          setActionError(true);
        } else {
          submitHandler("Deferred");
          setActionError(false);
          setMessage("");
        }
      }
      return;
    } else {
      setshowError(true);
      return;
    }
  };

  const submitHandler = (status) => {
    console.log("message", message + "Status ->" + status);
    setapiCallInProgress(true);
    Axios.post(
      `https://qcaefqcyp9.execute-api.ap-south-1.amazonaws.com/prod/notificationstatusupdate`,
      {
        id: Notification_id.match.params.notification_id,
        status: status,
        message: message,
      }
    ).then(
      (response) => {
        setapiCallInProgress(false);
        console.log(response);
        setMessage(null);
        setApproveFull(false);
        setRejectFull(false);
        if (index > 0) {
          onClickHandlerPrevious();
        } else {
          onClickHandlerNext();
        }
      },
      (error) => {
        setapiCallInProgress(false);
        setAPIError(error);
        console.log(error);
      }
    );
    setMessage(null);
  };

  const formatConfidenceRatio = (ratio) => {
    if (ratio) {
      return ratio * 100;
    }
  };
  const formatDate = (date) => {
    if (date) {
      return (
        new Date(date).getDate() +
        "/" +
        (new Date(date).getMonth() + 1) +
        "/" +
        new Date(date).getFullYear()
      );
    }
  };

  const formatTime = (date) => {
    if (date) {
      var time = new Date(date * 1000);
      return (
        time.getHours() + ": " + time.getMinutes() + ": " + time.getSeconds()
      );
    }
  };

  const onClickHandlerPrevious = () => {
    console.log("Index : ", index);
    let notification_id = notificationArray[index - 1];
    history.push("/NotificationDescription/" + notification_id);
    setNotification(notification_id);
    getIndex(notification_id, notificationArray);
  };
  const onClickHandlerNext = () => {
    console.log("Index : ", index);
    let notification_id = notificationArray[index + 1];
    history.push("/NotificationDescription/" + notification_id);
    setNotification(notification_id);
    getIndex(notification_id, notificationArray);
  };
  const onClickApprove = () => {
    setRejectFull(false);
    setApproveFull(true);
  };
  const onClickReject = () => {
    setRejectFull(true);
    setApproveFull(false);
    console.log("onClickReject : ", hidediv);
  };

  const fetchData = () => {
    const not_id = {
      id: Notification_id.match.params.notification_id,
    };
    Axios.post(
      `https://qcaefqcyp9.execute-api.ap-south-1.amazonaws.com/prod/fetchnotificationbyid`,
      not_id
    )
      .then((res) => {
        if(res && res.data && res.data.data.length > 0) {
          setDetails(res.data.data[0]);
          cameraId = res.data.data[0].camera_id;
        } else {
          setInfo("No Data!");
        }
        
        // alert(Object.toString(res.data.data));
        fetchNotificationArray();
      })
      .catch((err) => {
        setAPIError(err);
      });
  };
  React.useEffect(() => {
    setHideDiv(false);
    setEditok(false);
    fetchData();
  }, [Notification_id.match.params.notification_id]);

  const handleGoBack = () => {
    history.push(`/preview-camera/${details.camera_id}`);
  }

  return (
    <UsersSection>
      <ManagementSection>
        <main className={classes.content}>
          
            <div className="preview">
              <span
                style={{ fontWeight: "bold", fontFamily: "'Roboto', sans-serif" }}
                className="SMS-LRF-1"
              > 
               <ArrowBackIos onClick={() => handleGoBack()} fontSize="large" className="iiq-back-btn"/>
                Notification
              </span>
            </div>
            <div className="flex show-info">{info && info}</div>
            { details && !_.isEmpty(details) &&
            <div>
              <Grid container>
                <Grid item xs={1}></Grid>
                <Grid item xs={10}>
                  <div className="wrapper">
                    <section className="content">
                      <div className="columns">
                        <aside className="sidebar-first">
                          {index > 0 ? (
                            <img
                              src={LeftArrow}
                              style={{ width: "50px", cursor: "pointer" }}
                              onClick={onClickHandlerPrevious}
                            />
                          ) : (
                            " "
                          )}
                        </aside>
                        <main className="main slider-content">
                          <img
                            style={{ minWidth: "200px", maxHeight: "400px"}}
                            src={details && details.resource_url}
                          ></img>
                        </main>
                        <aside className="sidebar-second">
                          {index < notificationArray.length - 1 ? (
                            <img
                              src={RightArrow}
                              style={{ width: "50px", cursor: "pointer" }}
                              onClick={onClickHandlerNext}
                            />
                          ) : (
                            " "
                          )}
                        </aside>
                      </div>
                    </section>
                  </div>
                </Grid>
                <Grid item xs={1}></Grid>
              </Grid>
            <div className="preview">
              <Grid container style={{ height: "25%" }}>
                <Grid item xs={10} className="iiq--notification-details">
                  <Grid container style={{ border: "2px solid black" }}>
                    <Grid item xs={6}>
                      <div
                        style={{
                          paddingBottom: "10px",
                        }}
                      >
                        <div
                          style={{
                            height: "40px",
                            fontSize: 16,
                            padding: "5px 20px",
                            borderBottom: "1px solid black",
                          }}
                        >
                          Information
                        </div>
                        <div className="table1">
                        <div className="tr">
                            <div className="name">Notification Id</div>
                            <div className="colon">:</div>
                            <div className="val">{Notification_id.match.params.notification_id}</div>
                          </div>
                          <div className="tr">
                            <div className="name">Zone Name</div>
                            <div className="colon">:</div>
                            <div className="val">{details && details.zone_name}</div>
                          </div>
                          <div className="tr">
                            <div className="name">Camera</div>
                            <div className="colon">:</div>
                            <div className="val">{details && details.camera_name}</div>
                          </div>
                          <div className="tr">
                            <div className="name">Location</div>
                            <div className="colon">:</div>
                            <div className="val">{details && details.location}</div>
                          </div>
                          <div className="tr">
                            <div className="name">Alert Message</div>
                            <div className="colon">:</div>
                            <div className="val">{details && details.notification}</div>
                          </div>

                          {details && details.zone_name === "Vehicle Speed Monitor" && (
                            <div className="tr">
                              <div className="name">Vehicle Number</div>
                              <div className="colon">:</div>
                              <div className="val">
                                {details && details.vehicle_number != null ? (
                                  editok ? (
                                    <span>
                                      <CustomInput
                                        onChange={handleChange}
                                        value={vehiclenr}
                                      />
                                      <img
                                        src={Save}
                                        width={15}
                                        style={{
                                          margin: "10px",
                                          cursor: "pointer",
                                        }}
                                        onClick={handleOnClickSave}
                                        // value={vehiclenr}
                                      />
                                      <img
                                        src={Cancel}
                                        width={10}
                                        style={{
                                          margin: "10px",
                                          cursor: "pointer",
                                        }}
                                        onClick={handleOnClickCancel}
                                      />
                                    </span>
                                  ) : (
                                    <span>
                                      {" "}
                                      {details.vehicle_number}
                                      <img
                                        src={Edit}
                                        width={15}
                                        style={{
                                          margin: "10px",
                                          cursor: "pointer",
                                          marginLeft: "50px",
                                        }}
                                        onClick={() =>
                                          handleOnClick(details.vehicle_number)
                                        }
                                      />
                                    </span>
                                  )
                                ) : editok ? (
                                  <span>
                                    <CustomInput
                                      placeholder={"Enter Vehicle Number"}
                                      onChange={handleChange}
                                    />
                                    <img
                                      src={Save}
                                      width={15}
                                      style={{
                                        margin: "10px",
                                        cursor: "pointer",
                                      }}
                                      onClick={handleOnClickSave}
                                    />
                                    <img
                                      src={Cancel}
                                      width={10}
                                      style={{
                                        margin: "10px",
                                        cursor: "pointer",
                                      }}
                                      onClick={handleOnClickCancel}
                                    />
                                  </span>
                                ) : (
                                  <span>
                                    <span className="name">
                                      Number not recognised
                                    </span>
                                    <img
                                      src={Edit}
                                      width={15}
                                      style={{
                                        margin: "10px",
                                        cursor: "pointer",
                                      }}
                                      onClick={handleOnClick}
                                    />
                                  </span>
                                )}
                              </div>
                            </div>
                          )}
                          {details && details.vehicle_speed != null && (
                            <div className="tr">
                              <div className="name">Vehicle Speed</div>
                              <div className="colon">:</div>
                              <div className="val">
                                {details.vehicle_speed} km/h
                              </div>
                            </div>
                          )}
                          {details && details.vehicle_type != null && (
                            <div className="tr">
                              <div className="name">Vehicle Type</div>
                              <div className="colon">:</div>
                              <div className="val">{details.vehicle_type}</div>
                            </div>
                          )}
                          <div className="tr">
                            <div className="name">Date</div>
                            <div className="colon">:</div>
                            <div className="val">
                              {formatDate(details.generated_time)}
                            </div>
                          </div>
                          <div className="tr">
                            <div className="name">Time</div>
                            <div className="colon">:</div>
                            <div className="val">
                              {moment
                                .utc(details.generated_time)
                                .local()
                                .format("LTS")}
                            </div>
                          </div>
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={6}>
                      <div
                        style={{
                          paddingBottom: "10px",

                          borderLeft: "2px solid black",
                        }}
                      >
                        <div
                          style={{
                            borderBottom: "1px solid black",
                            height: "40px",
                            fontSize: 16,
                            padding: "5px 20px",
                          }}
                        >
                          Action
                        </div>

                        <div>
                          <div style={{ padding: "20px" }}>
                            <span>Status</span>
                            <img
                              className="Approve"
                              src={approveFull ? ApproveFull : Approve}
                              style={{
                                marginLeft: "5%",
                                width: "25px",
                                cursor: "pointer",
                                marginTop: "0%",
                              }}
                              onClick={onClickApprove}
                            />

                            <img
                              className="Reject"
                              src={rejectFull ? RejectFull : Reject}
                              style={{
                                marginLeft: "10%",
                                width: "25px",
                                cursor: "pointer",
                                marginTop: "2%",
                              }}
                              onClick={onClickReject}
                            />
                          </div>
                          <div
                            className="ErrorMsg"
                            style={{ display: showError ? "flex" : "none" }}
                          >
                            Select Approve or Reject Option !!
                          </div>
                          <div>
                            <div>
                              {/* <input
                                placeholder="Action taken"
                                name="action"
                                value={message}
                                style={{
                                  marginLeft: "20px",
                                  backgroundColor: "#111728",
                                  border: "1px solid black",
                                  width: "50%",
                                  height: "32px",
                                  marginTop: "5px",
                                }}
                                onChange={messageHandler}
                              /> */}

                              <textarea 
                                placeholder="Comments" 
                                onChange={messageHandler}
                                maxLength={250}
                                value={message}
                                style={{
                                  marginLeft: "20px",
                                  backgroundColor: "#111728",
                                  border: "1px solid black",
                                  height: "100px",
                                  marginTop: "5px",
                                  width: "calc(100% - 40px)",
                                  borderRadius: "5px"
                                }}></textarea>
                              <br></br>
                              <div
                                className="ErrorMsg"
                                style={{ display: actionError ? "flex" : "none" }}
                              >
                                Please enter Action taken !!
                              </div>
                            </div>
                            <div style={{
                              display: 'flex',
                              justifyContent: 'right'
                            }}>
                              <button
                                className="btn btn-primary"
                                onClick={() => onSubmitHandler("Deferred")}
                              >
                                Submit
                              </button>

                              <br></br>
                            </div>
                          </div>
                          
                          <div className="val1">
                            Please click thumbs up if you are agreeing to the
                            above notification. Please click thumbs down if you
                            are not agreeing to the above notification. Please
                            fill the reason for not agreeing
                          </div>
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
          </div>
          </div>
          }
        </main>
      </ManagementSection>
    </UsersSection>
  );
}
