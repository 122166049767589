import React, { Component } from "react";
import Columns from "./columns";
import { TableContainer, TableActionsBar } from "./styles";
import ActionsBar from "./actionsBar";
import Rows from "./rows";
import axios from "axios";
import { connect } from "react-redux";
import { selectUsers, } from "../../../modals/userManagement/selectors";
import SearchIcon from "@material-ui/icons/Search";
import {
  fetchUsers,
  fetchUserByAlphabet,
} from "../../../modals/userManagement/thunk";
import userManagement from "..";
class Table extends Component {
  state = { searchInput: '', alphabet: '' };
  onSearchInputChange = (e) => {
    this.setState({ searchInput: e.target.value })
  }
  onAlphabetClick = (e) => {
    console.log("In the click event", e.target.value)
    const { fetchUsers } = this.props;
     //   fetchUsers();
    fetchUsers(e.target.value)
    this.setState({ alphabet: e.target.value })
  }
  prepareAlphabets = () => {
    let result = [];
    for (let i = 65; i < 91; i++) {
      result.push(
        <button type="button" key={i} onClick={this.onAlphabetClick} value={String.fromCharCode(i)}style={{ color: "white", backgroundColor: "#111728", marginRight: "10px" }} >{String.fromCharCode(i)}</button>
      )
    }
    return result;
  }
  elementContainsSearchString = (searchInput, element) => (searchInput ? element.name.toLowerCase().includes(searchInput.toLowerCase()) : false);
  filterItems = (itemList) => {
    let result = [];
    const { searchInput, alphabet } = this.state;
    if (itemList && (searchInput || alphabet)) {
      result = itemList.filter((element) => (element.name.charAt(0).toLowerCase() === alphabet.toLowerCase()) ||
        this.elementContainsSearchString(searchInput, element));
    } else {
      result = itemList || [];
    }
    result = result.map((item) => (<li>{item.name}</li>))
    return result;
  }
   render() {

    const {
      rowData,
      columnsElements,
      action,
      editUser,
      panelType,
      requiredElements,
      deleteUser,
      fetchUserByAlphabet,
      confirmDelete,
      setSearchFilter,
    } = this.props;
    const filteredList = this.filterItems(rowData);
    return (

      <TableContainer>

        <TableActionsBar>
          {/* <ActionsBar clickAction={action} panelType={panelType} setSearchFilter={setSearchFilter}/> */}
          <ActionsBar clickAction={action} panelType={panelType} />
          <div style={{ marginLeft: "30px" }}>
            {/* <input type="search" onChange={this.onSearchInputChange} /> */}
            <table>
              <tr>
                <td>
                  <SearchIcon style={{ marginBottom: "-5px", marginRight: "10px" }} />
                </td>
                <td>
                  {this.prepareAlphabets()}
                </td>
                {/* <td>
                  <span> <SearchIcon onClick={(e) => this.onSearchInputChange(e)} style={{ marginBottom: "-5px", marginRight: "10px" }} /></span>
                  <span>
                    <input type="search" placeholder="Search by Name" onChange={(e) => this.handleChange(e)} style={{ background: "#111728", color: "white" }} /></span>
                </td> */}
              </tr>
            </table>

          </div>
        </TableActionsBar>
        <Columns columnsElements={columnsElements} />
        <Rows
          rowData={rowData}
          confirmDelete={confirmDelete}
          deleteUser={deleteUser}
          requiredElements={requiredElements}
          columnsElements={columnsElements}
          editUser={editUser}
          setSearchFilter={setSearchFilter}
          fetchUserByAlphabet={fetchUserByAlphabet}
        />
      </TableContainer>
    );
  }
}
const mapStateToProps = (state) => ({
  users: selectUsers(state),

});
const mapDispatchToProps = {
  fetchUsers: fetchUsers,
};
export default connect(mapStateToProps, mapDispatchToProps)(Table);
