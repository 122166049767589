import React, { useEffect, useState } from "react";
import { useStyles } from "../styles/layoutStyles";
import cameraImg from "../../assets/dummy.png";
import NotificationsIcon from "@material-ui/icons/Notifications";
import "./preview-zone.css";
import { loadDashboardData } from "../../redux/actions/dashboardAction";
import { connect } from "react-redux";
import Spinner from "../common/Spinner";
import Axios from "axios";
import { Link } from "react-router-dom";
import { ViewZoneContainer } from "./styles";
import { ArrowBackIos } from "@material-ui/icons";

export function PreviewZone({
  zone_id,
  zoneData,
  history,
}) {
  const [zone, setZone] = useState(null);
  const [notification, setNotification] = useState(0);
  const [localvalue, setLocalvalue] = useState("");
  console.log("abhi zoneData", zoneData);
  console.log("abhi zone", zone);

  useEffect(() => {
    if (localStorage.getItem("role_id") == undefined) history.push("/login");
    else setLocalvalue(localStorage.getItem("role_id"));
  }, []);

  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };

  const formatDate = (date) => {
    if (date) {
      return (
        date.getMonth() + 1 + "/" + date.getDate() + "/" + date.getFullYear()
      );
    }
  };

  const formatTime = (date) => {
    if (date) {
      console.log("abhi ", date);
      let hours = date.getHours();
      let minutes = date.getMinutes();
      let ampm = hours >= 12 ? "pm" : "am";
      hours = hours % 12;
      hours = hours ? hours : 12; // the hour '0' should be '12'
      minutes = minutes < 10 ? "0" + minutes : minutes;
      let strTime = hours + ":" + minutes + " " + ampm;
      return strTime;
    }
  };

  useEffect(() => {
    Axios.post(
      `https://qcaefqcyp9.execute-api.ap-south-1.amazonaws.com/prod/fetchzonebyid`,
      {
        id: zone_id,
      }
    )
      .then((res) => {
        console.log("Preview zone ", res.data.data);
        setZone(res.data.data);
      })
      .catch((err) => {});
    //Notification
  }, []);

  console.log("zone ===>", zone);
  const handleGoBack = () => {
    history.goBack();
  }

  return (
    <ViewZoneContainer>
      {zone ? (
        <main className={classes.content}>
          <div className="preview">
            <div className="SMS-LRF-1">
              <ArrowBackIos onClick={() => handleGoBack()} fontSize="large" className="iiq-back-btn"/>
              Cameras - Zone
            </div>
            <div className="preview-main">
              <div className="table1">
                <div className="tr">
                  <div className="name">Zone Name</div>
                  <div className="colon">:</div>
                  <div className="val">
                    {zone[0].zone_name ? zone[0].zone_name : "BRM"}
                  </div>
                </div>
                <div className="tr">
                  <div className="name">Department Name</div>
                  <div className="colon">:</div>
                  <div className="val">
                    {zone[0].zone_name ? zone[0].unit_name : "BRM"}
                  </div>
                </div>
                <div className="tr">
                  <div className="name">Severity</div>
                  <div className="colon">:</div>
                  {zone.severity == "low" ? (
                    <div
                      style={{
                        backgroundColor: "amber",
                        width: "46px",
                        marginRight: "215px",
                        borderRadius: "5px",
                        paddingLeft: "7px",
                      }}
                      className="val"
                    >
                      {zone.severity}
                    </div>
                  ) : zone.severity == "medium" ? (
                    <div
                      className="val"
                      style={{
                        backgroundColor: "yellow",
                        width: "46px",
                        marginRight: "215px",
                        borderRadius: "5px",
                        paddingLeft: "7px",
                      }}
                    >
                      {zone.severity}
                    </div>
                  ) : (
                    <div
                      className="val"
                      style={{
                        backgroundColor: "red",
                        width: "46px",
                        marginRight: "215px",
                        borderRadius: "5px",
                        paddingLeft: "7px",
                      }}
                    >
                      {"high"}
                    </div>
                  )}
                </div>
              </div>
              <div style={{ marginLeft: 290 }}>
               
              </div>
            </div>
           
            <div className="cameras">
              <div id="dashboard1">
                {zone.map((card) => {
                  return (
                    <div className="card1">
                      <div className="card1-title">
                          <Link
                            to={"/preview-camera/" + card.camera_id}
                          >
                            <div className="tooltiptext">{card.camera_name}</div>
                          </Link>
                      </div>
                      
                      <Link to={"/preview-camera/" + card.camera_id}>
                        <img
                          width={"100%"}
                          height={"160px"}
                          src={
                            card.resource_url
                              ? card.resource_url
                              : cameraImg
                          }
                          alt=""
                        />
                      </Link>
                      
                      <div className="flex-row" style={{border: '1px solid #222b45'}}>
                        <div className="flex-column notification-count" style={{ fontSize: 14}}>
                            <div>
                              <Link to={"/preview-camera/" + card.camera_id}>
                                <NotificationsIcon />
                                {card.notification_count}
                              </Link>
                            </div>
                        </div>
                        <span className="flex-column" style={{ fontSize: 14, width: '100%', textAlign: 'center' }}>
                          {card.location ? card.location : "SMS"}
                        </span>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            <div></div>
          </div>
        </main>
      ) : (
        <Spinner />
      )}
    </ViewZoneContainer>
  );
}

export function getZoneById(dashboard, zone_id) {
  console.log("abhi getZoneById", zone_id, dashboard[0].zone_id);
  let test = dashboard.find((zone) => zone.zone_id == zone_id) || null;
  console.log("abhi test", test);
  return test;
}

// export default PreviewZone;
function mapStateToProps(state, ownProps) {
  const zone_id = ownProps.match.params.zone_id;
  console.log("abhi zone_id ", zone_id);
  const zoneData =
    zone_id && state.dashboard.length > 0
      ? getZoneById(state.dashboard, zone_id)
      : [];
  console.log("abhi zoneData", zoneData);

  return {
    zone_id: zone_id,
    zoneData,
    dashboard: state.dashboard,
    apiCallsInProgress: state.apiCallsInProgress,
  };
}

const mapDispatchToProps = {
  loadDashboardData,
};

export default connect(mapStateToProps, mapDispatchToProps)(PreviewZone);
