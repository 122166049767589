import React, { Component } from "react";
import { ColumnContainer, Cell, ColumnName, ColumnActions } from "./styles";

class Column extends Component {
  render() {
    const {
      columnsElements = [
        "Name",
        "IP Address",
        "Location",
        "Unit",
        "Zone",
        "Camera Title",
      ],
      filterFunction,
    } = this.props;
    return (
      <ColumnContainer>
        {columnsElements.map((element, i) => {
          return (
            <Cell size={i === 6 ? 16 : 16}>
              <ColumnName>{element}</ColumnName>
              <ColumnActions></ColumnActions>
            </Cell>
          );
        })}
      </ColumnContainer>
    );
  }
}

export default Column;
