import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { Container } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import {Select } from "antd";
import ExcelReader from "../../file-upload/excel-reader";

const { Option } = Select;
const AddBulkUser = ({
  preLoadedValues,
}) => {
  const [gender, setGender] = useState("male");
  const [designations, setDesignations] = useState([]);
  const [units, setUnits] = useState([]);
  const [roles, setRoles] = useState([]);
  const [selectedUnitId, setSelectedUnit] = useState(null);
  const [selectedRoleId, setSelectedRole] = useState(null);
  const [selectedDesignationId, setSelectedDesignation] = useState(null);
  const [customError, setCustomError] = useState(null);

  const { handleSubmit, register, errors, reset } = useForm({
    defaultValues: preLoadedValues || {},
  });

  const onSubmit = async (values) => {
    console.log(
      "clicked data submission ===>",
      values,
      selectedUnitId,
      selectedRoleId,
      selectedDesignationId
    );
    const name = values.name;
    const role_id = selectedRoleId;
    const emp_id = values.emp_id;
    const phone_number = values.phone_number;
    const email_id = values.email_id;
    const designation_id = selectedDesignationId;
    const unit_id = selectedUnitId;
    const shift_id = 133;

    const user = {
      name,
      role_id,
      emp_id,
      phone_number,
      gender,
      email_id,
      designation_id,
      unit_id,
      shift_id,
    };
  };

  const handleUnitChange = (value) => {
    setSelectedUnit(value);
    if (selectedUnitId && selectedDesignationId && selectedRoleId)
      setCustomError(null);
  };

  const handleDesignationChange = (value) => {
    setSelectedDesignation(value);
    if (selectedUnitId && selectedDesignationId && selectedRoleId)
      setCustomError(null);
  };

  const handleRoleChange = (value) => {
    setSelectedRole(value);
    if (selectedUnitId && selectedDesignationId && selectedRoleId)
      setCustomError(null);
  };
  const useStyles = makeStyles((theme) => ({
    button: {
      display: "block",
      marginTop: theme.spacing(2),
    },
    formControl: {
      margin: theme.spacing(1),
      maxWidth: 200,
    },
  }));
  useEffect(() => {
    fetchComponentData();
  }, []);

  const fetchComponentData = async () => {
    console.log("fetched from form ==>", units, designations, roles);
  };

  return (
    <Container>
      <form onSubmit={handleSubmit(onSubmit)}>
        <table>
          <tr>
            <td>
              <label>
                Add or edit users by uploading a CSV file with thier info such
                as passwords and organisation units.
              </label>
            </td>
          </tr>
          <tr>
            <td>
              <label>
                <strong>Note : </strong>Required fields are Name, Employee id,
                Phone number, Designation, Role, unit, Gender, EmailId.
              </label>
            </td>
          </tr>
          <tr>
            <td>
              <ExcelReader />
            </td>
          </tr>
          <tr>
            <td>
              <label>Not sure how to get started?</label>
            </td>
          </tr>
          <tr>
            <td>
              <label>Download a csv file, then edit and upload the file</label>
            </td>
          </tr>
          <tr>
            <td>
              <label>
                Download Blank <a href="">CSV Template</a>
              </label>
            </td>
          </tr>
          <tr>
            <td>
              <label>
                Download User's info in a <a href="">CSV file</a>
              </label>
            </td>
          </tr>
        </table>
        
      </form>
    </Container>
  );
};

export default AddBulkUser;
