export const BEGIN_API_CALL = "BEGIN_API_CALL";
export const API_CALL_ERROR = "API_CALL_ERROR";
export const LOAD_PROFILE_SUCCESS = "LOAD_PROFILE_SUCCESS";
export const CREATE_PROFILE_SUCCESS = "CREATE_PROFILE_SUCCESS";
export const SAVE_USER_SUCCESS = "SAVE_USER_SUCCESS";
export const UPDATE_PERSONAL_INFO_SUCCESS = "UPDATE_PERSONAL_INFO_SUCCESS";
export const UPDATE_ACCOUNT_INFO_SUCCESS = "UPDATE_ACCOUNT_INFO_SUCCESS";
export const UPDATE_COMPANY_INFO_SUCCESS = "UPDATE_COMPANY_INFO_SUCCESS";


export const LOAD_DASHBOARD_SUCCESS = "LOAD_DASHBOARD_SUCCESS";
export const UPDATE_RULES_SUCCESS = "UPDATE_RULES_SUCCESS";

export const LOAD_ZONE_SUCCESS = "LOAD_ZONE_SUCCESS";
export const LOAD_CAMERA_SUCCESS = "LOAD_CAMERA_SUCCESS";


