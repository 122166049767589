import * as types from "./types.js";


export const setNotificationGroups = (notificationgroup) => {
  console.log("Set NotificationGroups : ", notificationgroup);
  return {
    type: types.SET_TOTAL_NOTIFICATIONGROUPS,
    notificationgroup,
  }
};

export const NotificationGroupSetNameFilter = (nameFilter) => ({
  type: types.NOTIFICATIONGROUP_SET_NAME_FILTER,
  nameFilter,
});