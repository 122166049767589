import React, { Component } from "react";
import { connect } from "react-redux";
import Table from "../RuleManagement/userTable";
import AddRuleForm from "../RuleManagement/AddRuleForm"
import { ManagementSection, BannerHeading } from "./styles";
import Panel from "../common/panelUser";

import { selectRule} from "../../modals/RuleManagement/selectors";
import { RuleSetNameFilter } from "../../modals/RuleManagement/actions";
import {
    fetchRule,
    deleteRule,
    addRule,
    updateRule,
} from "../../modals/RuleManagement/thunk";
import {
    appFetchAllUnits,
    appFetchAllRoles,
} from "../../modals/app/thunk";


class RuleManagement extends Component {
    state = {
        panelOpenType: "",
        activeUser: null,
    };
    componentDidCatch(error, errorInfo) {
        console.log(error, errorInfo, "==>");
    }

    componentDidMount() {
        const { fetchRule } = this.props;
        fetchRule();
    }

    toggleUserPanel = (panelOpenType) => {
        this.setState({ panelOpenType });
    };

    editRule = (rule) => {
        this.setState({ panelOpenType: "edit", activeUser: rule });
    };

    handleDelete = (status) => {
        this.setState({ showconfirmation: status });
    };

    confirmDelete = (rule) => {
        const { deleteRule } = this.props;
        deleteRule(rule);
        this.handleDelete(false);
    };

    render() {
        const { panelOpenType, activeUser } = this.state;
        const {
            addRule,
            deleteRule,
            updateRule,
            fetchUnits,
            fetchRoles,
            rule,
            setSearchFilter,
        } = this.props;
        return (
            <ManagementSection>
                <BannerHeading>Rule Management</BannerHeading>
                <Table
                    action={this.toggleUserPanel}
                    rowData={rule.rule }

                    requiredElements={[
                        "message",
                        "category",
                        "description",
                        "notification",
                        "is_active"

                    ]}
                    columnsElements={[
                        "Name",
                        "Category", 
                        "Description",
                        "Notification",
                   
                        "Active"
                                           
                    ]}
                    panelType={panelOpenType}
                    editRule={this.editRule}
                    deleteRule={this.confirmDelete}
                    setSearchFilter={setSearchFilter}
                    perPage={10}
                />
                <Panel
                    panelType={panelOpenType}
                    isPanelOpen={Boolean(panelOpenType)}
                    Heading={
                        panelOpenType === "edit"
                            ? "Update Rule details"
                            : panelOpenType === "add"
                                ? "Add Rule"
                                : panelOpenType === "searchBy"
                                    ? "Search By"
                                    : null
                    }
                    closeAction={this.toggleUserPanel}
                >

                    {panelOpenType && (
                        <AddRuleForm
                            cancelAction={this.toggleUserPanel}
                            addRule={addRule}
                            deleteRule={deleteRule}
                            fetchUnits={fetchUnits}
                            fetchRoles={fetchRoles}
                            preLoadedValues={activeUser}
                            isEditing={panelOpenType === "edit"}
                            updateRule={updateRule}
                        />
                    )}
                </Panel>
            </ManagementSection>
        );
    }
}

const mapStateToProps = (state) => ({
    rule: selectRule(state),
});

const mapDispatchToProps = {
    addRule: addRule,
    updateRule: updateRule,
    fetchRule: fetchRule,
    deleteRule: deleteRule,
    fetchUnits: appFetchAllUnits,
    fetchRoles: appFetchAllRoles,
    setSearchFilter: RuleSetNameFilter,
};

export default connect(mapStateToProps, mapDispatchToProps)(RuleManagement);
