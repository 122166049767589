import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardContent from "@material-ui/core/CardContent";
import { Typography } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import Camera from "../../assets/photo-camera.png"
const useStyles = makeStyles((theme) => ({
  root: {

  },
  card: {
    backgroundColor: "rgb(17, 23, 40)",
  },
  media: {
    height: 140,
  },
  closeButton: {
    height: "15px",
    position: "absolute",
    right: theme.spacing.unit / 10,
    top: theme.spacing.unit / 10,
    color: "white",
  },
}));

const CameraCard = (props) => {
  const classes = useStyles();
  const { camera, deleteCard, isDefault, setDefaultCam } = props;
  return (
    <Card
      onClick={() => setDefaultCam(camera.camera_id)}
      style={
        isDefault
          ? { border: "1px solid red" }
          : { border: "1px solid #5f5d70" }
      }
      className={classes.card}
    >
      <CardActionArea>
        <CloseIcon
          className={classes.closeButton}
          onClick={() => deleteCard(camera)}
        />
        <CardContent>

          <img src = {Camera} style = {{width:"100%"}}></img>
          <Typography style={{ color: "white", fontSize: 10 }}>
            {camera.name}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};
export default CameraCard;
