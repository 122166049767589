import styled from "styled-components";
import { Drawer } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
export const Container = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  padding: 1rem;
`;

export const CustomInput = styled.input`
  width: 100%;
  background: #0c111d;
  border: none;
  border-bottom: 1px solid #a1adcd;
  color: #fff;
  padding: 0.5rem 0rem;
  font-size: 1rem;
  margin: 0 0 1rem 0;
  outline: none;
  &:focus {
    outline: none;
  }
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  :: placeholder {
    color: #fff;
  }
`;

export const GenderSelection = styled.div`
  display: flex;
  padding: 0.5rem 0rem;
  margin: 0 0 1rem 0;
  border-bottom: 1px solid #a1adcd;
  font-size: 1rem;
  .ant-radio + * {
    color: #fff;
  }
  .ant-radio:hover .ant-radio-inner {
    border-color: #2dd1ac;
    &:hover {
      border-color: #2dd1ac;
    }
  }
  .ant-radio-inner {
    height: 1.5rem;
    width: 1.5rem;
  }
  .ant-radio-inner::after {
    background-color: #2dd1ac;
    top: 4px;
    left: 4px;
    display: table;
    width: 14px;
    height: 14px;
  }
`;

export const GenderLable = styled.p`
  margin: 0 1rem 0 0;
`;

export const SubmitSection = styled.div`
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 3rem;
  display: flex;
`;

export const ActionButtons = styled.button`
  border: 1px solid #979797;
  padding: 0.5rem 1.5rem;
  color: #fff;
  margin: 0 1rem 0 0;
  cursor: pointer;
  background: ${({ isSubmit }) => (isSubmit ? "#1b2848" : "#111728")};
`;

export const ErrorMsg = styled.p`
  margin: 0;
  color: red;
`;

export const AligningWrapper = styled.div`
  display: flex;
  padding: 0.5rem 0rem;
  margin: 0 0 1rem 0;
`;

export const Lable = styled.p`
  margin: 0 0.3rem 0 0;
  font-size: 1.2rem;
  color: #fff;
`;

 
export const MainBoard = styled.div`
max-width: 33%;
min-width: 30rem;
height: 83%;
background: #0d111e;
position: fixed;
bottom: 0;
right: ${({ isScheduling }) => (isScheduling ? "0" : "-30rem")};
opacity: ${({ isScheduling }) => (isScheduling ? 1 : 0)};
color: white;
transition: all 0.3s ease-in-out;
.ant-input[disabled] {
  color: #000;
}
`;

export const CustomDrawer = styled(Drawer)`
.MuiBackdrop-root {
  background-color: lightgreen;
}
`;

export const Header = styled.div`
display: flex;
align-items: center;
justify-content: space-between;
padding: 10px 20px;
background: #0f1726;
`;

export const HeaderText = styled.p`
margin: 0;
font-size: 20px;
`;

export const CustomCloseIcon = styled(CloseIcon)`
cursor: pointer;
`;

export const DetailsSection = styled.div`
padding: 10px 20px;
`;

export const DetailRow = styled.div`
display: flex;
align-items: center;
margin: 25px 0;
`;

export const DetailKey = styled.p`
font-size: 14px;
flex: 1;
margin: 0;
`;

export const DetailValue = styled.div`
font-size: 14px;
flex: 3;
display: flex;
align-items: center;
padding: 0 0 0 30px;
// .ant-picker {
//   padding: 0;
//   color: #fff;
//   cursor: pointer;
//   margin: 0 10px 0 0;
// }
// .ant-picker-input > input {
//   color: #fff;
//   cursor: pointer;
// }
// .ant-input {
//   padding: 3px 3px;
//   border: 1px solid #a1adcd;
//   background-color: #0d111e;
//   color: #fff;
// }
`;
 