import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { Container } from "@material-ui/core";
import {
  CustomInput,
  ActionButtons,
  SubmitSection,
  ErrorMsg,
  AligningWrapper,
} from "./styles";
import { Select } from "antd";

const { Option } = Select;

const AddZoneForm = ({
  cancelAction,
  addZone,
  fetchUnits,
  preLoadedValues,
  isEditing,
  updateZone,
}) => {
  const [units, setUnits] = useState([]);
  const [roles, setRoles] = useState([]);
  const [selectedUnitId, setSelectedUnit] = useState(null);
  const [customError, setCustomError] = useState(null);

  const { handleSubmit, register, errors, reset } = useForm({
    defaultValues: preLoadedValues || {},
  });

  const onSubmit = async (values) => {
    console.log(
      "clicked data submission ===>",
      values,
      selectedUnitId,

    );
    const name = values.name;
    const incharge = values.incharge;
    const phonenumber = values.phonenumber;
    const unit_id = selectedUnitId;

    const zone = {
      name,
      incharge,
      phonenumber,
      unit_id,
    };
    console.log('isEditing :', isEditing)
       isEditing
      ? updateZone({
        ...preLoadedValues,
        ...zone,
      })
      : addZone(zone);
  };

  const handleUnitChange = (value) => {
    setSelectedUnit(value);
    if (selectedUnitId)
      setCustomError(null);
  };

  useEffect(() => {
    fetchComponentData();
  }, []);

  const fetchComponentData = async () => {
    const units = await fetchUnits();
    setUnits(units);
    console.log("fetched from form ==>", units);
  };

  return (
    <Container>
      <form onSubmit={handleSubmit(onSubmit)}>
        <CustomInput
          name="name"
          placeholder={"Zone Name"}
          ref={register({
            required: "Required",
            validate: (value) => value !== "admin" || "Nice try!",
          })}
        />
        <ErrorMsg>{errors.name && errors.name.message}</ErrorMsg>
         <AligningWrapper>
          <Select
            style={{ width: "100%" }}
            placeholder="Select Unit"
            onChange={handleUnitChange}
            defaultValue={
              isEditing
                ? preLoadedValues.unit_id
                : units.legth && units[0].unit_id
            }
          >
            {units.length &&
              units.map(({ unit_id: id, name }) => (
                <Option value={id}>{name}</Option>
              ))}
          </Select>
        </AligningWrapper>
        <CustomInput
          placeholder={"Incharge Name"}
          name="incharge"
          ref={register({
            required: "Required",
            validate: (value) => value.length != 0,
          })}
        />
        <ErrorMsg>{errors.incharge && "Provide Incharge Name"}</ErrorMsg>
        <CustomInput
          placeholder={"Phone Number"}
          maxLength="50"
          name="phonenumber"
          ref={register({
            required: "Required",
            validate: (value) => value.length != 0,
          })}
        />
       <ErrorMsg>{errors.phonenumber && "Provide User Name"}</ErrorMsg>

        <SubmitSection>
          <ActionButtons onClick={() => cancelAction(null)}>
            Cancel
          </ActionButtons>
          <ActionButtons isSubmit type="submit">
            Submit
          </ActionButtons>
        </SubmitSection>
      </form>
    </Container>
  );
};

export default AddZoneForm;
