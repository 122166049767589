import React from "react";
import { connect } from "react-redux";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import Red from '../../assets/red.png';
import Yellow from '../../assets/yellow.png'
import Green from '../../assets/green.png'
import Blue from '../../assets/blue.png'
import "./dashboard.css";
import { loadDashboardData } from "../../redux/actions/dashboardAction";
import {
  DashboardSection,
  ZoneOptions,
  AddZoneWrapper,
  AddButton,
  MapImage,
  MainMapView,
} from "./styles";
import WipScheduler from "./scheduleWIP";
import SearchBar from "../common/searchBar";
import { appSetZonesFilter } from "../../modals/app/actions";
import { goToZoneSelectionPage } from "../../Utils/navigationUtils";
import Map from "../assets/Plant-map.jpg";
import { isEmpty } from "lodash";
import FaceAuthenticationTab from "../FaceAuthenticationTab/FaceAuthenticationTab"
export default function faceAuthDashboardList({
  fetchEquipment,
  fetchZones,
  fetchCameras,
  fetchACameras,
  setSearchFilter,
  zones,
  cameras,
  acameras,
  history,
  createWIP,
  getWipDetails,
}) {
  let interval = 0;
  

 /*  useEffect(() => {
    interval = setInterval(() => {
      fetchACameras();
    }, 5000);
    
  }, []); */

  return (
    <DashboardSection>
      <FaceAuthenticationTab/>
      <h1 style = {{color:"#FFF",marginLeft : "50px"}}> Dashboard List </h1>
     <a href = "/authentication-dashboard">
      <h2 style = {{color:"#FFF",marginLeft : "50px"}}> Main Gate IN Punching </h2> </a>
     
    </DashboardSection>
  );
}