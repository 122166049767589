import styled from "styled-components";

export const TableContainer = styled.div`
  display: flex;
  flex-direction column;
  background: #0f1322;
  padding-bottom: 50px;
`;

export const TableActionsBar = styled.div`
  background: #111728;
`;
