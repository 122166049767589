import React from "react";
import { NavLink } from "react-router-dom";
import "./PlatformSettings.css"

function PlatformSettings(props) {


    return (
        <div>
            <h1
                style={{
                    color: "white",
                    zIndex: "999",
                    position: "relative",
                    margin: "15px",
                }}
            >
                Platform Settings
            </h1>
            <div>
                <NavLink to="/Viewplantform" className="user flex">
                    <div className="title_text">View Plant Details</div>
                </NavLink>
                <NavLink to="/Platform-user-management" className="user flex">
                    <div className="title_text">Platform User Management</div>
                </NavLink>
                <NavLink to="/notificationpause" className="user flex">
                    <div className="title_text">Notification Management</div>
                </NavLink>
                <NavLink to="/add-zone" className="user flex">
                    <div className="title_text">Zone Management</div>
                </NavLink>
                <NavLink to="/ShiftManagement" className="user flex">
                    <div className="title_text">Shift Management</div>
                </NavLink>
            </div>
        </div>
    )
}
export default PlatformSettings;