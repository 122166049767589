import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import Axios from "axios";
import { Checkbox } from "antd";
import { Container } from "@material-ui/core";
import {
  CustomInput,
  ActionButtons,
  SubmitSection,
  ErrorMsg,
  AligningWrapper,
  DetailRow,
  DetailKey,
  DetailValue,
} from "./styles";
import "antd/dist/antd.css";
import { Select, DatePicker} from "antd";
import moment from "moment";
const { Option } = Select;

const AddWipForm = ({
  cancelAction,
  addWip,
  fetchZone,
  fetchUnits,
  preLoadedValues,
  isEditing,
  updateZone,
}) => {
  const [startTime, setStartTime] = useState(new Date());
  const [endTime, setEndTime] = useState(new Date());
  const [units, setUnits] = useState([]);
  const [zones, setZones] = useState([]);
  const [selectedZoneId, setSelectedZone] = useState(null);
  const [selectedUnitId, setSelectedUnit] = useState(null);
  const [sendAlert, setSencAlert] = useState(false);
  const [errorMsg, setErrorMsg] = useState(null);
  const [customError, setCustomError] = useState(null);
  const [isEditingForm, setFormModeEditing] = useState(false);
  const [duration, setDuration] = useState("");
  const { handleSubmit, register, errors, reset } = useForm({
    defaultValues: preLoadedValues || {},
  });
  console.log("preLoadedValues",preLoadedValues);
  const calculateDuration = (startTime, endTime) => {
    if (
      startTime &&
      endTime &&
      new Date(startTime).getTime() < new Date(endTime).getTime()
    ) {
      const totalMinDiff = moment(endTime).diff(startTime, "minutes");
      var hours = Math.floor(totalMinDiff / 60);
      var minutes = totalMinDiff % 60;
      setDuration(`${hours}:${minutes} Hours`);
      setErrorMsg(null);
      return `${hours}:${minutes} Hours`;
    }
    setErrorMsg("Select valid time");
    return "not valid time";
  };

  const dateFormat = "YYYY/MM/DD hh:mm A";

  const onTimeChange = (time, timePeriod) => {
    const string = moment(time).toISOString();
    console.log("from calendar ==>", time, moment(time).toISOString());
    timePeriod === "start"
      ? setStartTime(new Date(string).getTime())
      : setEndTime(new Date(string).getTime());
    const timeDuration =
      timePeriod === "start"
        ? calculateDuration(setStartTime(new Date(string).getTime()), endTime)
        : calculateDuration(startTime, new Date(string).getTime());
    setDuration(timeDuration);
  };
  const onSubmit = async (values) => {

    
    // reset();
    console.log(
      "clicked data submission ===>",
      values,
      selectedUnitId,

    );
    const end_time =  new Date(endTime).getTime();
    const purpose = values.purpose;
    const alert_hod = sendAlert;
    const start_time = new Date(startTime).getTime();
    const zone_id = selectedZoneId;
    const unit_id = selectedUnitId;

    const zone = {
      end_time,
      purpose,
      alert_hod,
      start_time,
      zone_id,
      unit_id,
    };
    console.log('isEditing :', isEditing)


    if (
      !Boolean(startTime) ||
      !Boolean(endTime) ||
      new Date(startTime) >= new Date(endTime)
    ) {
     alert('Please provide valid time');
      return;
    }


    isEditing
      ? updateZone({
        ...preLoadedValues,
        ...zone,
      })
      : addWip(zone);
    cancelAction(null);
  };

  const handleAlert = (value) => {
    setSencAlert(value.target.checked);
  }

  const handleUnitChange = (value) => {
    setSelectedUnit(value);
  }
  const handleZoneChange = (value) => {
    setSelectedZone(value);
    setSelectedUnit('');
    Axios
    .post("https://qcaefqcyp9.execute-api.ap-south-1.amazonaws.com/prod/fetchunitbyzoneid",
    {
     "zone_id" : value
    })
    .then(function(response) {
     setUnits(response.data.data);
     console.log("units",units);
    })
    .catch(function(error) {
      console.log(error);
    });
 // 
     
  };

  useEffect(() => {
    fetchComponentData();
  }, []);

  // need to move to redux thunks after demo
  const fetchComponentData = async () => {
    
    const zones = await fetchZone();
    setZones(zones);
    console.log("fetched from form ==>", zones);
  };

  return (
    <Container>
      <form onSubmit={handleSubmit(onSubmit)}>
       
        
        <AligningWrapper>
          <Select
            name="zone"
            style={{ width: "100%" }}
            placeholder="Select Zone"
            onChange={handleZoneChange}
            
            defaultValue={
              isEditing
                ? preLoadedValues.zone_id
                : zones.legth && zones[0].zone_id
            }
          >
            {zones.length &&
              zones.map(({ zone_id: id, name }) => (
                <Option value={id}>{name}</Option>
              ))}
          </Select>
        </AligningWrapper>
        <AligningWrapper>
          <Select
            style={{ width: "100%" }}
            placeholder="Select Unit"
            value={selectedUnitId}
            name = "unit"
            onChange={handleUnitChange}
            defaultValue={
              isEditing
                ? preLoadedValues.unit_id
                : units.legth && units[0].unit_id
            }
          >
            {units.length &&
              units.map(({ unit_id: id, unit_name }) => (
                <Option value={id}>{unit_name}</Option>
              ))}
          </Select>
        </AligningWrapper>
        
        <DetailRow>
              <DetailKey>Start Time</DetailKey>:
              <DetailValue>
                {isEditingForm ? (
                  moment(startTime).format(dateFormat)
                ) : (
                  <DatePicker
                    name="startTime"
                    onOk={(time) => onTimeChange(time, "start")}
                    showTime={{
                      format: "hh:mm A",
                      defaultValue: moment(startTime),
                    }}
                  />
                )}
              </DetailValue>
            </DetailRow>
            <ErrorMsg>{errors.startTime && "Provide Start Time"}</ErrorMsg>
            <DetailRow>
              <DetailKey>End Time</DetailKey>:
              <DetailValue>
                {isEditingForm ? (
                  moment(endTime).format(dateFormat)
                ) : (
                  <DatePicker
                  name ="endTime"
                    onOk={(time) => onTimeChange(time, "end")}
                    showTime={{
                      format: "hh:mm a",
                      defaultValue: moment(endTime),
                    }}
                  />
                )}
              </DetailValue>
             
            </DetailRow>
            <ErrorMsg>{errors.endTime && "Provide End Time"}</ErrorMsg>
            <DetailRow>
              <DetailKey>Duration</DetailKey>:
              <DetailValue>{`${duration}`}</DetailValue>
            </DetailRow>
       <CustomInput
          name="purpose"
          type="text"
          placeholder={"Purpose"}
          ref={register({
            required: "Required",
            validate: (value) => value.length !== 0,
          })}
        />
        <ErrorMsg>{errors.purpose && "Provide Purpose"}</ErrorMsg>
        <DetailValue>
        <Checkbox style={{marginLeft : "-30px", marginRight : "10px"}} onChange={handleAlert}></Checkbox>
        Alert to the HOD/Supervisor
        </DetailValue>
        <SubmitSection>
          <ActionButtons onClick={() => cancelAction(null)}>
            Cancel
          </ActionButtons>
          <ActionButtons isSubmit type="submit">
            Submit
          </ActionButtons>
        </SubmitSection>
      </form>
    </Container>
  );
};

export default AddWipForm;
