import React, { useState, useEffect } from "react";
import { withRouter } from "react-router";
import "./BarDefaultSMS.css";
import { Modal, Button, Select } from "antd";
import ImgAdd from "../../assets/add.jpg";
import Axios from "axios";
import { connect } from "react-redux";
import { appSelectAvailableTabs } from "../../modals/app/selectors";
import { appFetchAllTabs, appSetTabData } from "../../modals/app/thunk";
import { CloseCircleOutlined } from "@ant-design/icons";
import { ErrorMsg } from "./styles";
import { ToastContainer, toast } from "react-toastify";

const { Option } = Select;

function BarDefaultSMS({ fetchTabs, fetchZones, history }) {
  const [selectedZones, setSelectedZones] = useState([]);
  const [tabName, setTabName] = useState("");
  const [visible, setVisible] = useState(false);
  const [customError, setCustomError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [availableZones, setZones] = useState([]);
  const [tabs, setTabs] = useState([]);
  const [activeTab, setActiveTab] = useState('dashboard');
  const [isShowModal, setIsShowModal] = useState(false);
  const [apiError, setAPIError] = useState(null);

  useEffect(() => {
    if(apiError && isShowModal != true) {
      setIsShowModal(true);
    }
  }, [apiError]);

  useEffect(() => {
    if(isShowModal) {
      Modal.error({
        wrapClassName: 'error-modal',
        title: 'Oops!',
        content: "We're sorry, but something went wrong.",
        onOk() {
          setIsShowModal(false);
        }
      });
    }
  },[isShowModal]);

  const deleteTab = (id) => {
    let URL = `https://qcaefqcyp9.execute-api.ap-south-1.amazonaws.com/prod/deleteusertab`;
    Axios.post(URL, {
      tab_id: id,
    }).then((res) => {
      console.log(res);
      toast.success(`Tab Removed`);
      fetchData();
      history.push("/dashboard");
    },(error) => {
      setAPIError(error);
    });
  };

  const showModal = () => {
    setVisible(true);
    setLoading(false);
  };

  const handleOk = () => {
    setCustomError("");
    setLoading(true);
    if (!tabName) {
      setCustomError("Give A Tab Name");
      setLoading(false);
      return;
    }
    console.log(selectedZones);
    if (selectedZones.length < 1) {
      setCustomError("Please select Zones");
      setLoading(false);
      return;
    }
    let obj = {
      tabname: tabName,
      username: localStorage.getItem("user_name"),
      zone_ids: selectedZones,
    };
    Axios.post(
      "https://qcaefqcyp9.execute-api.ap-south-1.amazonaws.com/prod/createusertab",
      obj,
      {}
    )
      .then((res) => {
        setTabName("");
        setSelectedZones(null);
        toast.success(`${obj.tabname} Tab Added`);
        // fetchTabs();
        fetchData();
      })
      .catch((err) => {
        console.log(err);
        setAPIError(err);
      });
    setTimeout(() => {
      setLoading(false);
      setVisible(false);
    }, 3000);
  };

  const handleChange = (value) => {
    setSelectedZones(value);
  };

  const handleCancel = (e) => {
    setVisible(false);
  };

  const handleTabName = (e) => {
    let value = e.target.value;
    setTabName(value);
  };

  const fetchData = async () => {
    let obj = {
      username: localStorage.getItem("user_name"),
    };
    Axios.post(
      "https://qcaefqcyp9.execute-api.ap-south-1.amazonaws.com/prod/fetchusertab",
      obj,
      {}
    )
      .then((res) => {
        let data = res.data.data;
        setTabs(data);
      })
      .catch((err) => {
        setAPIError(err);
      });
  };

  const changeActiveTab = () => {
    let uriPath = history.location.pathname.split("/");
    if (uriPath.length > 0) {
      setActiveTab(uriPath[uriPath.length-1]);
    }
  }

  useEffect(() => {
    fetchData();
    changeActiveTab();
  }, []);


  const tabClickHandler = (id, zoneId) => {
    console.log("tab_clicked", id);
    localStorage.setItem("TabId", id);
    localStorage.setItem("zone_id", zoneId);
    history.push("/tab/" + id);
    if (id === 0) {
      fetchZones();
      return;
    }
    changeActiveTab();
  };
  useEffect(() => {
    Axios.get(
      "https://qcaefqcyp9.execute-api.ap-south-1.amazonaws.com/prod/fetchzoneall"
    ).then((res) => {
      setZones(res.data.data);
    },(err)=>{
      setAPIError(err);
    });
  }, []);
  //console.log("tabs=>", tabs)
  const role = localStorage.getItem("role_id");

  return (
    <div className="bar-default-sms flex">
      <ToastContainer
        position="top-right"
        autoClose={5000}
        newestOnTop={true}
        hideProgressBar={true}
        closeOnClick
        rtl={false}
        // style={{ paddingLeft: "50px" }}
      />
      <ul className="flex">
        {(() => {
          if (role != 6) {
            return (
              <li>
                <a className={activeTab == "dashboard" ? "active-tab flex" : "active flex"} href="/dashboard">
                  Anomalies
                  {/* <span className="flex">
                    <svg viewBox="0 0 24 24" aria-hidden="true">
                      <path d="M14.59 8L12 10.59 9.41 8 8 9.41 10.59 12 8 14.59 9.41 16 12 13.41 14.59 16 16 14.59 13.41 12 16 9.41 14.59 8zM12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z"></path>
                    </svg>
                  </span> */}
                </a>
              </li>
            );
          }
        })()}

        {/* {(() => {
          if (role != 6) {
            return (
              <li>
                <a className={activeTab == "plant" ? "active-tab flex" : "flex"} href="/plant" style={{ width: "150px" }}>
                  Plant View
                  <span className="flex">
                    <svg viewBox="0 0 24 24" aria-hidden="true">
                      <path d="M14.59 8L12 10.59 9.41 8 8 9.41 10.59 12 8 14.59 9.41 16 12 13.41 14.59 16 16 14.59 13.41 12 16 9.41 14.59 8zM12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8z"></path>
                    </svg>
                  </span>
                </a>
              </li>
            );
          }
        })()} */}
        {tabs
          ? tabs.map((tab) => (
              <li key={tab.tab_id}>
                <a className={activeTab == String(tab.tab_id) ? "active-tab flex" : "active flex"} onClick={() => tabClickHandler(tab.tab_id, tab.zoneid)}>
                  <span>
                    {tab.tabname}
                  </span>
                  {(() => {
                    if (role != 6) {
                      return (
                        <CloseCircleOutlined
                          onClick={() => deleteTab(tab.tab_id)}
                        />
                      );
                    }
                  })()}
                </a>
              </li>
            ))
          : ""}
      </ul>

      {(() => {
        if (role != 6) {
          return (
            <a className="add-btn" onClick={showModal}>
              <img src={ImgAdd} alt="Add" />
            </a>
          );
        }
      })()}

      <Modal
        title="Tab Details"
        visible={visible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel}>
            cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={loading}
            onClick={handleOk}
          >
            Submit
          </Button>,
        ]}
      >
        <div className="tr">
          <div className="name">Tab Name</div>
          <div className="colon">:</div>
          <input
            className="val"
            name="tab_name"
            value={tabName}
            onChange={handleTabName}
          ></input>
        </div>
        <div className="tr">
          <div className="name">Zone Name</div>
          <div className="colon">:</div>
          <div className="val">
            <Select
              mode="tags"
              placeholder="Select Zones"
              value={selectedZones}
              onChange={handleChange}
              style={{ width: "100%" }}
            >
              {availableZones
                ? availableZones.map((zone) => (
                    <Option key={zone.zone_id} value={zone.zone_id}>
                      {" "}
                      {zone.name}
                    </Option>
                  ))
                : ""}
            </Select>
          </div>
        </div>
        <ErrorMsg>{customError}</ErrorMsg>
      </Modal>
    </div>
  );
}

function mapStateToProps(state, ownProps) {
  return {
    stateData: state,
    tabs: appSelectAvailableTabs(state),
  };
}

const mapDispatchToProps = {
  fetchTabs: appFetchAllTabs,
  setData: appSetTabData,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(BarDefaultSMS)
);
