import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import Red from '../../assets/red.png';
import Yellow from '../../assets/yellow.png'
import Green from '../../assets/green.png'
import Blue from '../../assets/blue.png'
import "./dashboard.css";
import DashboardCard from "./card/dashboardCard";
import Spinner from "../common/Spinner";
import BarDefaultSMS from "../BarDefaultSMS/BarDefaultSMS";
import {
  DashboardSection,
  ZoneOptions,
  AddZoneWrapper,
  AddButton,
  MapImage,
  MainMapView,
} from "./styles";
import WipScheduler from "./scheduleWIP";
import {
  appFetchAllEquipments,
  appFetchZonesData,
  appFetchCamerasData,
  appFetchACamerasData,
  zoneScheduleWIP,
  zoneFecthWIPdetails,
} from "../../modals/app/thunk";
import SearchBar from "../common/searchBar";
import {
  appSelectFilteredZones,
  appSelectFilteredCameras,
  appSelectFilteredACameras,
  appSelectNotifications,
} from "../../modals/app/selectors";
import { appSetZonesFilter } from "../../modals/app/actions";
import { goToZoneSelectionPage } from "../../Utils/navigationUtils";
import Map from "../assets/Plant-map.jpg";

export function Dashboard({
  fetchEquipment,
  fetchACameras,
  setSearchFilter,
  cameras,
  acameras,
  history,
  createWIP,
  getWipDetails,
}) {
  const [schedulingZone, setSechudulingZone] = useState({});
  const [equipments, setEquipments] = useState([]);
  const [isMapView, setIsMapView] = useState(false);
  const [localvalue, setLocalvalue] = useState("");
  
  useEffect( () => {
	  if(localStorage.getItem("role_id")  == undefined)
		history.push("/login")
	else
      setLocalvalue(localStorage.getItem("role_id") );
   }, []);

  const fetchREquiredData = async () => {

    fetchACameras();
    console.log('cameras :',cameras);
  };

  const searchFilter = (text) => {
    setSearchFilter(text);
  };

  useEffect(() => {
    fetchREquiredData();
  }, []);

  return (
    <DashboardSection>
      <BarDefaultSMS />
      {!isMapView ? (
        <ZoneOptions>
          <AddZoneWrapper onClick={() => goToZoneSelectionPage(history)}>
            <AddCircleOutlineIcon />
  
          </AddZoneWrapper>
          <SearchBar changeAction={searchFilter} />
          <img src= { Red } width = {15} height= {15}/>
          <h4 style = {{color:'white', marginTop : '-5px',marginLeft : '5px', marginRight : '10px', marginTop : '8px'}}>High</h4>
          <img src= { Yellow } width = {15} height= {15}/>
          <h4 style = {{color:'white', marginTop : '-5px',marginLeft : '5px', marginRight : '10px', marginTop : '8px'}}>Medium</h4>

          <img src= { Green } width = {15} height= {15}/>
          <h4 style = {{color:'white', marginTop : '-5px',marginLeft : '5px', marginRight : '10px', marginTop : '8px'}}>Low</h4>
        </ZoneOptions>
      ) : null}
      {isMapView ? (
        <MainMapView>
          <MapImage src={Map} />
        </MainMapView>
      ) : acameras && acameras.length ? (
        <>
          <div className="cards flex">
            {acameras.map((card) => {
              return (
                <DashboardCard
                  key={card.camera_id}
                  card={card}
                  severity={card.severity}
                  toggleWipScheduler={setSechudulingZone}
                />
              );
            })}
          </div>
          <WipScheduler
            schedulingZone={schedulingZone}
            toggleWipScheduler={setSechudulingZone}
            fetchEquipment={fetchEquipment}
            equipments={equipments}
            createWIP={createWIP}
            getWipDetails={getWipDetails}
          />
        </>
      ) : (
        <Spinner />
      )}
    </DashboardSection>
  );
}

const mapStateToProps = (state, ownProps) => ({
  acameras: appSelectFilteredACameras(state),
  notifications: appSelectNotifications(state),
  apiCallsInProgress: state.apiCallsInProgress,
});

const mapDispatchToProps = {
  fetchACameras: appFetchACamerasData,
  setSearchFilter: appSetZonesFilter,
  createWIP: zoneScheduleWIP,
  getWipDetails: zoneFecthWIPdetails,
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
