const baseUrl = `http://shark-api-v2.herokuapp.com/api`;

export const fetchUsersUrl = `https://qcaefqcyp9.execute-api.ap-south-1.amazonaws.com/prod/fetchusers`;

export const deleteUserUrl = `${baseUrl}/user/delete`;

export const addUserUrl = `https://qcaefqcyp9.execute-api.ap-south-1.amazonaws.com/prod/createuser`;

export const updateUserUrl = `https://qcaefqcyp9.execute-api.ap-south-1.amazonaws.com/prod/updateuser`;

export const fetchUsersbyNameUrl = `https://qcaefqcyp9.execute-api.ap-south-1.amazonaws.com/prod/fetchuserbyname`;