import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useStyles } from "../styles/layoutStyles";
import { Modal } from "antd"
import "./preview.css";
import {
  Card,
  Grid,
  CardContent,
  Button,
  Typography,
} from "@material-ui/core";
import jacket from "../assets/Denim-jacket.png";
import mask from "../assets/Nose-mask.png";
import shoes from "../assets/safety-shoes.png";
import RestrictedArea from "../assets/Restricted-area.png";
import SecludedArea from "../assets/Secluded-area.png";
import styled from "styled-components";
import Axios from "axios";

import dashboardImg from "../../assets/photo-camera.png";



export function Preview({ stateData, history }) {
  const [zone, setZone] = useState();
  const [camera, setCamera] = useState();
  const [rules, setRules] = useState();
  const [visible, setVisible] = useState(false);
  let temp_camera = JSON.parse(localStorage.getItem("cameras"));
  let temp_zone = JSON.parse(localStorage.getItem("zone"));
  let temp_rules = localStorage.getItem("ruleIds");
  let temp_camIds = localStorage.getItem("camera_ids");
  let temp_rule = localStorage.getItem("rules");


  const handleCancel = (e) => {
    setVisible(false);
    localStorage.removeItem("cameras");
    localStorage.removeItem("zone");
    localStorage.removeItem("ruleIds");
    localStorage.removeItem("camera_ids");
    localStorage.removeItem("rules");
    localStorage.removeItem("zone_name");
    localStorage.removeItem("parent_zone_id");
    history.push("/dashboard");
  };
  const onSubmit = () => {
   // setVisible(true);
    console.log("preview ", zone.parent_zone_id, temp_camIds, temp_rule);
  Axios.post('https://qcaefqcyp9.execute-api.ap-south-1.amazonaws.com/prod/zonecamerarulemap',{
    "parent_zone_id" : zone.parent_zone_id,
    "camera_id": temp_camIds,
    "rule_id": temp_rule
  }).then((resp)=>
  {
    console.log(resp)
  })
  history.push("/ZoneManagement");
  }
  useEffect(() => {
    setZone(temp_zone);
    setCamera(temp_camera);
    setRules(stateData.rules);
    
  }, []);
 
  const classes = useStyles();
  return (
    <div>
          <Modal
            visible={visible}
            onCancel={handleCancel}
            footer={
              [
                <Button key="Cancel" onClick={handleCancel}>
                  close
                </Button>,
              ]
            }>
            <div style={{ width: '100%', color: 'black', fontWeight: "bold" }}>Thank you for your Request to enable Zone-Camera-Rule Mapping <br/>We Would need 24-48 Hours to review and Process this Request <br/> Reach out to us on 'shark@intersectiq.com' any further clarifications </div>
          </Modal>
       { zone &&
    <Wrapper>
      <div className="p-zone">
        <Heading>Zone Information</Heading>
        <div className="preview-main">
          <div className="table">
            <div className="tr">
              <div className="name">Zone Name</div>
              <div className="colon">:</div>
              <div className="val"> {zone.name}</div>
            </div>
            <div className="tr">
              <div className="name">Department</div>
              <div className="colon">:</div>
              <div className="val"> {localStorage.getItem("zone_name")}</div>
            </div>

            <div className="tr">
              <div className="name">Severity</div>
              <div className="colon">:</div>
              <div className="val">{zone.severity}</div>
            </div>
          </div>
        </div>
      </div>
      <div className="p-camera">
        <Heading>Selected Cameras</Heading>
        <div id="dashboard1">
          {camera.map((card) => {
            return (
              <div className="card1">
                <div className="tooltip">
                  <img
                    className="card-img"
                    src={dashboardImg}
                    style={{ width: "150px" }}
                    alt="Camera"
                  />
                  <div className="tooltiptext">{card.camera_name}</div>
                </div>
                <div style={{ textAlign: "left" }}>
                  <span style={{ fontSize: 10 }}>
                    {card.name ? card.name : "SMS"}
                  </span>
                  <div style={{ fontSize: 10 }}>{card.ipaddress}</div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div className="p-rules">
      <Heading>Selected Rules</Heading>
        <Grid className={classes.root} container>
          {rules && 
          rules.map((activity) => {
            return (
              <a>
                              <Card
                  style={{
                    height: "124px",
                    width: "222px",
                    backgroundColor: "#111728",
                    marginRight: "12px",
                    marginBottom: "12px",
                  }}
                >
                  <CardContent>
                    <Grid container>
                      <Grid item xs={6}>
                        {
                          activity.image_url ? (
                            <img
                              src={activity.image_url}
                              alt="logo"
                              style={{ maxHeight: "40px" }}
                            />
                          ) : activity.message === "Nose Mask" ? (
                            <img
                              src={mask}
                              alt="logo"
                              style={{ maxHeight: "40px" }}
                            />
                          ) : activity.message === "Denim Jacket" ? (
                            <img
                              src={jacket}
                              alt="logo"
                              style={{ maxHeight: "40px" }}
                            />
                          ) : activity.message === "Safety Shoes" ? (
                            <img
                              src={shoes}
                              alt="logo"
                              style={{ maxHeight: "40px" }}
                            />
                          ) : activity.message ===
                            "SAFETY ALERT - RESTRICTED AREA" ? (
                            <img
                              src={SecludedArea}
                              alt="logo"
                              style={{ maxHeight: "40px" }}
                            />
                          ) : (
                            <img
                              src={RestrictedArea}
                              alt="logo"
                              style={{ maxHeight: "40px" }}
                            />
                          )
                        }
                      </Grid>
                      <Grid item xs={6}>
                        <Typography style={{ color: "white" }}>
                          {activity.message}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography style={{ color: "white", fontSize: 14 }}>
                          {activity.description}
                        </Typography>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </a>
            );
          })}
        </Grid>
      </div>
      <ButtonOuter>
        <Button onClick={()=>onSubmit()}  style={{ color: "white" }}>
          Submit
        </Button>
     </ButtonOuter>
          
    </Wrapper>
    }
   
    </div>
  );
}

function mapStateToProps(state, ownProps) {
  return {
    stateData: state,
  };
}

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Preview);

const Wrapper = styled.div`
  background: #111728;
  padding: 20px;
  position: relative;
`;

const Content = styled.div`
  ${"" /* margin: 24px 32px; */}
`;

const Heading = styled.h1`
  margin: 0 0 20px;
  color: wheat;
  font-size: 26px;
  font-weight: normal;
  color: white;
`;

const Img = styled.img`
  height: auto;
  width: 300px;
`;

const ButtonOuter = styled.div`
  background: #111728;
  display: flex;
  justify-content: flex-end;
  padding: 10px 0;
  position: absolute;
  top: calc(100% + 15px);
  left: 0;
  width: 100%;
`;
