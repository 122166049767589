import React, { useState, useEffect } from "react";
import { withRouter } from "react-router";
import "./BarDefaultSMS.css";
import { Select } from "antd";
import Axios from "axios";
import { connect } from "react-redux";
import {
  appSelectAvailableTabs,
} from "../../modals/app/selectors";
import { appFetchAllTabs, appSetTabData } from "../../modals/app/thunk";

const { Option } = Select;


function FaceAuthenticationTab({
  setData,
  fetchTabs,
  fetchZones,
  history
}

) {
  const [selectedZones, setSelectedZones] = useState([]);
  const [tabName, setTabName] = useState("");
  const [visible, setVisible] = useState(false);
  const [customError, setCustomError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [availableZones, setZones] = useState([]);
  const [tabs, setTabs] = useState([]);
  const deleteTab = id => {
    let URL = `https://qcaefqcyp9.execute-api.ap-south-1.amazonaws.com/prod/deleteusertab`
    Axios.post(URL, {
        tab_id: id
    })
      .then((res) => {
        console.log(res);
        fetchTabs();
      })
      history.push("/dashboard");
  }
  //console.log("availableZones", availableZones);
  const showModal = () => {
    setVisible(true);
    setLoading(false);
  };

  const handleOk = () => {
    setCustomError("");
    setLoading(true);
    if (!tabName) {
      setCustomError("Give A Tab Name");
      setLoading(false);
      return;
    }
    console.log(selectedZones);
    if (selectedZones.length < 1) {
      setCustomError("Please select Zones");
      setLoading(false);
      return;
    }
    let obj = {
      tabname: tabName,
      username: localStorage.getItem("user_name"),
      zone_ids: selectedZones
    };
    Axios.post("https://qcaefqcyp9.execute-api.ap-south-1.amazonaws.com/prod/createusertab", obj, {

    })
      .then((res) => {
        console.log(res);
        fetchTabs();
      })
      .catch((err) => {
        console.log(err);
      });
    setTimeout(() => {
      setLoading(false);
      setVisible(false);
    }, 3000);
  };

  const handleChange = (value) => {
    setSelectedZones(value);
  };

  const handleCancel = (e) => {
    setVisible(false);
  };

  const handleTabName = (e) => {
    let value = e.target.value;
    setTabName(value);
  };

  const fetchData = async () => {

    let obj = {
      username: localStorage.getItem("user_name")
    };
    Axios.post("https://qcaefqcyp9.execute-api.ap-south-1.amazonaws.com/prod/fetchusertab", obj, {
  
    })
      .then((res) => {
      
        let data = res.data.data;
        setTabs(data);
      
      })
      .catch((err) => { });
    
  };

  useEffect(() => {
      fetchData();

  }, []);

  const tabClickHandler = (id) => {
    console.log("tab_clicked", id);
    localStorage.setItem("TabId",id);
    history.push("/tab/" + id);
    if (id === 0) {
      fetchZones();
      return
    }

  }
  useEffect(() => {
    Axios.get("https://qcaefqcyp9.execute-api.ap-south-1.amazonaws.com/prod/fetchzoneall").then(
      (res) => {
        setZones(res.data.data);
      }
    );
  }, []);
  return (
    <div className="bar-default-sms flex">
      <ul className="flex">

        <li>
          <a className="active flex" href="/mginpunch">
            MG IN Punch 
          </a>
        </li>
        <li>
          <a className="flex" href="/tgoutpunch" style={{ width: "150px" }}>
            TG Out Punch
            
          </a>
        </li>
      </ul>
    </div>
  );
}

function mapStateToProps(state, ownProps) {
  return {
    stateData: state,
    tabs: appSelectAvailableTabs(state),
  };
}

const mapDispatchToProps = {
  fetchTabs: appFetchAllTabs,
  setData: appSetTabData,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(FaceAuthenticationTab)
);
