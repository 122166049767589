import React from "react";

const Termsandcondition = () => {
  return (
    <div className="terms" style={{ color: "white" }}>
      <h5 style={{ textAlign: "center", color: "white" }}>
        Terms of Use of the Shark AI Platform
      </h5>

      <h6 style={{ color: "white" }}>Platform:</h6>
      <p>
        Platform means shark AI platform which is used in website, mobile apps
        and all other components.
      </p>
      <p>
        <strong>
          {" "}
          This document is an electronic record in terms of Information
          Technology Act, 2000 and rules framed thereunder and any other
          applicable statutes under the laws of the Republic of India. This
          electronic record is generated by a computer system and does not
          require any physical or digital signatures.
        </strong>
      </p>

      <p>
        {" "}
        This document shall contain the Terms of Use of the Shark AI Platform.
        Please read this carefully before using our Shark AI Platform, services,
        or products. This policy, read with the Privacy Policy contained in our
        Shark AI Platform shall constitute a legal binding contract between us.
        Please note that the use of our services is subject to your acceptance
        of and complying with the same.{" "}
      </p>

      <p>
        {" "}
        Any use of this Shark AI Platform is conditional on your acceptance of
        these Terms of Use and extends to the other policies stated on our Shark
        AI Platform. If you merely use the Shark AI Platform, you shall be
        subject to the policies that are applicable to the Shark AI Platform for
        such use. In case you do not agree with the whole or any part of the
        Terms of Use or other policies on our Shark AI Platform, kindly exit
        from this Shark AI Platform immediately.
      </p>

      <p>
        {" "}
        The domain names ‘https://shark.iiq.ai’ and ‘www.sharkdigital.ai’ and
        ‘www.intersectiq.com’ (hereinafter referred to as "Shark AI Platform")
        is registered and owned by Intersect Innovations Private Limited (IIQ),
        a company incorporated under the provisions of the Companies Act, 1956
        and having its Registered Office at #704, Surabhi, C-Block, Bannerghatta
        Road, Bilekehalli, Bengaluru, Karnakata 560076 (hereinafter referred to
        as “Owner”). The term Owner hereunder means and includes its successors,
        affiliates, agents, legal representatives or persons claiming under it
        from time to time, where applicable.
      </p>

      <p>
        The reference to Owner hereunder shall mean reference to any and all of
        the Owner’s affiliates, agents or persons claiming under the owner in
        respect of any rights and liabilities accruing to such persons by access
        or use of this Shark AI Platform or the services thereof. The Owner
        asserts its right to operate and administer the Shark AI Platform and
        reserves all rights pertaining to the Shark AI Platform and the contents
        therein.{" "}
      </p>

      <p>
        {" "}
        Owner, inter alia, is in the business of development of innovative
        products and technologies (“Products”) (hereinafter referred to as
        “Service”) through the Shark AI Platform. IIQ's signature digital
        platform enables enterprises to manage plant operations and safety by
        giving visibility to the operations and detecting anomalies Enables the
        team to be on top of the worker safety and operational efficiency by
        providing a toolkit.
      </p>

      <p>
        {" "}
        For the purpose of these Terms of Use, wherever the context so requires
        ‘you’, ‘your’ or ‘User’ shall mean any natural or legal person who has
        agreed to become a member of the Shark AI Platform by providing
        Registration Data while registering on the Shark AI Platform as
        Registered User. Owner also allows User to surf the Shark AI Platform or
        place orders for Products without registering on the Shark AI Platform.
        The term “we”, “us”, “our” shall mean the Owner.
      </p>

      <p>
        {" "}
        We reserve the right to amend these Terms of Use from time to time
        without notice and at our sole discretion. You agree that it is your
        responsibility to review these stated Terms of Use at regular intervals
        to keep yourself updated on the terms and conditions. We reserve the
        right to amend, modify, discontinue or suspend all or part of this Shark
        AI Platform and may restrict your access to all or part of the Shark AI
        Platform at any time without notice or liability. We grant you a
        personal, non-exclusive, non-transferable, limited privilege to enter
        and use the Shark AI Platform. You agree that nothing on Shark AI
        Platform constitutes, or is meant to constitute, advice of any kind.
      </p>
      <h6 style={{ color: "white" }}>Account:</h6>
      <p>
        If you use the Shark AI Platform, you shall be responsible for
        maintaining the confidentiality of your User ID and Password and you
        shall be responsible for all activities that occur under your User ID
        and Password. You agree that if you provide any information that is
        untrue, inaccurate, not current or incomplete or we have reasonable
        grounds to suspect that such information is untrue, inaccurate, not
        current or incomplete, or not in accordance with the this Terms of Use,
        we shall have the right to indefinitely suspend or terminate or block
        access of your membership on the Shark AI Platform and refuse to provide
        you with access to the Shark AI Platform.{" "}
      </p>

      <p>
        {" "}
        There is no charge or fee for registering, browsing and placing order
        for the Products on the Shark AI Platform. You agree that the Products
        shall be charged as per your offline agreement. However we reserve the
        right to charge fee and change our policies from time to time. In
        particular, we may at our sole discretion introduce new services and
        modify some or all of the existing Services offered on the Shark AI
        Platform. In such an event, we reserve, without notice to you, the right
        to introduce fees for the new services offered or amend/introduce fees
        for existing services, as the case may be. Changes to the fee and
        related policies shall automatically become effective immediately once
        implemented on the Shark AI Platform. Unless otherwise stated, all fees
        shall be quoted in US Dollars or Indian Rupees. You shall be solely
        responsible for compliance of all applicable laws including those in
        India for making payments to us.
      </p>

      <h6 style={{ color: "white" }}>
        Conditions for the Use of our Shark AI Platform:
      </h6>

      <p>
        You agree, undertake and confirm that your use of Shark AI Platform
        shall be strictly governed by the following binding principles:
      </p>
      <ol>
        <li>
          You shall not host, display, upload, modify, publish, transmit, update
          or share any information which:
          <ol type="a">
            <li>
              belongs to another person and to which you do not have any right
              to.
            </li>
            <li>is misleading in any way.</li>
            <li>
              infringes upon or violates any third party's rights [including,
              but not limited to, intellectual property rights, rights of
              privacy (including without limitation unauthorized disclosure of a
              person's name, email address, physical address or phone number) or
              rights of publicity];
            </li>
            <li>
              promotes an illegal or unauthorized copy of another person's
              copyrighted work (see "Copyright complaint" below for instructions
              on how to lodge a complaint about uploaded copyrighted material),
              such as providing pirated computer programs or links to them,
              providing information to circumvent manufacture-installed
              copy-protect devices, or providing pirated music or links to
              pirated music files;
            </li>
            <li>
              contains restricted or password-only access pages, or hidden pages
              or images (those not linked to or from another accessible page);
            </li>
            <li>
              tries to gain unauthorized access or exceeds the scope of
              authorized access to the Shark AI Platform or to profiles, blogs,
              communities, account information, bulletins, friend request, or
              other areas of the Shark AI Platform or solicits passwords or
              personal identifying information for commercial or unlawful
              purposes from other users;
            </li>
            <li>
              engages in commercial activities and/or sales without our prior
              written consent such as contests, sweepstakes, barter, advertising
              and pyramid schemes, or the buying or selling of "virtual" items
              related to the Shark AI Platform. Throughout this Terms of Use,
              Owner’s prior written consent" means a communication coming from
              Owner, specifically in response to your request, and specifically
              addressing the activity or conduct for which you seek
              authorization.
            </li>
            <li>
              interferes with another user's use and enjoyment of the Shark AI
              Platform or any other individual's user and enjoyment of similar
              services;
            </li>
            <li>
              refers to any Shark AI Platform or URL that, in our sole
              discretion, contains material that is inappropriate for the Shark
              AI Platform or any other website, contains content that would be
              prohibited or violates the letter or spirit of these Terms of Use.
            </li>
            <li>harms minors in any way;</li>
            <li>
              infringes any patent, trademark, copyright or other proprietary
              rights or third party’s trade secrets or rights of publicity or
              privacy or shall not be fraudulent or involve the sale of
              counterfeit or stolen items;
            </li>
            <li>violates any law for the time being in force;</li>
            <li>
              deceives or misleads the addressee/ users about the origin of such
              messages or communicates any information which is grossly
              offensive or menacing in nature;
            </li>
            <li>impersonate another person;</li>
            <li>
              contains software viruses or any other computer code, files or
              programs designed to interrupt, destroy or limit the functionality
              of any computer resource; or contains any Trojan horses, worms,
              time bombs, cancelbots, easter eggs or other computer programming
              routines that may damage, detrimentally interfere with, diminish
              value of, surreptitiously intercept or expropriate any system,
              data or personal information;
            </li>
            <li>
              threatens the unity, integrity, defence, security or sovereignty
              of India, friendly relations with foreign states, or public order
              or causes incitement to the commission of any cognizable offence
              or prevents investigation of any offence or is insulting any other
              nation; and/or
            </li>
            <li>
              shall not, directly or indirectly, offer, attempt to offer, trade
              or attempt to trade in any item, the dealing of which is
              prohibited or restricted in any manner under the provisions of any
              applicable law, rule, regulation or guideline for the time being
              in force.
            </li>
          </ol>
        </li>
        <li>
          You shall not use any automatic device, program, algorithm or
          methodology, or any similar or equivalent manual process, to access,
          acquire, copy or monitor any portion of the Shark AI Platform or any
          Content, or in any way reproduce or circumvent the navigational
          structure or presentation of the Shark AI Platform or any Content, to
          obtain or attempt to obtain any materials, documents or information
          through any means not purposely made available through the Shark AI
          Platform. We reserve our right to bar any such activity.
        </li>
        <li>
          You shall not attempt to gain unauthorized access to any portion or
          feature of the Shark AI Platform, or any other systems or networks
          connected to the Shark AI Platform or to any server, computer,
          network, or to any of the services offered on or through the Shark AI
          Platform, by hacking, password “mining” or any other illegitimate
          means.
        </li>
        <li>
          You shall not probe, scan or test the vulnerability of the Shark AI
          Platform or any network connected to the Shark AI Platform nor breach
          the security or authentication measures on the Shark AI Platform or
          any network connected to the Shark AI Platform. You may not reverse
          look-up, trace or seek to trace any information on any other user of
          or visitor to Shark AI Platform, or any other customer, including any
          account on the Shark AI Platform not owned by you, to its source, or
          exploit the Shark AI Platform or any Service or information made
          available or offered by or through the Shark AI Platform, in any way
          where the purpose is to reveal any information, including but not
          limited to personal identification or information, other than your own
          information, as provided for by the Shark AI Platform.
        </li>
        <li>
          You may not use the Shark AI Platform or any content for any purpose
          that is unlawful or prohibited by these Terms of Use, or to solicit
          the performance of any illegal activity or other activity which
          infringes the rights of Owner and / or others.
        </li>
        <li>
          Solely to enable us to use the information you supply us with, so that
          we are not violating any rights you might have in your Information,
          you agree to grant us a non-exclusive, worldwide, perpetual,
          irrevocable, royalty-free, sub-licensable (through multiple tiers)
          right to exercise the copyright, publicity, database rights or any
          other rights you have in your information, in any media now known or
          not currently known, with respect to your Information. We will only
          use your information in accordance with the Terms of Use and Privacy
          Policy applicable to use of the Shark AI Platform.
        </li>
      </ol>
      <h6 style={{ color: "white" }}>Content:</h6>
      <p>
        Except as expressly provided in these Terms of Use, no part of the Shark
        AI Platform and no Content may be copied, reproduced, republished,
        uploaded, posted, publicly displayed, encoded, translated, transmitted
        or distributed in any way (including “mirroring”) to any other computer,
        server, Shark AI Platform or other medium for publication or
        distribution or for any commercial enterprise, without our express prior
        written consent.
      </p>
      <p>
        You shall be responsible for any notes, messages, e-mails, billboard
        postings, photos, drawings, profiles, opinions, ideas, images, videos,
        audio files or other materials or information posted or transmitted to
        the Shark AI Platform (collectively, " Content"). Such Content will
        become our property and you grant us the worldwide, perpetual and
        transferable rights in such Content. We will be entitled to, consistent
        with our Privacy Policy as adopted in accordance with applicable law,
        use the Content or any of its elements for any type of use forever,
        including but not limited to promotional and advertising purposes and in
        any media whether now known or hereafter devised, including the creation
        of derivative works that may include the Content You provide. You agree
        that any Content You post may be used by us, consistent with our Privacy
        Policy and Rules of Conduct on Site as mentioned herein, and you are not
        entitled to any payment or other compensation for such use.
      </p>
      <h6 style={{ color: "white" }}>Privacy:</h6>
      <p>
        Protection of your privacy is our foremost obligation. We store and
        process your Information including any sensitive financial information
        collected (as defined under the Information Technology Act, 2000), if
        any, on computers that may be protected by physical as well as
        reasonable technological security measures and procedures in accordance
        with Information Technology Act 2000 and Rules there under. You can read
        our Privacy Policy at https://shark.iiq.ai/auth/policy. If you object to
        your Information being transferred or used in this way please do not use
        our Shark AI Platform.
      </p>
      <h6 style={{ color: "white" }}>
        Disclaimer of Warranties and Liability:
      </h6>
      <p>
        We take utmost care in ensuring the highest level of accuracy about the
        content made available to you on this Shark AI Platform. However, this
        Shark AI Platform, all Products and Services, included on or otherwise
        made available to you through this Shark AI Platform are provided on “as
        is” and “as available” basis without any representation or warranties,
        express or implied except otherwise specified in writing. Without
        prejudice to the forgoing paragraph, we are unable to accept
        responsibility for interrupted access to the Shark AI Platform due to
        technical problems outside of our control. Due to the nature of the
        Internet, access may be suspended, restricted or interrupted at any
        time, and we are therefore unable to guarantee entirely error-free
        access and services. We do not warrant that:
      </p>

      <ul>
        <li>
          This Shark AI Platform will be constantly available, or available at
          all; or
        </li>
        <li>
          The information on this Shark AI Platform is complete, true, accurate
          or non-misleading.{" "}
        </li>
        <li>
          This Shark AI Platform, information, Content, Products or Services
          included on or otherwise made available to you through the Shark AI
          Platform, its servers or electronic communication sent from us are
          free of viruses or other harmful components.
        </li>
      </ul>
      <p>
        You or the administrator will be required to enter a valid phone number
        while registering on the Shark AI Platform. By registering your phone
        number with us, you consent to be contacted by us via phone calls and /
        or SMS notifications, in case of any functionality related updates. We
        will not use your personal information to initiate any promotional phone
        calls or SMS.
      </p>
      <h6 style={{ color: "white" }}>Intellectual Property Rights:</h6>
      <p>
        All Intellectual Property Rights to and into the Shark AI Platform,
        including but not limited to, any and all rights, title and interest in
        and to copyright, related rights, patents, utility models, trademarks,
        trade names, service marks, designs, know-how, trade secrets and
        inventions (whether patentable or not), goodwill, source code, meta
        tags, databases, text, content, graphics, icons, and hyperlinks shall be
        owned by the Owner, unless otherwise indicated. You acknowledge and
        agree not to copy, reproduce, republish, upload, post, publicly display,
        encode, translate, transmit or distribute in any way to any other
        computer, server, Shark AI Platform or other medium any Content from the
        Shark AI Platform belonging to the Owner without obtaining authorization
        from the Owner.
      </p>
      <h6 style={{ color: "white" }}>Indemnity:</h6>
      <p>
        You shall indemnify and hold harmless Owner, licensee, affiliates,
        subsidiaries, associates and employees, from any claim or demand, or
        actions including reasonable attorneys' fees, made by any third party or
        penalty imposed due to or arising out of your breach of this Terms of
        Use, privacy Policy and other policies, or your violation of any law,
        rules or regulations or the rights of a third party.
      </p>
      <h6 style={{ color: "white" }}>Entire Agreement:</h6>
      <p>
        These Terms of Use along with the other policies including our privacy
        policy, constitute the entire agreement between you and us and supersede
        all previous statements and understandings whatsoever between us (with
        an exception of the offline agreement, which will take precedence). Any
        failure by us to exercise or enforce any right or provision of these
        terms & conditions shall not constitute a waiver of such right or
        provision. If any provision under these terms & conditions is found in
        law to be unenforceable all other provisions of these terms & conditions
        shall remain in full force and effect. We reserve the right to cede,
        assign or otherwise transfer our rights and obligations in terms of
        these standard terms and conditions to a third party.
      </p>
      <h6 style={{ color: "white" }}>Limitation of Liability:</h6>
      <p>
        In no event shall We, or our directors, employees, agents, partners,
        suppliers or third party content providers, be liable under contract,
        tort, strict liability, negligence or any other legal or equitable
        theory with respect to the services (i) for any lost profits, data loss,
        cost of procurement of substitute goods or services, or special,
        indirect, incidental, punitive, compensatory or consequential damages of
        any kind whatsoever, substitute goods or services (however arising)
        and/or (ii) for any bugs, viruses, trojan horses, or the like
        (regardless of the source of origin).
      </p>
      <h6 style={{ color: "white" }}>
        Disclaimer of Warranties and Liability:
      </h6>
      <p>
        We have endeavored to ensure that all the information on the Shark AI
        Platform is correct, but we neither warrant nor make any representations
        regarding the quality, accuracy or completeness of any data,
        information, product or service. In no event are we liable for any
        direct, indirect, punitive, incidental, special, consequential damages
        or any other damages that may result from: (a) the use or the inability
        to use the Services; (b) unauthorized access to or alteration of the
        user's transmissions or data; (c) any other matter relating to the
        Services; including, without limitation, damages for loss of use, data
        or profits, arising out of or in any way connected with the use or
        performance of the Shark AI Platform or Services. The Owner shall not be
        responsible for the delay or inability to use the Shark AI Platform or
        related services, the provision of or failure to provide Services, or
        for any information, software, products, services and related graphics
        obtained through the Shark AI Platform, or otherwise arising out of the
        use of the Shark AI Platform, whether based on contract, tort,
        negligence, strict liability or otherwise. Further, the Owner shall not
        be held responsible for non-availability of the Shark AI Platform during
        periodic maintenance operations or any unplanned suspension of access to
        the Shark AI Platform that may occur due to technical reasons or for any
        reason beyond the Owner’s control. You agree and understand that any
        material and/or data downloaded or otherwise obtained through the Shark
        AI Platform is done entirely at your own discretion and risk and you
        will be solely responsible for any damage to your computer systems or
        loss of data that results from the download of such material and/or
        data.
      </p>
      <h6 style={{ color: "white" }}>Applicable Law:</h6>
      <p>
        This Agreement shall be governed by and interpreted and construed in
        accordance with the laws of India. The courts at Bangalore shall have
        exclusive jurisdiction to try any disputes arising out of the use of
        this Shark AI Platform.
      </p>

      <h6 style={{ color: "white" }}>Our Contact:</h6>

      <p>
        Please send any questions or comments (including all inquiries unrelated
        to copyright infringement) regarding this Shark AI Platform to
        admin@intersectiq.com.
      </p>
    </div>
  );
};

export default Termsandcondition;
