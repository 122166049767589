import React from "react";
import "./Spinner.css";

const Spinner = () => {
  //return <div className="loader">Loading...</div>;
  return(
    <div className="folding">
      <div className="sk-cube1 sk-cube"></div>
      <div className="sk-cube2 sk-cube"></div>
      <div className="sk-cube4 sk-cube"></div>
      <div className="sk-cube3 sk-cube"></div>
    </div>
  );
};

export default Spinner;
