import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { Container } from "@material-ui/core";
import {
  CustomInput,
  ActionButtons,
  SubmitSection,
  ErrorMsg,
  AligningWrapper,
} from "./styles";
import { Select,Checkbox } from "antd";

const { Option } = Select;

const AddUserForm = ({
  cancelAction,
  addUser,
  fetchUnits,
  fetchUserRoles,
  preLoadedValues,
  isEditing,
  updateUser,
}) => {
  const [designations, setDesignations] = useState([]);
  const [units, setUnits] = useState([]);
  const [userroles, setUserRoles] = useState([]);
  const [selectedUnitId, setSelectedUnit] = useState(null);
  const [selectedUserRoleId, setSelectedUserRole] = useState(null);
  const [customError, setCustomError] = useState(null);
  const [appUser,setAppUser]  = useState(false);

  const { handleSubmit, register, errors, reset } = useForm({
    defaultValues: preLoadedValues || {},
  });

  const onSubmit = async (values) => {
    console.log(
      "clicked data submission ===>",
      values,
      selectedUnitId,
      selectedUserRoleId,
    );
    const username = values.username;
    const password = values.password;
    const role_id = selectedUserRoleId;
    const emp_id = values.emp_id;
    const unit_id = selectedUnitId;
    const app_user = appUser;

    const user = {
      username,
	    password,
      role_id,
      emp_id,
      unit_id,
      app_user,
    };
    console.log('isEditing :', isEditing)
       isEditing
      ? updateUser({
        ...preLoadedValues,
        ...user,
      })
      : addUser(user);
  };

  function onAppUserChange(e){
    setAppUser(e.target.checked);
    console.log(`checked = ${e.target.checked}`);
  }
  
  const handleUnitChange = (value) => {
    setSelectedUnit(value);
    if (selectedUnitId  && selectedUserRoleId)
      setCustomError(null);
  };

    const handleRoleChange = (value) => {
    setSelectedUserRole(value);
    if (selectedUnitId && selectedUserRoleId)
      setCustomError(null);
  };

  useEffect(() => {
    fetchComponentData();
  }, []);

  const fetchComponentData = async () => {
    const units = await fetchUnits();
    const userroles = await fetchUserRoles();
   
    setUnits(units);
    setUserRoles(userroles);
    console.log("fetched from form ==>", units, userroles);
  };

  return (
    <Container>
      <form onSubmit={handleSubmit(onSubmit)}>
        <CustomInput
          name="username"
          placeholder={"User Name"}
          ref={register({
            required: "Required",
            validate: (value) => value !== "admin" || "Nice try!",
          })}
        />
        <ErrorMsg>{errors.username && errors.username.message}</ErrorMsg>
        <CustomInput
          type="password"
          placeholder={"Password"}
          name="password"
          ref={register({
            required: "Required",
            validate: (value) => value.length >= 8,
          })}
        />
        <ErrorMsg>{errors.password && "Password must have 8 or more characters!"}</ErrorMsg>
        <CustomInput
          name="emp_id"
          placeholder={"Employee ID"}
          ref={register({
            required: "Required",
            validate: (value) => value.length === 7,
          })}
        />
        <ErrorMsg>{errors.emp_id && "Provide a valid Employee ID"}</ErrorMsg>

         <AligningWrapper>
          <Select
            style={{ width: "100%" }}
            placeholder="Select Unit"
            onChange={handleUnitChange}
            defaultValue={
              isEditing
                ? preLoadedValues.unit_id
                : units.legth && units[0].unit_id
            }
          >
            {units.length &&
              units.map(({ unit_id: id, name }) => (
                <Option value={id}>{name}</Option>
              ))}
          </Select>
        </AligningWrapper>
      
        <AligningWrapper>
          <Select
            style={{ width: "100%" }}
            placeholder="Select Role"
            onChange={handleRoleChange}
            defaultValue={
              isEditing
                ? preLoadedValues.unit_id
                : userroles.legth && userroles[0].role_id
            }
          >
            {userroles.length &&
              userroles.map(({ role_id: id, role_name }) => (
                <Option value={id}>{role_name}</Option>
              ))}
          </Select>
        </AligningWrapper>
        <Checkbox onChange = {onAppUserChange}>
          <span style = {{color:'White'}}>Does User allowed to use DeepInsight APP ?</span> 
        </Checkbox>
        <SubmitSection>
          <ActionButtons onClick={() => cancelAction(null)}>
            Cancel
          </ActionButtons>
          <ActionButtons isSubmit type="submit">
            Submit
          </ActionButtons>
        </SubmitSection>
      </form>
    </Container>
  );
};

export default AddUserForm;
