import React, { useRef, useEffect } from "react";
import { loadModules } from "esri-loader";

function EsriMap({ id }) {
  
  const mapEl = useRef(null);

  
  useEffect(
    () => {
      let view;
      loadModules(["esri/views/MapView", "esri/WebMap"], {
        css: true
      }).then(([MapView, WebMap]) => {
        const webmap = new WebMap({
          portalItem: {
            id
          }
        });
        view = new MapView({
          map: webmap,
          container: mapEl.current
        });
      });
      return () => {
        if (!!view) {
          view.destroy();
          view = null;
        }
      };
    },
    [id]
  );
  return <div style={{ height: "100vh" }} ref={mapEl} />;
}

export default EsriMap;