import * as types from "./types";

export const initialState = {
  zone: [],
};

export default function ZoneManagementReducer(
  state = initialState,
  action
) {
  switch (action.type) {
    case types.SET_TOTAL_ZONES:
      {
        const { zone } = action;
        state = { ...state, zone };
      }
      break;
    case types.ZONE_SET_NAME_FILTER: {
      const { nameFilter } = action;
      state = { ...state, filters: { ...state.filters, nameFilter } };
    }
    // case y:
    //   // code block
    //   break;
    default:
      return state;
  }
  console.log("State Variable :",state);
  return state;
}
