import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import Dialog from "@material-ui/core/Dialog";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Termsandcondition from "./termsandcondition/termsandcondition";
// import Privacypolicy from "./privacypolicy/privacypolicy";
import iiqlogo from "../../assets/iiq-logo.png";
import "./about.css";

class About extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Dialogopen: false,
      DialogopenPrivacy: false,
      value: "",
    };
  }

  setDialog = () => {
    this.setState({
      Dialogopen: true,
    });
  };
  setDialogPrivacy = () => {
    this.setState({
      DialogopenPrivacy: true,
    });
  };

  closeDialogPrivacy = (e) => {
    this.setState({
      DialogopenPrivacy: false,
    });
  };

  handleClose = () => {
    this.setState({
      open: false,
    });
  };

  closeDialog = (e) => {
    this.setState({
      Dialogopen: false,
    });
  };

  render() {
    const isconfirm = true;
    return (
      <div className={`about`}>
        <h1
          style={{
            color: "white",
            zIndex: "999",
            position: "relative",
            margin: "15px",
            fontSize: "30px",
          }}
        >
          About
        </h1>

        <div className="row">
          <div className="col-md-7">
            <div
              className="row"
              style={{
                color: "white",
                padding: "0px 40px",
                margin: "0px 0px",
                fontSize: "17px",
              }}
            >
              <div className="col-md-3">Product name</div>

              <div className="col-md-3">: Shark AI</div>
            </div>
            <div
              className="row"
              style={{
                color: "white",
                padding: "0px 40px",
                fontSize: "17px",
                margin: "0px 0px",
              }}
            >
              <div className="col-md-3">Version</div>

              <div className="col-md-3">: 2.1.0</div>
            </div>

            <div
              className="row"
              style={{
                color: "white",
                padding: "0px 40px",
                fontSize: "17px",
                margin: "0px 0px",
              }}
            >
              <div className="col-md-3">Build</div>

              <div className="col-md-3">: 1</div>
            </div>

            <div
              className="row"
              style={{
                color: "white",
                padding: "10px 40px",
                fontSize: "20px",
                margin: "0px 0px",
              }}
            >
              {/* <div className="col-md-6">
                <span
                  value="privacy"
                  onClick={this.setDialogPrivacy}
                  style={{
                    cursor: "pointer",
                    textDecoration: "underline",
                    fontSize: "17px",
                  }}
                >
                  Privacy Policy
                </span>
              </div> */}

              <div
                className="row"
                style={{
                  color: "white",
                  padding: "10px 0px",
                  fontSize: "20px",
                  margin: "0px 0px",
                  textDecoration: "underline",
                }}
              >
                <div className="col-md-6">
                  <span
                    value="terms"
                    onClick={this.setDialog}
                    style={{ cursor: "pointer", fontSize: "17px" }}
                  >
                    Terms &amp; Condition
                  </span>
                </div>
              </div>

              <div className="row">
                <div className="col-md-6">
                  <h4
                    style={{
                      color: "white",
                      // position: "absolute",
                      // bottom: "5px",
                      padding: "13px 0px",
                      // marginLeft: "68%",
                      fontSize: "14px",
                      //   textDecoration: "inherit",
                      //   color: "inherit",
                    }}
                  >
                    <img src={iiqlogo} style={{ width: "96px" }} />{" "}
                    <span style={{}}>&trade; </span> 2020. All rights reserved.
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Dialog
          className="dialog"
          open={this.state.Dialogopen}
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          PaperProps={{
            style: {
              backgroundColor: "#111728",
              boxShadow: "none",
            },
          }}
          style={{
            color: "green",
          }}
        >
          <DialogTitle
            id="alert-dialog-title"
            style={{ backgroundColor: "#111728" }}
          >
            <span style={{ color: "white" }}>Terms &amp; Condition</span>
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {/* {() => {
                if (isconfirm == true) {
                  <Privacypolicy />;
                } else {
                  <Termsandcondition />;
                }
              }} */}
              {<Termsandcondition />}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.closeDialog} autoFocus>
              <span style={{ color: "white" }}>Ok</span>
            </Button>
          </DialogActions>
        </Dialog>
        {/* 
        <Dialog
          className="dialog"
          open={this.state.DialogopenPrivacy}
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          PaperProps={{
            style: {
              backgroundColor: "#111728",
              boxShadow: "none",
            },
          }}
          style={{
            color: "green",
          }}
        >
          <DialogTitle
            id="alert-dialog-title"
            style={{ backgroundColor: "#111728" }}
          >
            <span style={{ color: "white" }}>Privacypolicy</span>
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {<Privacypolicy />}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.closeDialogPrivacy} autoFocus>
              <span style={{ color: "white" }}>Ok</span>
            </Button>
          </DialogActions>
        </Dialog> */}
      </div>
    );
  }
}

export default About;
