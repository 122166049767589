import React from "react";
import { Link } from "react-router-dom";
import { Menu, Dropdown } from "antd";
import { DownOutlined } from "@ant-design/icons";
import ImgDots from "../../../assets/dots.jpg";
import ImgHealth from "../../../assets/health.jpg";
import ImgNotification from "../../../assets/notification.jpg";
import ImgNotificationOff from "../../../assets/notifications-off.png";
import ImgCamera from "../../../assets/video-camera.png";
import HealthRed from "../../../assets/red-heart.png";
import HealthYellow from "../../../assets/yellow-heart.png";
import { Switch } from "antd"

import Logo from "../../../assets/JSW-logo.png";
import ImgAnomalies from "../../../assets/noanomalies.png";
import List from "./list";

import "./Card.css";
import { CustomWrapper, DropDownElement } from "./styles";
import { Tooltip } from '@material-ui/core';

export default function PlantCard({ card, toggleWipScheduler }) {
  /* const menu = (
    <Menu style={{ backgroundColor: "#0d111e", border: "none" }}>
       <Menu.Item key="0" style={{ backgroundColor: "#0d111e" }}>
        <DropDownElement onClick={() => toggleWipScheduler(card)}>
          Schedule WIP
        </DropDownElement>
      </Menu.Item>
      <Menu.Item key="2" style={{ backgroundColor: "#0d111e" }}>
        <DropDownElement onClick={() => toggleWipScheduler(card)}>
          View Schedule
        </DropDownElement>
      </Menu.Item> 
      <Menu.Item key="1" style={{ backgroundColor: "#0d111e" }}>
        <DropDownElement>Ad-hoc WIP</DropDownElement>
      </Menu.Item>
      <Menu.Item key="1" style={{ backgroundColor: "#0d111e" }}>
        <DropDownElement>Rules</DropDownElement>
      </Menu.Item> 
       <Menu.Item key="3" style={{ backgroundColor: "#0d111e" }}>
        <DropDownElement>Delete Zone</DropDownElement>
      </Menu.Item> 
    </Menu>
  ); */
  return (
    <div className={card.notification_count > 0 ? `card ${card.severity}` : `card`} >
      <Link to={"/preview-camera/" + card.camera_id}>
        {/*  <iframe
          title={card.default_camera_url}
          style={{ WebkitUserSelect: "none", width: "100%" }}
          width="100%"
          height="100%"
          // src={
          //   "http://ubuntu@ec2-54-237-12-172.compute-1.amazonaws.com:8091/video_feed"
          // }
          src={card.resource_url}
        ></iframe> */}
        <div style={{ textAlign: "center", fontSize: "18px" }}>
          {card.camera_rule ? card.camera_rule : "SMS"} </div>

        <img
          title={card.default_camera_url}
          style={{ WebkitUserSelect: "none", width: "100%", height: "200px" }}
          width="100%"


          // src={
          //   "http://ubuntu@ec2-54-237-12-172.compute-1.amazonaws.com:8091/video_feed"
          // }
          src={card.resource_url ? card.resource_url : ImgAnomalies}
        />

      </Link>

      <div className="info">
        <div className="top flex">
          <Link to={"/preview-camera/" + card.camera_id}>
            {card.name ? card.name : "SMS"}

          </Link>

          {/* <List /> */}
          {/* <CustomWrapper>
            <Dropdown
              overlay={menu}
              trigger={["click"]}
              placement="bottomRight"
            >
              <span className="options">
                <img src={ImgDots} alt="Menu" />
              </span>
            </Dropdown>
          </CustomWrapper> */}
        </div>
        <ul className="bottom flex">
         {/*  <li className="flex">
            {card.notification_count > 100 ? <img src={HealthRed} alt="Health" /> : <img src={HealthYellow} alt="Health" />}
          </li> */}
          <li className="flex">
            {!card.pause_notification ? <img src={ImgNotification} alt="Health" /> : <img src={ImgNotificationOff} alt="Health" />}
            <span className="count">
              {card.notification_count ? card.notification_count : 0}
            </span>
          </li>
          <li className="flex">
          {/* <Tooltip title="Integration to be Done" aria-label="Integration to be Done"> */}
              <img src={ImgCamera} alt="Health" />
              {/* </Tooltip> */}
            {/* <span className="count">
              {card.camera_count ? card.camera_count : 0}
            </span> */}
          </li>
        </ul>
      </div>
    </div>
  );
}
