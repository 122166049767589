import { selectZone } from "./selectors";
import { setZones } from "./actions";
import axios from "axios";
import { fetchZoneUrl, deleteZoneUrl, addZoneUrl, updateZoneUrl,fetchplantUrl } from "./api";
import { appShowPushNotification } from "../app/thunk";

export const deleteZone = (zone) => async (dispatch, getState) => {
  console.log(zone);
  const respone = await axios.post(deleteZoneUrl, {
    zone_id: zone.zone_id,
  });
  dispatch(fetchZone());
  console.log("api data ==>", respone);
  dispatch(appShowPushNotification("successfully deleted user"));
};

export const fetchZone = () => async (dispatch, getState) => {
  const { data: zone } = await axios.get(fetchZoneUrl);
  console.log("Zones fetched data ==>", zone.data);
  dispatch(setZones(zone ? zone.data : []));
};

export const addZone = (zone) => async (dispatch, getState) => {
  try {
   // console.log("add Zone data before post ==>", zone);
    dispatch(appShowPushNotification("adding Zone in Zone management"));
    //console.log('Zone from thunk', JSON.stringify(zone));
    const response = await axios.post(addZoneUrl, zone);
    //console.log(response);
    dispatch(fetchZone());
    dispatch(appShowPushNotification("successfully added Zone"));
  } catch (err) {
    dispatch(appShowPushNotification("failed to add Zone, try again"));
    console.error(`failed to add Zone with ${err}`);
  }
};

export const updateZone = (zone) => async (dispatch, getState) => {
  dispatch(appShowPushNotification("updating zone"));
  try {
    console.log("updating Zone ==>", zone);
    const updateResponse = await axios.post(updateZoneUrl, zone);
    dispatch(fetchZone());
    dispatch(appShowPushNotification("successfully updated Zone"));
    console.log("updated response ==>", updateResponse);
  } catch (err) {
    dispatch(appShowPushNotification("failed to update Zone, try again"));
    console.error(`failed to update Zone with ${err}`);
  }
};

export const fetchPlant = () => async (dispatch, getState) => {
  const { data: plant } = await axios.get(fetchplantUrl);
  console.log("Plant fetched data ==>", plant.data);
  dispatch(setZones(plant ? plant.data : []));
};