import React, { Component } from "react";
import { connect } from "react-redux";
import Table from "../Wip/userTable";
import AddWipForm from "../Wip/AddWipForm/index";
import { ManagementSection, BannerHeading } from "./styles";
import Panel from "../common/panelUser";

import { selectZone } from "../../modals/ZoneManagement/selectors";
import { ZoneSetNameFilter } from "../../modals/ZoneManagement/actions";
import {
  fetchZone,
  fetchUnits,
  fetchWipList,
  addWip,
} from "../../modals/Wip/thunk";

class WipManagement extends Component {
  state = {
    panelOpenType: "",
    activeUser: null,
  };
  componentDidCatch(error, errorInfo) {
    console.log(error, errorInfo, "==>");
  }

  componentDidMount() {
    const { fetchWipList } = this.props;
    fetchWipList();
  }

  toggleUserPanel = (panelOpenType) => {
    console.log("panel function inside ==>");
    this.setState({ panelOpenType });
    console.log("Panel Open Type :", panelOpenType);
  };

  editZone = (zone) => {
    this.setState({ panelOpenType: "edit", activeUser: zone });
  };

  handleDelete = (status) => {
    this.setState({ showconfirmation: status });
  };

  confirmDelete = (zone) => {
    const { deleteZone } = this.props;
    deleteZone(zone);
    this.handleDelete(false);
  };

  render() {
    const { panelOpenType, activeUser } = this.state;
    const { addWip, fetchZone, fetchUnits, updateWip, zone, setSearchFilter } =
      this.props;
    return (
      <ManagementSection>
        <BannerHeading>
          <h1 style={{ fontSize: "30px", color: "white" }}>WIP List</h1>
        </BannerHeading>
        <Table
          action={this.toggleUserPanel}
          rowData={zone.zone}
          requiredElements={[
            "zone_name",
            "unit_name",
            "purpose",
            "start_time",
            "end_time",
            "alert_hod",
          ]}
          columnsElements={[
            "Zone Name",
            "Unit Name",
            "Purpose",
            "Start Time",
            "End Time",
            "Alert To HOD",
          ]}
          panelType={panelOpenType}
          editZone={this.editZone}
          deleteZone={this.confirmDelete}
          setSearchFilter={setSearchFilter}
          perPage={10}
        />
        <Panel
          panelType={panelOpenType}
          isPanelOpen={Boolean(panelOpenType)}
          Heading={
            panelOpenType === "edit"
              ? "Update WIP details"
              : panelOpenType === "add"
              ? "Schedule WIP"
              : panelOpenType === "searchBy"
              ? "Search By"
              : null
          }
          closeAction={this.toggleUserPanel}
        >
          {panelOpenType && (
            <AddWipForm
              cancelAction={this.toggleUserPanel}
              addWip={addWip}
              fetchZone={fetchZone}
              fetchUnits={fetchUnits}
              preLoadedValues={activeUser}
              isEditing={panelOpenType === "edit"}
              updateWip={updateWip}
            />
          )}
        </Panel>
      </ManagementSection>
    );
  }
}
const mapStateToProps = (state) => ({
  zone: selectZone(state),
});

const mapDispatchToProps = {
  addWip: addWip,
  fetchUnits: fetchUnits,
  fetchZone: fetchZone,
  fetchWipList: fetchWipList,
};

export default connect(mapStateToProps, mapDispatchToProps)(WipManagement);
