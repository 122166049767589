export const baseUrl = "http://shark-api-v2.herokuapp.com/api";

export const unitsGetAllUnitsListUrl = `https://qcaefqcyp9.execute-api.ap-south-1.amazonaws.com/prod/fetchunitsall`;
export const rolesGetAllUserRolesListUrl = `https://qcaefqcyp9.execute-api.ap-south-1.amazonaws.com/prod/fetchuserroleall`;
export const rolesGetAllRolesListUrl = `https://qcaefqcyp9.execute-api.ap-south-1.amazonaws.com/prod/fetchroleall`;

export const designationsGetAllDesignationsUrl = `https://qcaefqcyp9.execute-api.ap-south-1.amazonaws.com/prod/fetchdesignationall`;

export const equipmentGetAllEquipmentUrl = `${baseUrl}/equipment/fetch/all`;

export const notificationsGetAllUrl = `https://qcaefqcyp9.execute-api.ap-south-1.amazonaws.com/prod/fetchnotification`;
export const dailyNotificationsGetAllUrl = `https://qcaefqcyp9.execute-api.ap-south-1.amazonaws.com/prod/fetchnotificationtodays`;

// http://shark-api-v2.herokuapp.com/api/notification/fetch/todays

export const zonesGetAllZoneUrl = `https://qcaefqcyp9.execute-api.ap-south-1.amazonaws.com/prod/fetchzoneall`;
export const camerasGetAllCameraUrl = `https://qcaefqcyp9.execute-api.ap-south-1.amazonaws.com/prod/fetchcamerasall`;
export const anomaliesGetAllCameraUrl = `https://qcaefqcyp9.execute-api.ap-south-1.amazonaws.com/prod/fetchanomalycameras`;

export const hodGetAllHodUrl = `https://qcaefqcyp9.execute-api.ap-south-1.amazonaws.com/prod/fetchhodsall`;

export const scheduleWipUrl = `https://qcaefqcyp9.execute-api.ap-south-1.amazonaws.com/prod/createwip`;

export const parentZoneGetAllParentZoneUrl = `https://qcaefqcyp9.execute-api.ap-south-1.amazonaws.com/prod/fetchparentzoneall`

export const updateWipUrl = `${baseUrl}/wip/update`;

export const deleteWipUrl = `${baseUrl}/delete`;

export const zoneGetWIPdetailsUrl = `${baseUrl}/wip/fetch/by-zone-id`;

export const zoneUpdateWIPUrl = `${baseUrl}/wip/update`;

export const getShiftsUrl = `${baseUrl}/shift/fetch/all`;

export const tabsGetAllTabsUrl = `https://qcaefqcyp9.execute-api.ap-south-1.amazonaws.com/prod/fetchusertab`;
export const getAllWorkAreasUrl = `https://qcaefqcyp9.execute-api.ap-south-1.amazonaws.com/prod/fetchworkarea`;