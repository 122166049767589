import { selectRule } from "./selectors";
import { setRules } from "./actions";
import axios from "axios";
import { fetchRuleUrl, deleteRuleUrl, addRuleUrl, updateRuleUrl } from "./api";
import { appShowPushNotification } from "../app/thunk";

export const deleteRule = (rule) => async (dispatch, getState) => {
  console.log(rule);
  const respone = await axios.post(deleteRuleUrl, {
    rule_id: rule.rule_id,
  });
  dispatch(fetchRule());
  console.log("api data ==>", respone);
  dispatch(appShowPushNotification("successfully deleted user"));
};

export const fetchRule = () => async (dispatch, getState) => {
  const { data: rule } = await axios.get(fetchRuleUrl);
  console.log("Rules fetched data ==>", rule.data);
  dispatch(setRules(rule ? rule.data : []));
};

export const addRule = (rule) => async (dispatch, getState) => {
  try {
    console.log("add rule data before post ==>", rule);
    dispatch(appShowPushNotification("adding rule in rule management"));
    console.log('Rule from thunk', JSON.stringify(rule));
    const response = await axios.post(addRuleUrl, rule);
    console.log(response);
    dispatch(fetchRule());
    dispatch(appShowPushNotification("successfully added Rule"));
  } catch (err) {
    dispatch(appShowPushNotification("failed to add Rule, try again"));
    console.error(`failed to add Rule with ${err}`);
  }
};

export const updateRule = (rule) => async (dispatch, getState) => {
  dispatch(appShowPushNotification("updating Rule"));
  try {
    console.log("updating Rule ==>", rule);
    const updateResponse = await axios.post(updateRuleUrl, rule);
    dispatch(fetchRule());
    dispatch(appShowPushNotification("successfully updated Rule"));
    console.log("updated response ==>", updateResponse);
  } catch (err) {
    dispatch(appShowPushNotification("failed to update Rule, try again"));
    console.error(`failed to update Rule with ${err}`);
  }
};
