import React, { Component } from "react";
import { connect } from "react-redux";
import Table from "../CameraManagement/userTable";
import AddCameraForm from "../CameraManagement/AddCameraForm";
import { ManagementSection, BannerHeading } from "./styles";
import Panel from "../common/panelUser";

import { selectCamera } from "../../modals/CameraManagement/selectors";
import { CameraSetNameFilter } from "../../modals/CameraManagement/actions";
import {
  fetchCamera,
  deleteCamera,
  addCamera,
  updateCamera,
} from "../../modals/CameraManagement/thunk";
import {
  appFetchAllUnits,
  appFetchParentZonesData,
} from "../../modals/app/thunk";

class CameraManagement extends Component {
  state = {
    panelOpenType: "",
    activeUser: null,
  };
  componentDidCatch(error, errorInfo) {
    console.log(error, errorInfo, "==>");
  }

  componentDidMount() {
    const { fetchCamera } = this.props;
    fetchCamera();
  }

  toggleUserPanel = (panelOpenType) => {
    if (!panelOpenType) this.setUser(null);
    this.setState({ panelOpenType });
  };

  setUser = (user) => {
    this.setState({ activeUser: null });
  };

  editCamera = (camera) => {
    this.setState({ panelOpenType: "edit", activeUser: camera });
  };

  handleDelete = (status) => {
    this.setState({ showconfirmation: status });
  };

  confirmDelete = (camera) => {
    const { deleteCamera } = this.props;
    deleteCamera(camera);
    this.handleDelete(false);
  };

  render() {
    const { panelOpenType, activeUser } = this.state;
    const {
      addCamera,
      deleteCamera,
      updateCamera,
      fetchUnits,
      fetchRoles,
      fetchParentZones,
      camera,
      setSearchFilter,
    } = this.props;
    return (
      <ManagementSection>
        <BannerHeading>Camera Management</BannerHeading>
        <Table
          action={this.toggleUserPanel}
          rowData={camera.camera}
          requiredElements={[
            "name",
            "ipaddress",
            "location",
            "unit_name",
            "zone_name",
            "camera_rule",
          ]}
          columnsElements={[
            "Name",
            "IP Address",
            "Location",
            "Department",
            "Zone",
            "Camera Title",
          ]}
          panelType={panelOpenType}
          editCamera={this.editCamera}
          deleteCamera={this.confirmDelete}
          setSearchFilter={setSearchFilter}
          perPage={10}
        />
        <Panel
          panelType={panelOpenType}
          isPanelOpen={Boolean(panelOpenType)}
          Heading={
            panelOpenType === "edit"
              ? "Update Camera details"
              : panelOpenType === "add"
              ? "Add Camera"
              : panelOpenType === "searchBy"
              ? "Search By"
              : null
          }
          closeAction={this.toggleUserPanel}
        >
          {panelOpenType && (
            <AddCameraForm
              cancelAction={this.toggleUserPanel}
              addCamera={addCamera}
              deleteCamera={deleteCamera}
              fetchUnits={fetchUnits}
              fetchParentZones={fetchParentZones}
              preLoadedValues={activeUser}
              isEditing={panelOpenType === "edit"}
              updateCamera={updateCamera}
            />
          )}
        </Panel>
      </ManagementSection>
    );
  }
}

const mapStateToProps = (state) => ({
  camera: selectCamera(state),
});

const mapDispatchToProps = {
  addCamera: addCamera,
  updateCamera: updateCamera,
  fetchCamera: fetchCamera,
  deleteCamera: deleteCamera,
  fetchUnits: appFetchAllUnits,
  fetchParentZones: appFetchParentZonesData,
  setSearchFilter: CameraSetNameFilter,
};

export default connect(mapStateToProps, mapDispatchToProps)(CameraManagement);
