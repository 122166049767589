import React, { useEffect, useState } from "react";
import moment from "moment";
import Axios from "axios";
import { useStyles } from "../styles/layoutStyles";
import { forwardRef } from "react";
import MaterialTable from "material-table";
import 'bootstrap-daterangepicker/daterangepicker.css';
import Spinner from "../common/Spinner";
import { Switch, Modal } from "antd"
import Button from "@material-ui/core/Button";

import {
  AddBox,
  ArrowDownward,
  Search,
  Check,
  ChevronLeft,
  ChevronRight,
  Clear,
  DeleteOutline,
  Edit,
  FilterList,
  FirstPage,
  LastPage,
  Remove,
  ViewColumn,
  SaveAlt,
} from "@material-ui/icons";
import { SwitchStyle } from "./styles";

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

export default function NotificationReport(history) {
  const url = " ";
  let camera_name = "";
  const classes = useStyles();
  const [details, setDetails] = React.useState([]);
  const [status, setStatus] = React.useState([]);
  const [message, setMessage] = React.useState(null);
  const [localvalue, setLocalvalue] = useState("");
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [filename, setFilename] = useState("");
  const [cameraName, setCameraName] = useState("");
  const [startnotification, setstartNotification] = useState("");
  const [camera_id, setcameraId] = useState("");
  const [pause_notification, setpauseNotification] = useState("");
  const showModal = (data, event) => {
    if (event == true) { setstartNotification("Pause") }
    else {
      setstartNotification("Start")
    }
    setCameraName(data.name);
    setpauseNotification(event);
    setcameraId(data.camera_id);
    setVisible(true);
    setLoading(false);
    console.log(data, event);
  };
  const LoadData = () => {
    Axios.get(
      `https://qcaefqcyp9.execute-api.ap-south-1.amazonaws.com/prod/fetchcamerasall`
    )
      .then((res) => {
        let myData = res.data.data;
        setDetails(myData);
        console.log("pulling data", myData);
        let noteData = myData.map((e) => {
          return {
            camera_id: e.camera_id,

            ipaddress: e.ipaddress,
            camera_rule: e.camera_rule,
            name: e.name,
            location: e.location,
            pause_notification: e.pause_notification,
          };
        });

        setDetails(noteData);
      })
      .catch((err) => { });
  }
  const handleSubmit = () => {
    let obj = {
      camera_id: camera_id,
      pause_notification: pause_notification,
    };

    Axios.post("https://qcaefqcyp9.execute-api.ap-south-1.amazonaws.com/prod/camerapausenotification", obj, {
    })
      .then((res) => {
        console.log(res);
        LoadData();
      })
      .catch((err) => {
        console.log(err);
      });
    setTimeout(() => {
      setLoading(false);
      setVisible(false);
    }, 3000);
  }
  const handleCancel = (e) => {
    setVisible(false);
  };
  useEffect(() => {
    if (localStorage.getItem("role_id") == undefined) history.push("/login");
    else setLocalvalue(localStorage.getItem("role_id"));
    var today = new Date();
    let todaydate = today.getDate() + '-' + day_of_the_month(today.getMonth() + 1) + '-' + today.getFullYear();
    setFilename('Vehicle_Speed_Report_' + todaydate)
  }, []);


  const styles = theme => ({
    root: {
      backgroundColor: theme.palette.secondary.dark,
    },
    tablePagination: {
    },
    tablePaginationCaption: {
      color: 'white'
    },
    tablePaginationSelectIcon: {
      color: 'white'
    },
    tablePaginationSelect: {
      color: 'white'
    },
    tablePaginationActions: {
      color: 'white',
    },
  })

  const handleChange = (event) => {
    setStatus(event.target.value);
  };
  function day_of_the_month(m) {
    return (m < 10 ? '0' : '') + m;
  }

  const handleEvent = (event, picker) => {
    console.log(picker.startDate);
  }

  const handleCallback = (start, end, label) => {
    let from = moment(start).unix() * 1000;
    let to = moment(end).unix() * 1000;
    Axios.post(url, {
      "from": from,
      "to": to
    }).then((res) => {
      let myData = res.data.data;

      console.log("pulling data", myData);
  
      let noteData = myData.map((e) => {
        return {
          camera_id: e.camera_id,

          ipaddress: e.ipaddress,
          camera_rule: e.camera_rule,
          name: e.name,
          location: e.location,
          pause_notification: e.pause_notification,
          status:
            e.status,
        };
      });

      setDetails(noteData);
      console.log("pulling data", myData);
    });
  }
  const messageHandler = (event) => {
    setMessage(event.target.value);
  };
  const showNotificationPause = () => {

  };

  const formatDate = (date) => {
    return "mm-dd-yyyy";
  };
  let interval = 0;
  React.useEffect(() => {

    //interval = setInterval(() => {
    LoadData();
    //}, 9000);
  }, []);

  return (
    <div>
      <h1 style={{ color: "white", zIndex: "999", position: "relative", margin: "15px", fontSize: "2rem", fontWeight: "400" }}>
        Notification Pause
      </h1>
      {details ? (
        <div>

          <div style={{ width: "100%", marginTop: "-50px" }}>
            <MaterialTable
              stickyHeader
              style={{ background: "#0f1322", color: "#fff" }}
              size="small"
              aria-label="a dense table"
              icons={tableIcons}
              columns={[
                { title: "Anomaly Type", field: "camera_rule", width: "20%", headerStyle: { background: "#0f1322", color: "#fff" } },
                { title: "Camera Name", field: "name", width: "20%", headerStyle: { background: "#0f1322", color: "#fff" } },
                { title: "Camera IP", field: "ipaddress", width: "15%", headerStyle: { background: "#0f1322", color: "#fff" } },
                { title: "Camera Location", field: "location", width: "15%", headerStyle: { background: "#0f1322", color: "#fff" } },
                {
                  title: "Notification Pause", field: "pause_notification", width: "30%", headerStyle: { background: "#0f1322", color: "#fff" },
                                  render: (data, id) => (<SwitchStyle> <Switch
                    checked={data.pause_notification}
                    onChange={(e) => showModal(data, e)} />
                  </SwitchStyle>)
                }


              ]}
              data={details}
              options={{
                search: true,
                searchFieldStyle: { maxWidth: "30%", marginLeft: "65%" },
                pageSize: 50,
                pageSizeOptions: [50, 100, 200, 300],
                showTitle: false,
                toolbar: true,
                paging: true,
              }}
            />
          </div>
          <Modal
            // title={cameraName}
            visible={visible}
            onCancel={handleCancel}
            footer={
              [
                <Button key="Cancel" onClick={handleCancel}>
                  close
                </Button>,
                <Button
                  key="submit"
                  type="primary"
                  loading={loading}
                  onClick={handleSubmit}
                >
                  Submit
                </Button>,
              ]
            }>
            <div style={{ width: '100%', color: 'black', fontWeight: "bold" }}>Are you sure you want to {startnotification} notifications for camera {cameraName}</div>
          </Modal>
        </div>
      ) : (
          <Spinner />
        )}

    </div>

  );
}
