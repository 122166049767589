import React, { useState } from 'react';
import AnalyticsBody from './layout/body';
import AnalyticsHeader from './layout/header';

import './style.css'
const Analytics = () => {

    const [zones, setZones] = useState([
        {
            name: "BRM",
            total: 100,
            status: {
                Approved: 10,
                Pending: 70,
                Rejected: 10,
            },
            
        },{
            name: "EOF",
            total: 100,
            status: {
                Approved: 10,
                Pending: 70,
                Rejected: 10,
            }
        },
        {
            name: "BRM - 2",
            total: 100,
            status: {
                Approved: 10,
                Pending: 70,
                Rejected: 10,
            },
            
        },{
            name: "EOF - 3",
            total: 100,
            status: {
                Approved: 10,
                Pending: 70,
                Rejected: 10,
            }
        }
    ]);
    return (
        <div className="iiq-analytics-container">
            <AnalyticsHeader />
            <AnalyticsBody zones={zones}/>
        </div>
    );
}

export default Analytics;