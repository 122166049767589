import { createSelector } from "reselect";
import { compare } from "../../Utils";

export const selectZone = ({ zone }) => {
	console.log("Selector Zone :",zone);
	return	zone;
	}
export const ZoneSelectSearchFilter = ( {

    filters: { nameFilter },

}) => {
  return nameFilter;
};

export const ZoneSelectFilteredList = createSelector(
  [selectZone, ZoneSelectSearchFilter],
  (zone, nameFilter) => {
    if (!zone.length) return zone;
    zone.sort(compare);
    if (!nameFilter) return zone;
    const filteredzone = zone.filter(
      ({ name }) => name.toUpperCase().indexOf(nameFilter.toUpperCase()) > -1
    );
    return filteredzone;
  }
);
