import styled from "styled-components";

export const UsersSection = styled.div`
  height: 100%;
`;

export const UsersContent = styled.div`
  height: 100%;
`;

export const ManagementSection = styled.div`
  background: #0f1322;
  height: 100%;
`;

export const BannerHeading = styled.div`
  font-size: 2rem;
  padding: 1rem 1.5rem;
  color: #fff;
`;

export const CustomInput = styled.input`
  width: 50%;
  background: #0c111d;
  border: none;
  border: 1px solid #a1adcd;
  color: #fff;
  padding:  0rem;
  font-size: 0.8rem;
  margin: 0;
  outline: none;
  &:focus {
    outline: none;
  }
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  :: placeholder {
    color: #fff;
  }
`;
