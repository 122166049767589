import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { Container } from "@material-ui/core";
import {
  CustomInput,
  ActionButtons,
  SubmitSection,
  ErrorMsg,
  AligningWrapper,
  Lable,
} from "./styles";
import { Radio, Select } from "antd";

const { Option } = Select;

const AddShiftForm = ({
  cancelAction,
  addShift,
  deleteShift,
  preLoadedValues,
  isEditing,
  updateShift,
}) => {
 
 // const [selectedCategory, setSelectedCategory] = useState(null);
  const [customError, setCustomError] = useState(null);

  const { handleSubmit, register, errors, reset } = useForm({
    defaultValues: preLoadedValues || {},
  });

  const onSubmit = async (values) => {
    // reset();
    console.log(
      "clicked data submission ===>",
      values,
      );
    const name = values.name;
    const start_time = values.start_time;
    const end_time = values.end_time;
    const shift = {
      name,
      start_time,
      end_time,
    };
    console.log('isEditing :', isEditing)
 
    isEditing
      ? updateShift({
        ...preLoadedValues,
        ...shift,
      })
      : addShift(shift);
  };


  return (
    <Container>
      <form onSubmit={handleSubmit(onSubmit)}>
        <CustomInput
          name="name"
          placeholder={"Shift Name"}
          ref={register({
            required: "Required",
            validate: (value) => value.length != 0,
          })}
        />
       <ErrorMsg>{errors.name && "Enter name"}</ErrorMsg>
        <Lable> Start Time </Lable>
        <CustomInput
          type = "time"
          name="start_time"
          placeholder={"From"}
          ref={register({
            required: "Required",
            validate: (value) => value.length != 0,
          })}
        />
        <ErrorMsg>{errors.start_time && "Select From time"}</ErrorMsg>
        <Lable> End Time </Lable>
        <CustomInput
          type = "time"
          name="end_time"
          placeholder={"To"}
          ref={register({
            required: "Required",
            validate: (value) => value.length != 0,
          })}
        />
        <ErrorMsg>{errors.end_time && "Select To time"}</ErrorMsg>
        <SubmitSection>
          <ActionButtons onClick={() => cancelAction(null)}>
            Cancel
          </ActionButtons>
          <ActionButtons isSubmit type="submit">
            Submit
          </ActionButtons>
        </SubmitSection>
      </form>
    </Container>
  );
};

export default AddShiftForm;
