import React, { Component } from "react";
import { Cell } from "../columns/styles";
import {
  TableRowsContainer,
  TableRow,
  RowActionsWrapper,
  RowActionsAligner,
  Action,
} from "./styles";
import { Switch } from "antd";

class Row extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activePage: 15
    };
  }
  handlePageChange(pageNumber) {
    console.log(`active page is ${pageNumber}`);
    this.setState({ activePage: pageNumber });
  }


  render() {
    const showModal = (rowData, event) => { }
    const {
      rowData,
      requiredElements = [
        "message",
        "category",
        "description",
        "default_notification",
        "is_actvie",       
      ],
      deleteRule,
      editRule,
    } = this.props;
    console.log("Data from ", rowData);
    return (
      <TableRowsContainer>
        { rowData &&
          rowData.map((row) => {
          return (
            <TableRow>
              {requiredElements.map((element, i) => (

                <Cell size={i === 2 ? 25 : 18}>
                 { element === "is_active" ? (
                    <Switch checked="true"
                      onChange={(e) => showModal(rowData, e)} />
                  ) : (
                        `${row[element]}`
                      )}
                  
                </Cell>
              ))}
              <RowActionsWrapper>
                <RowActionsAligner>
                  <Action onClick={() => editRule(row)}>Edit</Action>
                </RowActionsAligner>
              </RowActionsWrapper>
            </TableRow>
          );
        })}

      </TableRowsContainer>
    );
  }
}

export default Row;
