import React from "react";
import { NavLink } from "react-router-dom";
import "./Usermaster.css";
import ImgLink7 from "../../assets/construction-worker.png";
import User from "../../assets/usermaster.png";

function Usermaster(props) {
  return (
    <div>
      <h1
        style={{
          color: "white",
          zIndex: "999",
          position: "relative",
          margin: "15px",
          fontSize: "30px",
        }}
      >
        User Master
      </h1>
      <div>
        <NavLink to="/manage-user" className="user flex1">
          <img
            src={User}
            alt="Link 4"
            style={{ cursor: "pointer", width: "50px" }}
          />
          <div className="title_text">Employee Master</div>
        </NavLink>
        <NavLink to="/manage-work-force" className="user flex1">
          <img
            src={ImgLink7}
            alt="Link 4"
            style={{ cursor: "pointer", width: "50px" }}
          />
          <div className="title_text">Contract Employee Master</div>
        </NavLink>
      </div>
    </div>
  );
}
export default Usermaster;
