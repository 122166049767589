import { createSelector } from "reselect";

export const appSelectNotifications = ({ appData: { notifications } }) => {
  return notifications || [];
};

export const appSelectDailyNotifications = ({
  appData: { dailyNotifications },
}) => {
  return dailyNotifications || [];
};

export const appSelectPushNotification = ({
  appData: {
    pushNotification: { msg },
  },
}) => msg;

export const appSelectHodList = ({ appData: { hodList } }) => hodList;

export const appSelectAvailableTabs = ({ appData: { tabs } }) => {
  return tabs;
};

export const appSelectAvailablezones = ({ appData: { availableZones } }) => {
  return availableZones;
};

export const appSelectFilter = ({ appData: { zonesFilter } }) => {
  return zonesFilter;
};

export const appSelectAvailablecameras = ({ appData: { availableCameras } }) => {
  return availableCameras;
};

export const appSelectAvailableacameras = ({ appData: { availableACameras } }) => {
  return availableACameras;
};

export const appSelectAvailableusers = ({ appData: { availableUsers } }) => {
  return availableUsers;
};


export const appSelectFilteredZones = createSelector(
  [appSelectAvailablezones, appSelectFilter],
  (zones, filter) => {
    if (!filter) return zones;
    const filteredZones = zones.filter(
      ({ name }) => name.toUpperCase().indexOf(filter.toUpperCase()) > -1
    );
    return filteredZones;
  }
);

export const appSelectFilteredCameras = createSelector(
  [appSelectAvailablecameras, appSelectFilter],
  (cameras, filter) => {
    if (!filter) return cameras;
    const filteredCameras = cameras.filter(
      ({ name }) => name.toUpperCase().indexOf(filter.toUpperCase()) > -1
    );
    return filteredCameras;
  }
);


export const appSelectFilteredACameras = createSelector(
  [appSelectAvailableacameras, appSelectFilter],
  (acameras, filter) => {
    if (!filter) return acameras;
    const filteredACameras = acameras.filter(
      ({ name }) => name.toUpperCase().indexOf(filter.toUpperCase()) > -1
    );
    return filteredACameras;
  }
);


export const appSelectFilteredUsers = createSelector(
  [appSelectAvailableusers, appSelectFilter],
  (users, filter) => {
    if (!filter) return users;
    const filteredUsers = users.filter(
      ({ name }) => name.toUpperCase().indexOf(filter.toUpperCase()) > -1
    );
    return filteredUsers;
  }
);


export const appSelectPrevPushNotifTimemout = ({
  appData: {
    pushNotification: { timeOut },
  },
}) => timeOut;
