import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { Container } from "@material-ui/core";
import Axios from 'axios';
import {
  CustomInput,
  ActionButtons,
  SubmitSection,
  ErrorMsg,

} from "./styles";


const AddNotificationGroupForm = ({
  cancelAction,
  addNotificationGroup,
  preLoadedValues,
  isEditing,
  updateNotificationGroup,
}) => {
 
  const [customError, setCustomError] = useState(null);
  const [isAddUser,setIsAddUser]  = useState(false);
  const [users,setUsers] = useState([]);
  const { handleSubmit, register, errors, reset } = useForm({
    defaultValues: preLoadedValues || {},
  });
const onClickAddUser = () =>{
  setIsAddUser(true);
}
  const onSubmit = async (values) => {
    const name = values.name;
    const user = values.user;
    const notificationgroup = {
      name,
      user,
    };
    isEditing
      ? updateNotificationGroup({
        ...preLoadedValues,
        ...notificationgroup,
      })
      : addNotificationGroup(notificationgroup);
  };

   useEffect(() => {
     console.log('PreloadeValues',preLoadedValues)
       if(preLoadedValues != null) 
       {
      console.log("Preloaded Values :" ,preLoadedValues.notification_group_id);
      Axios.post('https://qcaefqcyp9.execute-api.ap-south-1.amazonaws.com/prod/notificationgroupusers',
     {
       "id":preLoadedValues.notification_group_id
     }).then((res) => {
      console.log(res.data.data);
      setUsers(res.data.data);
    })
  };
   }, []);


  return (
    <Container>
      <form onSubmit={handleSubmit(onSubmit)}>
        <CustomInput
          name="name"
          placeholder={"NotificationGroup Name"}
         
          ref={register({
            required: "Required",
           
            
          })}
          disabled = {isEditing ? true : false }
          
        />
        <ErrorMsg>{errors.description && "Enter Valid name"}</ErrorMsg>
       { isEditing ? 
       (<span>
        <span> <CustomInput
          type = "number"
          name = "user"
          placeholder={"User ID"}
          ref={register({
            required: "Required",

          })}
        />
     
        <ErrorMsg>{errors.user && "Add valid user id"}</ErrorMsg> </span>
         { 
         users[0] &&
          users[0].user_id &&
        
          users[0].user_id.map((user,index) =>(
          <p style = {{color:"white"}}>{user}</p>
        )
        ) 
  }
        </span>) : <span></span> }
     

        <SubmitSection>
          <ActionButtons onClick={() => cancelAction(null)}>
            Cancel
          </ActionButtons>
          <ActionButtons isSubmit type="submit">
            Submit
          </ActionButtons>
        </SubmitSection>
      </form>
        {
          isEditing ?
          (
              <span>
               
              </span> 
          ): <span></span>
        }
    </Container>
  );
};

export default AddNotificationGroupForm;
