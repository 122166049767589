import { createSelector } from "reselect";
import { compare } from "../../Utils";

export const selectRule = ({ rule }) => {
	console.log("Selector Rule :",rule);
	return	rule;
	}
export const RuleSelectSearchFilter = ( {

    filters: { nameFilter },

}) => {
  return nameFilter;
};

export const RuleSelectFilteredList = createSelector(
  [selectRule, RuleSelectSearchFilter],
  (rule, nameFilter) => {
    if (!rule.length) return rule;
    rule.sort(compare);
    if (!nameFilter) return rule;
    const filteredrule = rule.filter(
      ({ name }) => name.toUpperCase().indexOf(nameFilter.toUpperCase()) > -1
    );
    return filteredrule;
  }
);
