export const APP_SET_NOTIFICATIONS = "APP_SET_NOTIFICATIONS";
export const APP_SET_DAILY_NOTIFICATIONS = "APP_SET_DAILY_NOTIFICATIONS";
export const APP_SET_PUSH_NOTIFICATION = "APP_SET_PUSH_NOTIFICATION";
export const APP_SET_PUSH_NOTIF_TIMEOUT = "APP_SET_PUSH_NOTIF_TIMEOUT";
export const APP_SET_LOGIN_STATUS = "APP_SET_LOGIN_STATUS";
export const APP_SET_AVAILABLE_ZONES = "APP_SET_AVAILABLE_ZONES";
export const APP_SET_AVAILABLE_PARENT_ZONES = "APP_SET_AVAILABLE_PARENT_ZONES";
export const APP_SET_AVAILABLE_CAMERAS = "APP_SET_AVAILABLE_CAMERAS";
export const APP_SET_AVAILABLE_ACAMERAS = "APP_SET_AVAILABLE_ACAMERAS";
export const APP_SET_AVAILABLE_USERS = "APP_SET_AVAILABLE_USERS";
export const APP_SET_ZONES_FILTER = "APP_SET_ZONES_FILTER";
export const APP_SET_USERS_FILTER = "APP_SET_USERS_FILTER";
export const APP_SET_HOD_LIST = "APP_SET_HOD_LIST";
export const APP_SET_AVAIALABLE_TABS = "APP_SET_AVAIALABLE_TABS";
export const APP_API_ERROR = "APP_API_ERROR";