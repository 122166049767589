import { createSelector } from "reselect";
import { compare } from "../../Utils";

export const selectCamera = ({ camera }) => {
	//console.log("Selector Camera :",camera);
	return	camera;
	}
export const CameraSelectSearchFilter = ( {

    filters: { nameFilter },

}) => {
  return nameFilter;
};

export const CameraSelectFilteredList = createSelector(
  [selectCamera, CameraSelectSearchFilter],
  (camera, nameFilter) => {
    if (!camera.length) return camera;
    camera.sort(compare);
    if (!nameFilter) return camera;
    const filteredcamera = camera.filter(
      ({ name }) => name.toUpperCase().indexOf(nameFilter.toUpperCase()) > -1
    );
    return filteredcamera;
  }
);
