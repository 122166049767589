import React, { useEffect, useState } from "react";
import moment from "moment";
import Axios from "axios";
import { useStyles } from "../../styles/layoutStyles";
import { forwardRef } from "react";
import MaterialTable from "material-table";
import ExportCSV from "./exportToCSV";
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';
import Spinner from "../../common/Spinner";
import {
  AddBox,
  ArrowDownward,
  Search,
  Check,
  ChevronLeft,
  ChevronRight,
  Clear,
  DeleteOutline,
  Edit,
  FilterList,
  FirstPage,
  LastPage,
  Remove,
  ViewColumn,
  SaveAlt,
} from "@material-ui/icons";

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

export default function NotificationReport(history) {
  const url = " https://qcaefqcyp9.execute-api.ap-south-1.amazonaws.com/prod/fetchnoticiationvehicle";
  const classes = useStyles();
  const [details, setDetails] = React.useState([]);
  const [status, setStatus] = React.useState([]);
  const [message, setMessage] = React.useState(null);
  const [localvalue, setLocalvalue] = useState("");
  const [filename, setFilename] = useState("");

  useEffect(() => {

    if (localStorage.getItem("role_id") == undefined) history.push("/login");
    else setLocalvalue(localStorage.getItem("role_id"));
    var today = new Date();
    let todaydate = today.getDate() + '-' + day_of_the_month(today.getMonth() + 1) + '-' + today.getFullYear();
    setFilename('Vehicle_Speed_Report_' + todaydate)
  }, []);


  const styles = theme => ({
    root: {
      backgroundColor: theme.palette.secondary.dark,
    },
    tablePagination: {
    },
    tablePaginationCaption: {
      color: 'white'
    },
    tablePaginationSelectIcon: {
      color: 'white'
    },
    tablePaginationSelect: {
      color: 'white'
    },
    tablePaginationActions: {
      color: 'white',
    },
  })

  const handleChange = (event) => {
    setStatus(event.target.value);
  };
  function day_of_the_month(m) {
    return (m < 10 ? '0' : '') + m;
  }

  const handleEvent = (event, picker) => {
    console.log(picker.startDate);
  }

  const handleCallback = (start, end, label) => {
    let from = moment(start).unix() * 1000;
    let to = moment(end).unix() * 1000;
    Axios.post(url, {
      "from": from,
      "to": to
    }).then((res) => {
      let myData = res.data.data;
      console.log("pulling data", myData);

      let noteData = myData.map((e) => {
        return {
          notification_id: e.notification_id,

          location: e.location,
          zone_name: e.zone_name,
          message: e.message,
          vehicle_number: e.vehicle_number,
          vehicle_speed: e.vehicle_speed,
          img: e.resource_url,
          confidence_ratio: e.confidence_ratio * 100,
          severity: e.severity ? e.severity : "high",
          generated_time: moment(e.generated_time).format(
            "DD/MM/YYYY HH:mm:ss"
          ),
          status:
            e.status,
        };
      });

      setDetails(noteData);
      console.log("pulling data", myData);
    });
  }
  const messageHandler = (event) => {
    setMessage(event.target.value);
  };

  const formatDate = (date) => {
    return "mm-dd-yyyy";
  };
  let interval = 0;
  React.useEffect(() => {
    var today = new Date();
    let date = today.getDate();
    let month = today.getMonth() + 1;
    let year = today.getFullYear();
    let todaydate = year + '-' + month + '-' + date;
    console.log(todaydate);
    let from = moment(todaydate).unix() * 1000;
    let to = from + 86400000;
    console.log(from);

    Axios.post(url, {
      "from": from,
      "to": to
    }).then((res) => {
      let myData = res.data.data;
      setDetails(myData);
      console.log("pulling data", myData);
      
      let noteData = myData.map((e) => {
        return {
          notification_id: e.notification_id,

          location: e.location,
          zone_name: e.zone_name,
          message: e.message,
          vehicle_number: e.vehicle_number,
          vehicle_speed: e.vehicle_speed,
          img: e.resource_url,
          confidence_ratio: e.confidence_ratio * 100,
          severity: e.severity ? e.severity : "high",
          generated_time: moment(e.generated_time).format(
            "DD/MM/YYYY HH:mm:ss"
          ),
          status:
            e.status,
        };
      });

      setDetails(noteData);
    })
      .catch((err) => { });
  }, []);

  return (
    <div>
      <h1 style={{ color: "white", zIndex: "999", position: "relative", margin: "15px", fontSize: "2rem", fontWeight: "400" }}>
        Vehicle Speed Report
      </h1>
      {details ? (
        <div>
        
          <div style={{ marginLeft: "10%", marginTop: "20px", zIndex: "999", position: "relative" }}> <ExportCSV csvData={details} fileName={filename} /> </div>
          <div style={{ marginLeft: "40%", marginTop: "-70px", maxWidth: "300px", zIndex: "999", position: "relative" }}>
            <h5 style={{ color: "white", fontSize: "16px", marginLeft: "30px" }}> Date range</h5>
            <DateRangePicker onEvent={handleEvent} onCallback={handleCallback} size="md" >
              <input type="text" className="form-control" style={{ width: "195px", height: "35px", borderRadius: "5px" }} />
            </DateRangePicker>
          </div>
          {details.length ? (
        <div>
          <div style={{ width: "100%", marginTop: "-50px" }}>
            <MaterialTable
              stickyHeader
              style={{ background: "#0f1322", color: "#fff" }}
              size="small"
              aria-label="a dense table"
              icons={tableIcons}
              columns={[
                { title: "Date", field: "generated_time", width: "15%", headerStyle: { background: "#0f1322", color: "#fff" } },
                { title: "Message", field: "message", headerStyle: { background: "#0f1322", color: "#fff" } },
                { title: "Vehicle Number", field: "vehicle_number", headerStyle: { background: "#0f1322", color: "#fff" } },
                { title: "Speed (km/h)", field: "vehicle_speed", width: "12%", headerStyle: { background: "#0f1322", color: "#fff" } },
                /*  { title: "Severity", field: "severity" ,width:"8%" , headerStyle: { background: "#0f1322", color: "#fff" } }, */
                { title: "Location", field: "location", headerStyle: { background: "#0f1322", color: "#fff" } },
                { title: "Status", field: "status", width: "8%", headerStyle: { background: "#0f1322", color: "#fff" } },
                {
                  title: "Image",
                  field: "img",
                  render: (rowData) => (
                    <img
                      src={
                        rowData.img
                      }
                      style={{ width: "200px", height: "250px" }}
                    />
                  ), headerStyle: { background: "#0f1322", color: "#fff" },
                },
              ]}
              data={details}
              options={{
                search: true,
                searchFieldStyle: { maxWidth: "30%", marginLeft: "65%" },
                pageSize: 50,
                pageSizeOptions: [50, 100, 200, 300],
                showTitle: false,
                toolbar: true,
                paging: true,
              }}
            />
          </div>
          </div>
        ) : <h3 style = {{color:"white", marginLeft:"40%",marginTop : "20%"}}> No Data to Display</h3>}
        </div>
      ) : (
          <Spinner />
        )}
    </div>

  );
}
