import React, { useEffect, useState } from "react";
import moment from "moment";
import Axios from "axios";
import { useStyles } from "../styles/layoutStyles";
import { forwardRef } from "react";
import MaterialTable from "material-table";
import Spinner from "../common/Spinner";
import ExportCSV from "./exportToCSV";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import "./styles.css";
import { useHistory, useParams } from "react-router-dom";
import { Select } from "antd";
import styled from "styled-components";
import {
  AddBox,
  ArrowDownward,
  Search,
  Check,
  ChevronLeft,
  ChevronRight,
  Clear,
  DeleteOutline,
  Edit,
  FilterList,
  FirstPage,
  LastPage,
  Remove,
  ViewColumn,
  SaveAlt,
  Info,
  Cancel,
  CheckCircleOutline,
  Timer
} from "@material-ui/icons";
import Button from '@material-ui/core/Button';

import Table from 'react-bootstrap/Table';
import { Link } from "react-router-dom";
import anomalies from "../dashboard/anomalies";
import Pagination from '@material-ui/lab/Pagination';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import { Modal } from "antd";

const { Option } = Select;
let LIMIT = 1000;
let offSet = 0;
let DELTA_SEARCH = false;
const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

export default function NotificationReport() {
  const url ="https://qcaefqcyp9.execute-api.ap-south-1.amazonaws.com/prod/fetchnotificationbydate";
  const classes = useStyles();
  const [details, setDetails] = React.useState([]);
  const [status, setStatus] = React.useState([]);
  const [rule, setRule] = React.useState([]);
  const [localvalue, setLocalvalue] = useState("");
  const [loading, setLoading] = useState(true);
  const [filename, setFilename] = useState("");
  const [selectedAnamoly, setselectedAnamoly] = useState(0);
  let secCode = "";
  const { id } = useParams();
  const history = useHistory();
  const [st, setStart] = useState(0);
  const [ed, setEnd] = useState(0);
  const [allZone, setAllZone] = useState([]);
  const [selectedZone, setSelectedZone] = useState(null);
  const [isShowSpinner, setIsShowSpinner] = useState(false);
  const [zones, setZones] = useState([]);
  const [cameras, setCameras] = useState([]);
  const [anomalies, setSnomalies] = useState([]);
  const [anomalyStatus, setAnomalyStatus] = useState([]);
  const [alertIds, setAlertIds] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [filters, setFilters] = useState({});
  const [zoomImage, setZoomImage] = useState(null);
  const [selectedValue, setSelectedValue] = useState('Approved');
  const [pageValue, setPageValue] = useState(1);
  const [offSet, setOffSet] = useState(0);
  const [filterCount, setFilterCount] = useState(0);
  const [info, setInfo] = useState("")
  const [reportCount, setReportCount] = useState(0);
  const [isShowModal, setIsShowModal] = useState(false);
  const [apiError, setAPIError] = useState(null);

  useEffect(() => {
    if(apiError && isShowModal != true) {
      setIsShowModal(true);
    }
  }, [apiError]);

  useEffect(() => {
    if(isShowModal) {
      Modal.error({
        wrapClassName: 'error-modal',
        title: 'Oops!',
        content: "We're sorry, but something went wrong.",
        onOk() {
          setIsShowSpinner(false);
          setIsShowModal(false);
        }
      });
    }
  },[isShowModal]);

  useEffect(() => {
    console.log("ID :", id);
    Axios.get(
      `https://qcaefqcyp9.execute-api.ap-south-1.amazonaws.com/prod/fetchreportseccode`
    )
      .then((res) => {
        if (id == res.data.code) {
          console.log(id);
        } else if (localStorage.getItem("role_id") == undefined) {
          history.push("/login");
        } else {
          setLocalvalue(localStorage.getItem("role_id"));
        }
      })
      .catch((err) => {
        setAPIError(err);
      });
    console.log("Secret Code :", secCode);

    var today = new Date();
    let todaydate =
      today.getDate() +
      "-" +
      day_of_the_month(today.getMonth() + 1) +
      "-" +
      today.getFullYear();
    setFilename("Anomalies_Report_" + todaydate);
  }, []);

  useEffect(() => {
    Axios.get(
      `https://qcaefqcyp9.execute-api.ap-south-1.amazonaws.com/prod/fetchzoneall`
    )
      .then((res) => {
        console.log("fetchzoneall" , res.data.data);
        setAllZone(res.data.data)
      })
      .catch((err) => {
        setAPIError(err);
      });
  }, []);

  const getReportCount = (currentStatus) => {
    Axios.post(
      `https://qcaefqcyp9.execute-api.ap-south-1.amazonaws.com/prod/countnotificationbydate`,
      {
        from: st,
        to: ed,
        anomaly: selectedAnamoly,
        zone_id: parseInt(selectedZone),
        status: currentStatus
      }
    )
      .then((res) => {
        if(res && res.data && res.data.data && res.data.data.count) {
          setReportCount(res.data.data.count)
        } else {
          setReportCount(0)
        }        
        
      })
      .catch((err) => {
        setAPIError(err);
      });
  };

  const styles = {
    width: 50,
    display: "block",
    marginBottom: 10,
    format: "DD-MM-YYYY",
  };
  const handleChange = (event) => {
    setStatus(event.target.value);
  };

  function day_of_the_month(m) {
    return (m < 10 ? "0" : "") + m;
  }

  const handleEvent = (event, picker) => {
    console.log(picker.startDate);
  };

  useEffect(() => {
    if(selectedZone)getReportData(selectedValue);
  }, [offSet, selectedValue]);

  const getReportData = (currentStatus) => {
    Axios.post(url, {
      from: st,
      to: ed,
      anomaly: selectedAnamoly,
      zone_id: parseInt(selectedZone),
      status: currentStatus,
      limit: LIMIT,
      offset: offSet
    }).then((res) => {
      let myData = res.data.data;
      console.log("pulling data", myData);

      if(myData) {
        let noteData = myData.map((e) => {
          return {
            notification_id: e.notification_id,
            camera_name: e.camera_name,
            location: e.location,
            zone_name: e.zone_name,
            message: e.message,
            img: e.resource_url,
            confidence_ratio: e.confidence_ratio * 100,
            severity: e.severity ? e.severity : "high",
            accept_reject_comments: e.accept_reject_comments,
            generated_time: moment(e.generated_time).format(
              "DD/MM/YYYY HH:mm:ss a"
            ),
            status: e.status,
          };
        });
        if(DELTA_SEARCH) {
          if(details && details.length > 0) {
            noteData = [...details, ...noteData];
          }
        }
        setDetails(noteData);
        pagination(noteData);
        DELTA_SEARCH = true;
        
      }else {
        setInfo("No Data! Please try with different filter.");
      }
      setIsShowSpinner(false);
      
      console.log("pulling data", myData);
    },(err) => {
      setAPIError(err);
    });
  }
  
  const fetchReport = (statusVal, isSearchBtn) => {
    DELTA_SEARCH = false;
    if(offSet > 0) setOffSet(0);
    if(moment(ed).diff(moment(st), 'days') > 30) {
      setDetails([]);
      pagination([]);
      setInfo("The Date range should be less than 30 days");
    } else if (!statusVal || !selectedZone){  
      setDetails([]);
      pagination([]);
      setInfo("Please select Zone!");
    } else {
      setIsShowSpinner(true);
      const currentStatus = statusVal || selectedValue;
      getReportCount(currentStatus);
      if(isSearchBtn) getReportData(currentStatus);
    }
  }
  const handleCallback = (start, end, label) => {
    let from = moment(start).unix() * 1000;
    let to = moment(end).unix() * 1000;
    setStart(from);
    setEnd(to);
  };

  React.useEffect(() => {
    var today = new Date();
    let date = today.getDate();
    let month = today.getMonth() + 1;
    let year = today.getFullYear();
    let todaydate = year + "-" + month + "-" + date;
    let from = moment(todaydate).unix() * 1000;
    let to = from + 86400000;
    setStart(from);
    setEnd(to);
  }, []);

  const loadData = () => {
    if(details && details.length > 0) {
      // let zones = details.map(item => item.zone_name);
      // setZones(zones.filter((item, index) => zones.indexOf(item) === index));

      let cameras = details.map(item => item.camera_name);
      setCameras(cameras.filter((item, index) => cameras.indexOf(item) === index));

      let message = details.map(item => item.message);
      setSnomalies(message.filter((item, index) => message.indexOf(item) === index));

      let anomalyStatus = details.map(item => item.status);
      setAnomalyStatus(anomalyStatus.filter((item, index) => anomalyStatus.indexOf(item) === index)); 

      let alertIds = details.map(item => ""+item.notification_id);
      setAlertIds(alertIds.filter((item, index) => alertIds.indexOf(item) === index)); 

      applyFilter();
    }  
  }

  useEffect(() => {
    loadData();
  }, [details]);

  const applyFilter = (field, value) => {
    let filterList = {...filters};
    if(field) {
      if(value == "" && (filterList.hasOwnProperty(field))) {
        delete filterList[field];
      } else {
        filterList[field] = value;
      }
      setFilters(filterList);
    }
    let data = [...details];
    for (const [key, value] of Object.entries(filterList)) {
      data = data.filter(item => item[key] == value);
    }
    pagination(data);
  }

  const pagination = (data) => {
    const pageStart = pageValue === 1 ? 0 : pageValue * 10;
    const pageEnd = pageStart + 10;
    setFilterCount(data.length);
    const slicedArray = data.slice(pageStart, pageEnd);
    setFilterData(slicedArray); 
    const info = data ? "No Data! Please try with different filter." : "";
    setInfo(info);
  }

  const clearFilter = () => {
    setFilters({});
    setPageValue(1);
    //loadData();
  }

  const showImage = (isShow, image) => {
    if (isShow) {
      setZoomImage(image);
    } else {
      setZoomImage(null);
    }
  }

  const handleRadioChange = (event) => {
    setSelectedValue(event.target.value);
    fetchReport(event.target.value, false);
    //applyFilter('status', event.target.value);
  };

  useEffect(() => {
    const offSetValue = pageValue * 10;
    if(offSetValue >= parseInt(details.length/10) && details.length < reportCount) {
      const newLimit = reportCount - details.length;
      LIMIT = newLimit >= 1000 ? 1000 : newLimit;
      setOffSet(offSetValue);
    }
    loadData();
  }, [pageValue]);

  const handlePaginationChange = (event, value) => {
    setPageValue(value);
  };

  return (
    
    <div className="iiq-anomalies-report-sec">

        {zoomImage && <img src={zoomImage} className="zoom-image"/>}
        <div className="iiq-anomalies-report">

          <div className="iiq-anomalies-reports-header">
            <div className="flex-row">
              <div className="flex-column iiq-header">
                Anomalies Report
              </div>
              <div className="flex-column date-pick" style={{width: "70%", alignItems: 'flex-end'}}>
                <div className="flex">
                  <span style={{ color: "white", fontSize: "16px", marginLeft: "30px", marginRight: '10px' }}>
                    Date range
                  </span>
                  <DateRangePicker
                    onEvent={handleEvent}
                    onCallback={handleCallback}
                    size="md"
                    style={styles}
                    dateLimit="2"
                  >
                    <input
                      type="text"
                      className="form-control date-pick-comp"
                      style={{ width: "250px", height: "40px" }}
                    />
                  </DateRangePicker>
                  <span style={{ color: "white", fontSize: "16px", marginLeft: "30px", marginRight: '10px' }}>
                    Zone
                  </span>
                  <select className="iiq-select-filter zone-search" placeholder="Select Zone"
                        onChange={(e) => {
                          setSelectedZone(e.target.value);
                        }}
                        value={selectedZone || '' }
                    >
                      <option value="">Select Zone</option>
                      {allZone && allZone.map(item => <option value={item.zone_id}>{item.name}</option>)}
                  </select>
                  <Button onClick={() => fetchReport(selectedValue, true)} className="search-submit">Search</Button>
                  <div style={{marginLeft: "20px"}}>
                    {details && details.length > 0 && (
                      <ExportCSV csvData={details} fileName={filename} />
                    )}
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="flex-row">
              <div className="flex-column" style={{width: '50%'}}>
                  {details && details.length == 0 &&
                    <span className="no-data-info">
                      <Info />
                      {" "} No data found in the selected date range
                    </span>
                  }
              </div>
            </div> */}
          </div>
          <div style={{ width: "100%", marginTop: "10px" }}>
            <div className="flex-row">
              <div className="flex-column filter-btn" style={{width: '30%'}}>
                  {details && details.length > 0 &&
                      <button onClick={(e) => clearFilter()}><FilterList />Clear</button>
                  }
              </div>
              <div className="flex-row pagination-container" style={{width: '40%', justifyContent: 'center'}}>
                  {details && details.length > 0 &&
                      <Pagination count={parseInt(details.length/10)} showFirstButton showLastButton onChange={handlePaginationChange}/>
                  }
                  <div className="count-set">{reportCount > 0 && filterCount + " of " + reportCount}</div>
              </div>
              <div className="flex-column count-set" style={{width: '30%'}}>
              <FormControl component="fieldset" className="status-filter">
                  <RadioGroup row aria-label="position" name="position" defaultValue="top">
                    <FormControlLabel className="status-accepted" value="Approved" control={<Radio color="primary" onChange={handleRadioChange} checked={selectedValue === 'Approved'}/>} label="Accepted" />
                    <FormControlLabel className="status-pending" value="Pending" control={<Radio color="primary" onChange={handleRadioChange} checked={selectedValue === 'Pending'}/>} label="Pending" />
                    <FormControlLabel className="status-rejected" value="Deferred" control={<Radio color="primary" onChange={handleRadioChange} checked={selectedValue === 'Deferred'}/>} label="Rejected" />
                  </RadioGroup>
                </FormControl>
              </div>
            </div>
            <Table striped hover variant="dark" className="notification-report-table">
              <thead>
                <tr>
                  <th style={{width: '150px'}}>Notification Id</th>
                  <th style={{width: '250px'}}>Event Time</th>
                  {/* <th style={{width: '200px'}}>Zone</th> */}
                  <th style={{width: '300px'}}>Camera</th>
                  <th style={{width: '200px'}}>Anomaly</th>
                  <th>Comments</th>
                  {/* <th>Status</th> */}
                  <th style={{width: '150px'}}>Thumbnail</th>
                </tr>
              </thead>
              <tbody>
              <tr className="iiq-filter-tr">
                <td>
                  <select className="iiq-select-filter" placeholder="Select Notification Id"
                      onChange={(e) => {
                        applyFilter('notification_id', e.target.value);
                      }}
                      value={filters.notification_id || '' }
                  >
                    <option value="">Select Id</option>
                    {alertIds && alertIds.map(item => <option value={item}>{item}</option>)}
                  </select>
                </td>
                <td>
                </td>
                {/* <td>
                  <select className="iiq-select-filter" placeholder="Select Zone"
                      onChange={(e) => {
                        applyFilter('zone_name', e.target.value);
                      }}
                      value={filters.zone_name || '' }
                  >
                    <option value="">Select Zone</option>
                    {zones && zones.map(item => <option value={item}>{item}</option>)}
                  </select>
                </td> */}
                <td>
                  <select className="iiq-select-filter" placeholder="Select Camera"
                      onChange={(e) => {
                        applyFilter('camera_name', e.target.value);
                      }}
                      value={filters.camera_name || '' }
                  >
                    <option value="">Select Camera</option>
                    {cameras && cameras.map(item => <option value={item}>{item}</option>)}
                  </select>
                </td>
                <td>
                  <select className="iiq-select-filter" placeholder="Select Anomaly"
                      onChange={(e) => {
                        applyFilter('message', e.target.value);
                      }}
                      value={filters.message || '' }
                  >
                    <option value="">Select Anomaly</option>
                    {anomalies && anomalies.map(item => <option value={item}>{item}</option>)}
                  </select>
                </td> 
                <td></td>
                {/* <td>
                  <select className="iiq-select-filter" placeholder="Select Status"
                      onChange={(e) => {
                        applyFilter('status', e.target.value);
                      }}
                      value={filters.status || '' }
                  >
                    <option value="">Select Status</option>
                    {anomalyStatus && anomalyStatus.map(item => <option value={item}>{item}</option>)}
                  </select>
                </td>  */}
                <td></td> 
              </tr>
                {filterData && filterData.map((card) => {
                  return (
                    <tr>
                      <td>
                        <Link to={"/NotificationDescription/" + card.notification_id}>
                          {card.notification_id}
                        </Link>
                      </td>
                      <td>{card.generated_time}</td>
                      {/* <td>{card.zone_name}</td> */}
                      <td>{card.camera_name}</td>
                      <td>{card.message}</td> 
                      <td>
                        <div
                          className="comments flex tooltip-custom"
                        >
                          <span className="tooltiptext-custom">{card.accept_reject_comments}</span>
                          <div className="text-ellipsis--2">
                            {card.accept_reject_comments}
                          </div>
                        </div>
                      </td>
                      {/* <td style={{textAlign: 'center'}}>
                        { card.status == 'Approved' ? <CheckCircleOutline style={{color: 'green'}}/> : card.status == 'Rejected' ? <Cancel style={{color: 'red'}} /> : <Timer style={{color: '#fff'}} /> }
                      </td>  */}
                      <td>
                        <img
                          src={card.img}
                          style={{ width: "100px", height: "55px", cursor: 'pointer'}}
                          onMouseEnter={(e) => showImage(true, card.img)}
                          onMouseLeave={(e) => showImage(false)}
                        />
                      </td> 
                    </tr>
                  );
                })
              }
              </tbody>
            </Table>
            {isShowSpinner ? <Spinner /> : filterData.length == 0 ? <div className="flex show-info">{info}</div> : ''}
            
          </div>
        </div>
    </div>
  );
}

const btn = styled.div`
  background: none;
`;

