import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Red from "../../assets/red.png";
import Yellow from "../../assets/yellow.png";
import Amber from "../../assets/amber.png";
import Blue from "../../assets/blue.png";
import "./dashboard.css";
import PlantCard from "./card/PlantCard";
import Spinner from "../common/Spinner";
import BarDefaultSMS from "../BarDefaultSMS/BarDefaultSMS";
import { DashboardSection, ZoneOptions, MapImage, MainMapView } from "./styles";
import WipScheduler from "./scheduleWIP";
import {
  appFetchAllEquipments,
  appFetchCamerasData,
  zoneScheduleWIP,
  zoneFecthWIPdetails,
} from "../../modals/app/thunk";
import SearchBar from "../common/searchBar";
import {
  appSelectFilteredCameras,
  appSelectNotifications,
} from "../../modals/app/selectors";
import { appSetZonesFilter } from "../../modals/app/actions";
import Map from "../assets/Plant-map.jpg";

export function Plant({
  fetchEquipment,
  fetchCameras,
  setSearchFilter,
  cameras,
  history,
  createWIP,
  getWipDetails,
}) {
  const [schedulingZone, setSechudulingZone] = useState({});
  const [equipments, setEquipments] = useState([]);
  const [isMapView, setIsMapView] = useState(false);
  const [localvalue, setLocalvalue] = useState("");

  const [cameraItems, setCameraItems] = useState([]);
  const [severity, setSeverity] = useState(null);

  useEffect(() =>{
    setCameraItems(cameras)
  }, [cameras]);

  useEffect(() => {
    if (localStorage.getItem("role_id") == undefined) history.push("/login");
    else setLocalvalue(localStorage.getItem("role_id"));
  }, []);

  const fetchREquiredData = async () => {
    fetchCameras();
    console.log("cameras :", cameras);
    const { data: equipments } = await fetchEquipment();
    setEquipments(equipments);
  };

  const filterCamerasItems = (searchText) => {
    searchText = searchText.toLowerCase();
    if(cameras && cameras.length > 0) {
      const result = cameras.filter(item => (item.location.toLowerCase()).includes(searchText) 
        || (item.camera_rule.toLowerCase()).includes(searchText) 
        || (item.name.toLowerCase()).includes(searchText)
        || (String(item.notification_count).toLowerCase()).includes(searchText));
      setCameraItems(result);
    }
  }

  const handleSeverity = (severity) => {
    severity = severity.toLowerCase();
    if(cameras && cameras.length > 0) {
      if (severity !== 'all') {
        const result = cameras.filter(item => item.severity && item.severity.toLowerCase() === severity);
        setCameraItems(result);
        setSeverity(severity);
      } else {
        setCameraItems(cameras);
        setSeverity(null);
      }
    }
  }

  const searchFilter = (text) => {
    //setSearchFilter(text);
    filterCamerasItems(text);
  };
  useEffect(() => {
    fetchREquiredData();
  }, []);

  return (
    <DashboardSection>
      <BarDefaultSMS />
      {!isMapView ? (
        <div>
          <ZoneOptions>
          <div className="iiq-searchbar">
            <SearchBar changeAction={searchFilter} />
          </div>
          <div className="flex" style={{
            justifyContent: 'right',
            width: '100%'
          }}>
            <img src={Red} width={15} height={15} onClick={() => handleSeverity('high')}/>
            <h4
              style={{
                color: "white",
                marginLeft: "5px",
                marginRight: "10px",
                fontSize: "15px",
                marginTop : '8px',
                cursor: 'pointer'
              }}
              onClick={() => handleSeverity('high')}
            >
              High
            </h4>
            <img src={Amber} width={15} height={15} onClick={() => handleSeverity('medium')}/>
            <h4
              style={{
                color: "white",
                marginLeft: "5px",
                marginRight: "10px",
                fontSize: "15px",
                marginTop : '8px',
                cursor: 'pointer'
              }}
              onClick={() => handleSeverity('medium')}
            >
              Medium
            </h4>

            <img src={Yellow} width={15} height={15} onClick={() => handleSeverity('low')}/>
            <h4
              style={{
                color: "white",
                marginLeft: "5px",
                marginRight: "10px",
                fontSize: "15px",
                marginTop : '8px',
                cursor: 'pointer'
              }}
              onClick={() => handleSeverity('low')}
            >
              Low
            </h4>
            <img src={Blue} width={15} height={15} onClick={() => handleSeverity('all')}/>
            <h4
              style={{
                color: "white",
                marginLeft: "5px",
                marginRight: "10px",
                fontSize: "15px",
                marginTop : '8px',
                cursor: 'pointer'
              }}
              onClick={() => handleSeverity('all')}
            >
              All
            </h4>
          </div>
        </ZoneOptions>
        </div>
      ) : null}
      {isMapView ? (
        <MainMapView>
          <MapImage src={Map} />
        </MainMapView>
      ) : cameraItems && cameraItems.length ? (
        <>
          <div className="cards flex">
            {cameraItems.map((card) => {
              return (
                <PlantCard
                  key={card.camera_id}
                  card={card}
                  severity={card.severity}
                  toggleWipScheduler={setSechudulingZone}
                />
              );
            })}
          </div>
          <WipScheduler
            schedulingZone={schedulingZone}
            toggleWipScheduler={setSechudulingZone}
            fetchEquipment={fetchEquipment}
            equipments={equipments}
            createWIP={createWIP}
            getWipDetails={getWipDetails}
          />
        </>
      ) : (
        <div>
          { cameras && cameras.length ? 
          <div style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: '#ffffff',
            fontSize: '18px'
          }}> No {severity && severity} severity records! </div>
          :
          <Spinner />
        }
        </div>
      )}
    </DashboardSection>
  );
}

const mapStateToProps = (state, ownProps) => ({
  cameras: appSelectFilteredCameras(state),
  notifications: appSelectNotifications(state),
  apiCallsInProgress: state.apiCallsInProgress,
});

const mapDispatchToProps = {
  fetchCameras: appFetchCamerasData,
  fetchEquipment: appFetchAllEquipments,
  setSearchFilter: appSetZonesFilter,
  createWIP: zoneScheduleWIP,
  getWipDetails: zoneFecthWIPdetails,
};

export default connect(mapStateToProps, mapDispatchToProps)(Plant);
