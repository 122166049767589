import * as types from "./types.js";


export const setUsers = (users) => {
  return {
    type: types.SET_TOTAL_USERS,
    users
  }
};

export const platformUsersSetNameFilter = (nameFilter) => ({
  type: types.PLATFORM_USERS_SET_NAME_FILTER,
  nameFilter,
});