import React from 'react';
import AnalyticsCard from '../components/analytics-card';

import './style.css'
const AnalyticsBody = ({zones}) => {

    const getCardContent = (item) => {
        return (
            <div className="card-top">{item.name}</div>
        )
    }
    const getCardFooter = (item) => {
        return Object.keys(item.status).map(status => {
            return (
                <div className="card-actions">
                    <label>{status}: </label>
                    {item.status[status]}
                </div>
            )
        });
    }
    return (
        <div className="iiq-analytics-body">
            { zones && zones.length > 0 &&
                zones.map(item => {
                    return (
                        <div className="iiq-analytics-card-container">
                            <AnalyticsCard content={getCardContent(item)} footer={getCardFooter(item)} />
                        </div>
                    )
                })
            }
        </div>
    );
}

export default AnalyticsBody;