import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { Container } from "@material-ui/core";
import {
  CustomInput,
  ActionButtons,
  SubmitSection,
  ErrorMsg,
  AligningWrapper,
} from "./styles";
import { Select } from "antd";

const { Option } = Select;

const AddCameraForm = ({
  cancelAction,
  addCamera,
  fetchUnits,
  fetchParentZones,
  preLoadedValues,
  isEditing,
  updateCamera,
}) => {
  const [units, setUnits] = useState([]);
  const [parentZones, setParentZones] = useState([]);
  const [selectedUnitId, setSelectedUnit] = useState(null);
  const [selectedParentZoneId, setSelectedParentZone] = useState(null);
  const [customError, setCustomError] = useState(null);

  const { handleSubmit, register, errors, reset } = useForm({
    defaultValues: preLoadedValues || {},
  });

  const onSubmit = async (values) => {
    // reset();
    console.log(
      "clicked data submission ===>",
      values,
      selectedUnitId,

    );
    const name = values.name;
    const ipaddress = values.ipaddress;
    const location = values.location;
    const camera_rule = values.camera_rule;
    const unit_id = selectedUnitId;
    const parent_zone_id = selectedParentZoneId;
    const username = values.username;
    const password = values.password;

    const camera = {
      name,
      ipaddress,
      location,
      camera_rule,
      username,
      password,
      unit_id,
      parent_zone_id,
    };
    console.log('isEditing :', isEditing)
    isEditing
      ? updateCamera({
        ...preLoadedValues,
        ...camera,
      })
      : addCamera(camera);
  };

  const handleUnitChange = (value) => {
    setSelectedUnit(value);
    if (selectedUnitId)
      setCustomError(null);
  };

  const handleParentZoneChange = (value) => {
    setSelectedParentZone(value);
    if (selectedParentZoneId)
      setCustomError(null);
  };

  useEffect(() => {
    fetchComponentData();
  }, []);

  const fetchComponentData = async () => {
    const units = await fetchUnits();
    setUnits(units);
    console.log("fetched from form ==>", units);
    const parentZones = await fetchParentZones();
    setParentZones(parentZones);
    console.log("fetched from form ==>", parentZones);
  };

  return (
    <Container>
      <form onSubmit={handleSubmit(onSubmit)}>
        <CustomInput
          name="name"
          placeholder={"Name"}
          ref={register({
            required: "Required",
            validate: (value) => value !== "admin" || "Nice try!",
          })}
        />
        <ErrorMsg>{errors.name && errors.name.message}</ErrorMsg>
        <CustomInput

          name="ipaddress"
          placeholder={"IP Address"}
          ref={register({
            required: "Required",
            validate: (value) => value.length != 0,
          })}
        />
        <ErrorMsg>{errors.ip_address && "Provide IP Address"}</ErrorMsg>

        <CustomInput
          placeholder={"Location"}
          name="location"
          ref={register({
            required: "Required",
            validate: (value) => value.length != 0,
          })}
        />
        <ErrorMsg>{errors.location && "Provide Location"}</ErrorMsg>
        <CustomInput
          placeholder={"Camera Title"}
          name="camera_rule"
          ref={register({
            required: "Required",
            validate: (value) => value.length != 0,
          })}
        />
        <ErrorMsg>{errors.Camera_rule && "Provide Camera Title"}</ErrorMsg>


        <AligningWrapper>
          <Select
            style={{ width: "100%" }}
            placeholder="Select Unit"
            onChange={handleUnitChange}
            defaultValue={
              isEditing
                ? preLoadedValues.unit_id
                : units.legth && units[0].unit_id
            }
          >
            {units.length &&
              units.map(({ unit_id: id, name }) => (
                <Option value={id}>{name}</Option>
              ))}
          </Select>
        </AligningWrapper>

        <CustomInput
          placeholder={"User Name"}
          maxLength="50"
          name="username"
          ref={register({
            required: "Required",
            validate: (value) => value.length != 0,
          })}
        />
        <ErrorMsg>{errors.username && "Provide User Name"}</ErrorMsg>
        <CustomInput
          type="password"
          placeholder={"Password"}
          name="password"
          ref={register({
            required: "Required",
            validate: (value) => value.length === 8,
          })}
        />
        <ErrorMsg>{errors.password && "Provide a valid Password"}</ErrorMsg>

        <SubmitSection>
          <ActionButtons onClick={() => cancelAction(null)}>
            Cancel
          </ActionButtons>
          <ActionButtons isSubmit type="submit">
            Submit
          </ActionButtons>
        </SubmitSection>
      </form>
    </Container>
  );
};

export default AddCameraForm;
