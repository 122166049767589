const baseUrl = `https://qcaefqcyp9.execute-api.ap-south-1.amazonaws.com/prod`;

export const fetchZoneUrl = `${baseUrl}/fetchparentzoneall`;

export const deleteZoneUrl = `${baseUrl}/deletezone`;

export const addZoneUrl = `${baseUrl}addparentzone`;

export const updateZoneUrl = `${baseUrl}/updateparentzone`;

export const fetchplantUrl = `${baseUrl}/fetchplant`;
