import { createSelector } from "reselect";
import { compare } from "../../Utils";

export const selectShift = ({ shift }) => {
	console.log("Selector Shift :",shift);
	return	shift;
	}
export const ShiftSelectSearchFilter = ( {

    filters: { nameFilter },

}) => {
  return nameFilter;
};

export const ShiftSelectFilteredList = createSelector(
  [selectShift, ShiftSelectSearchFilter],
  (shift, nameFilter) => {
    if (!shift.length) return shift;
    shift.sort(compare);
    if (!nameFilter) return shift;
    const filteredshift = shift.filter(
      ({ name }) => name.toUpperCase().indexOf(nameFilter.toUpperCase()) > -1
    );
    return filteredshift;
  }
);
