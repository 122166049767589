import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import "./platformSettingsIIQ.css";
import Zone from "../../assets/area.png";
import Plant from "../../assets/factory.png";
import Rule from "../../assets/shield.png";
import Camera from "../../assets/security-cam.png";
import User from "../../assets/usermaster.png";
import Group from "../../assets/group.png";
import Axios from "axios";
import sha256 from "sha256";
import Arrow from "../../assets/arrow-new.png";

function PlatformSettings(props) {
  const [visible, setVisible] = useState(false);
  const [password, setPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [changePassword, setChangePassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [customError, setCustomError] = useState(null);

  const showChangePasswordModal = () => {
    setVisible(true);
    setPassword(true);
    setLoading(false);
  };
  const handleConfirmPassword = (e) => {
    let value = e.target.value;
    setConfirmPassword(value);
  };
  const handlechangepasswordCancel = (e) => {
    setPassword(false);
  };
  const handleChangePassword = (e) => {
    let value = e.target.value;
    setChangePassword(value);
  };
  const handleSubmit = () => {
    setCustomError("");
    setLoading(true);
    if (!changePassword || changePassword.length < 6) {
      setCustomError("Password must contain atleast 6 characters");
      setLoading(false);
      return;
    }
    if (changePassword != confirmPassword) {
      setCustomError("Password does not match");
      setLoading(false);
      return;
    }
    let obj = {
      password: sha256(changePassword),
      username: localStorage.getItem("user_name"),
    };
    Axios.post(
      "https://qcaefqcyp9.execute-api.ap-south-1.amazonaws.com/prod/updateplatformuserpassword",
      obj,
      {}
    )
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
    setTimeout(() => {
      setLoading(false);
      setVisible(false);
    }, 3000);
  };
  return (
    <div className="settings-screen">
      <div>
        <h1
          style={{
            color: "white",
            margin: "15px",
            fontSize: "30px",
          }}
        >
          Platform Settings
        </h1>
        <span className="flex">
          <NavLink to="/platforminitilization" className="icon-settings">
            <img src={Plant} alt="" className="img-settings" />
            <div className="title_text">Plant Setup</div>
          </NavLink>
          <img
            src={Arrow}
            style={{ width: "30px", marginBottom: "50px" }}
          ></img>
          <NavLink to="/Platform-user-management" className="icon-settings">
            <img src={User} alt="" className="img-settings" />
            <div className="title_text">Platform User Management</div>
          </NavLink>
          <img
            src={Arrow}
            style={{ width: "30px", marginBottom: "50px" }}
          ></img>
          <NavLink to="/NotificationGroupManagement" className="icon-settings">
            <img src={Group} alt="" className="img-settings" />
            <div className="title_text">Notification Group Management</div>
          </NavLink>
          <img
            src={Arrow}
            style={{ width: "30px", marginBottom: "50px" }}
          ></img>
          <NavLink to="/CameraManagement" className="icon-settings">
            <img src={Camera} alt="" className="img-settings" />
            <div className="title_text">Camera Management</div>
          </NavLink>
          <img
            src={Arrow}
            style={{ width: "30px", marginBottom: "50px" }}
          ></img>
          <NavLink to="/RuleManagement" className="icon-settings">
            <img src={Rule} alt="" className="img-settings" />
            <div className="title_text">Rule Management</div>
          </NavLink>
          <img
            src={Arrow}
            style={{ width: "30px", marginBottom: "50px" }}
          ></img>
          <NavLink to="/ZoneManagement" className="icon-settings ">
            <img src={Zone} alt="" className="img-settings" />
            <div className="title_text">Zone Management</div>
          </NavLink>
        </span>
      </div>
    </div>
  );
}
export default PlatformSettings;
