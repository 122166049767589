import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import Table from "../userManagement/userTable";
import { ManagementSection, BannerHeading } from "./styles";
import Panel from "../common/panelUser";
import AddUserForm from "./addUserForm";
import AddBulkUser from "./addBulkUser";
import { selectUsers, } from "../../modals/userManagement/selectors";
import { platformUsersSetNameFilter } from "../../modals/userManagement/actions";
import {
  fetchUsers,
  fetchUserByAlphabet,
  deleteUser,
  addUser,
  updateUser,
} from "../../modals/userManagement/thunk";
import {
  appFetchAllDesignations,
  appFetchAllUnits,
  appFetchAllRoles,
} from "../../modals/app/thunk";

class UserManagement extends Component {
  state = {
    panelOpenType: "",
    activeUser: null,
  };
  componentDidCatch(error, errorInfo) {
    console.log(error, errorInfo, "==>");
  }

  componentDidMount() {
    const { fetchUsers } = this.props;
    fetchUsers("A");
  }

  toggleUserPanel = (panelOpenType) => {
    if (!panelOpenType) this.setUser(null);
    this.setState({ panelOpenType });
  };
  setUser = (user) => {
    this.setState({ activeUser: null });
  };
  editUser = (user) => {
    this.setState({ panelOpenType: "edit", activeUser: user });
  };

  handleDelete = (status) => {
    this.setState({ showconfirmation: status });
  };

  setSearchFilter = (key) => {
    const { setSearchFilter } = this.props;
    alert(key);
  }

  confirmDelete = () => {
    const { deleteUser } = this.props;
    deleteUser();
    this.handleDelete(false);
  };

  render() {
    if (localStorage.getItem("role_id") == undefined)
    {
      return (
        <Redirect to="/login" />
    )
    }
    const { panelOpenType, activeUser } = this.state;
    const {
      addUser,
      deleteUser,
      updateUser,
      fetchUnits,
      fetchRoles,
      fetchDesignations,
      users,
      setSearchFilter,
      setFilterChar,
    } = this.props;
    return (
      <ManagementSection>
        <BannerHeading>Employee Master</BannerHeading>
        <Table
          action={this.toggleUserPanel}
          rowData={users.users}

          requiredElements={[
            "emp_id",
            "name",
            "phone_number",
            "unit_name",
            "designation_name",
            "role_name",
            "email_id",
            "status",
            "is_active",
          ]}
          columnsElements={[
            "Employee ID",
            "Name",
            "Phone Number",
            "Unit",
            "Designation",
            "Role",
            "Email ID",
            "Setup Status",
            "Active",
          ]}
          panelType={panelOpenType}
          editUser={this.editUser}
          deleteUser={this.confirmDelete}
          setSearchFilter={setSearchFilter}
          perPage={10}
        />
        <Panel
          panelType={panelOpenType}
          isPanelOpen={Boolean(panelOpenType)}
          Heading={
            panelOpenType === "edit"
              ? "Update Employee details"
              : panelOpenType === "add"
                ? "Add Employee"
                : panelOpenType === "searchBy"
                  ? "Search By"
                  : panelOpenType === "upload"
                    ? "Bulk upload Employees"
                    : null
          }
          closeAction={this.toggleUserPanel}
        >

          {panelOpenType && (panelOpenType === "add" || panelOpenType === "edit") ? (
            <AddUserForm
              cancelAction={this.toggleUserPanel}
              addUser={addUser}
              deleteUser={deleteUser}
              fetchUnits={fetchUnits}
              fetchRoles={fetchRoles}
              fetchDesignations={fetchDesignations}
              preLoadedValues={activeUser}
              isEditing={panelOpenType === "edit"}
              updateUser={updateUser}
            />
          ) : (
              <AddBulkUser />
            )}
        </Panel>
      </ManagementSection>
    );
  }
}

const mapStateToProps = (state) => ({
  users: selectUsers(state),
});

const mapDispatchToProps = {
  addUser: addUser,
  updateUser: updateUser,
  fetchUsers: fetchUsers,
  fetchUserByAlphabet: fetchUserByAlphabet,
  deleteUser: deleteUser,
  fetchUnits: appFetchAllUnits,
  fetchDesignations: appFetchAllDesignations,
  fetchRoles: appFetchAllRoles,
  setSearchFilter: platformUsersSetNameFilter,
};

export default connect(mapStateToProps, mapDispatchToProps)(UserManagement);
