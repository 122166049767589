import * as types from "./types.js";


export const setZones = (zone) => {
  console.log("Set Zones : ", zone);
  return {
    type: types.SET_TOTAL_ZONES,
    zone,
  }
};

export const ZoneSetNameFilter = (nameFilter) => ({
  type: types.ZONE_SET_NAME_FILTER,
  nameFilter,
});