import * as types from "./types";

export const initialState = {
  notifications: [],
  dailyNotifications: [],
  pushNotification: {
    msg: null,
    timeOut: null,
  },
  availableZones: [],
  availableCameras: [],
  zonesFilter: "",
  hodList: [],
  apiError: null
};

export default function AppReducer(state = initialState, action) {
  switch (action.type) {
    case types.APP_SET_NOTIFICATIONS: {
      const { notifications } = action;
      return (state = { ...state, notifications });
    }
    case types.APP_SET_DAILY_NOTIFICATIONS: {
      const { dailyNotifications } = action;
      return (state = { ...state, dailyNotifications });
    }
    case types.APP_SET_PUSH_NOTIFICATION: {
      const { msg } = action;
      return (state = {
        ...state,
        pushNotification: { ...state.pushNotification, msg },
      });
    }

    case types.APP_SET_PUSH_NOTIF_TIMEOUT: {
      const { timeOut } = action;
      return {
        ...state,
        pushNotification: { ...state.pushNotification, timeOut },
      };
    }
    case types.APP_SET_AVAILABLE_ZONES: {
      const { zones: availableZones } = action;
      return (state = { ...state, availableZones });
    }
    case types.APP_SET_AVAILABLE_CAMERAS: {
      const { cameras: availableCameras } = action;
      return (state = { ...state, availableCameras });
    }
    case types.APP_SET_AVAILABLE_ACAMERAS: {
      const { acameras: availableACameras } = action;
      return (state = { ...state, availableACameras });
    }
    case types.APP_SET_AVAILABLE_USERS: {
      const { users: availableUsers } = action;
      return (state = { ...state, availableUsers });
    }
    case types.APP_SET_ZONES_FILTER: {
      const { filter: zonesFilter } = action;
      return (state = { ...state, zonesFilter });
    }
    case types.APP_SET_HOD_LIST: {
      const { hodList } = action;
      return (state = { ...state, hodList });
    }
    case types.APP_API_ERROR: {
      const { apiError } = action;
      return (state = { ...state, apiError });
    }
    default:
      return state;
  }

  return state;
}
