import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Button,
  ButtonGroup,
  TextField,
  FormControl,
} from "@material-ui/core";
import { Radio, Select } from "antd";

import "./information-style.js";
import {
  useStyles,
  SeveritySelection,
  ShiftsWrapper,
} from "./information-style";
import Title from "../title/title";
import { connect } from "react-redux";
import { setZoneData } from "../../redux/actions/zoneAction";
import {
  appSelectFilteredZones,
  appSelectHodList,
} from "../../modals/app/selectors";
import "./information.css";
import Axios from "axios";
import styled from "styled-components";
import { appFetchHodList } from "../../modals/app/thunk.js";
import { appShowPushNotification } from "../../modals/app/thunk";
import {getZoneData} from "../../api/zone";
const { Option } = Select;

export function Information(props) {
  const [zoneInfo, setZoneInfo] = useState(
    props.currentZone || { shift_ids: [] }
  );
  const [shiftIds, setShiftIds] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [severity, setSeverity] = useState("low");
  const [errorMsg, setErrorMsg] = useState(null);
  let zoneName = '';
  useEffect(() => {
    Axios.get("https://qcaefqcyp9.execute-api.ap-south-1.amazonaws.com/prod/departmentwithcamera").then(
      (res) => {
        setDepartments(res.data.data);
      }
    );
    getZoneData().then((res) =>
    {
      console.log(res.data.data[0]);
      setZoneInfo(res.data.data[0]);
    }
    );
    
  }, []);

  useEffect(() => {
    const { fetchHods, currentZone } = props;
    fetchHods();
    console.log("Props ", props);
  }, [props.currentZone]);

  const onsubmithandler = (event) => {
    localStorage.setItem("zone_id", zoneInfo.zone_id);
    
    Axios.post('https://qcaefqcyp9.execute-api.ap-south-1.amazonaws.com/prod/fetchunitnamebyid',
    {
      "unit_id" : zoneInfo.unit_id
    }
      ).then((res)=>{
        console.log("Response" ,res);
        zoneName = res.data.data[0].name
        localStorage.setItem("zone_name",zoneName);
      });


    let data = {
      zone_id: zoneInfo.zone_id,
      name: zoneInfo.name,
      shift_ids: shiftIds,
      unit_id: zoneInfo.unit_id,
      parent_zone_id: zoneInfo.parent_zone_id ? zoneInfo.parent_zone_id : 1,
      severity: zoneInfo.severity,
      hod_name: zoneInfo.hod_name,
      hod_phone: zoneInfo.hod_phone,
    };
    if (!data.zone_id) {
      setErrorMsg("No Zone Id, Please Refresh To Get A ZoneId");
      return;
    } else if (!data.name) {
      setErrorMsg("Please Provide Zone Name");
      return;
    } else if (!data.unit_id) {
      setErrorMsg("Please, Select Department");
      return;
    }
    console.log("Data",data);
    localStorage.setItem("zone", JSON.stringify(data));
    setErrorMsg(null);
    const { pushnotification } = props;
    props.setZoneData(zoneInfo).then(() => {
      Axios.post(
        "https://qcaefqcyp9.execute-api.ap-south-1.amazonaws.com/prod/updatezone",
        data
      )
        .then((res) => {
          pushnotification("Zone Details Updated Successfully !");
        })
        .catch((err) => {
          pushnotification("Failed to Update Zone Details !");
          console.error(err);
        });

      props.handleChange(2);
    });
  };
  const handleChange = (event, name) => {
    setZoneInfo({ ...zoneInfo, [name]: event.target.value });
  };

  const setSeverityHandler = (level) => {
    setSeverity(level);
    setZoneInfo({ ...zoneInfo, severity: level });
  };
  const handleSelectChange = (value, selectType) => {
    if (selectType === "hod_user_id") {
      let selectedValue = props.hodList.filter((user) => user.user_id == value);
      let ph = selectedValue[0].phone_number;
      setZoneInfo({ ...zoneInfo, ["hod_phone"]: ph, [selectType]: value });
    } else setZoneInfo({ ...zoneInfo, [selectType]: value });
  };

  const classes = useStyles();
  return (
    <Wrapper classes={classes.info}>
      <Heading>Zone Information</Heading>
      <Content>
        <div className={classes.content}>
          <div className={classes.left}>
            <TextField
              
              style={{ width: 100 }}
              disabled
              onChange={(event) => handleChange(event, "zone_code")}
              value={zoneInfo.zone_code}
            />

            <TextField
              style={{ width: "300px" }}
              id="standard-basic"
              label="Zone Name"
              onChange={(event) => handleChange(event, "name")}
              value={zoneInfo.name}
            />
            
            <SeveritySelection
              color={
                severity === "high"
                  ? "#ff0008"
                  : severity === "medium"
                  ? "#facc2c"
                  : "#2dd1ac"
              }
            >
              <p>Severity</p>
              <Radio.Group
                onChange={(severity) => {
                  setSeverityHandler(severity.target.value);
                }}
                defaultValue={severity}
              >
                <Radio value={"high"}>High</Radio>
                <Radio value={"medium"}>Medium</Radio>
                <Radio value={"low"}>Low</Radio>
              </Radio.Group>
            </SeveritySelection>
            
            <ShiftsWrapper >
              
              <FormControl variant="outlined" className={classes.formControl}>
                <Select
                  native
                  placeholder="Select Department"
                  style={{ width: "100%", color: "#000" }}
                  value={zoneInfo.unit_id}
                  onChange={(value) => handleSelectChange(value, "unit_id")}
                  label="Department"
                  inputProps={{
                    name: "Department",
                    id: "outlined-age-native-simple",
                  }}
                >
                  {departments ? (
                    departments.map((o, index) => (
                      <Option value={o.unit_id}>{o.unit_name}</Option>
                    ))
                  ) : (
                    <></>
                  )}
                </Select>
              </FormControl>
            </ShiftsWrapper>


            <span
              style={{ color: "white", marginTop: "10px", fontSize: "14px" }}
            >
              HOD Name
            </span>
            <TextField
              style={{ width: "300px" }}
              id="standard-basic"
              type="text"
              InputProps={{
                readOnly: false,
              }}
              value={zoneInfo.hod_name}
              
            />
            <ErrorHighlighter>{errorMsg}</ErrorHighlighter>
            
            <span
              style={{ color: "white", marginTop: "10px", fontSize: "14px" }}
            >
              HOD Phone Number
            </span>
            <TextField
              style={{ width: "300px" }}
              id="standard-basic"
              type="Number"
              InputProps={{
                readOnly: false,
              }}
              value={zoneInfo.hod_phone}
              maxLength={10}
            />
            <ErrorHighlighter>{errorMsg}</ErrorHighlighter>


          </div>

          <div className={classes.right}>
            <Title>Selected Zone Map</Title>
          </div>
        </div>
      </Content>

      <ButtonOuter>
        <ButtonGroup className={classes.nextbutton}>
          <Button
            style={{ color: "white" }}
            onClick={() => props.handleChange(0)}
          >
            Previous
          </Button>
          <Button
            className={classes.nextbutton}
            style={{ color: "white" }}
            onClick={onsubmithandler}
          >
            Next
          </Button>
        </ButtonGroup>
      </ButtonOuter>
    </Wrapper>
  );
}
Information.prototype = {
  className: PropTypes.string,
};

function mapStateToProps(state, ownProps) {
  return {
    currentZone: state.zone,
    zones: appSelectFilteredZones(state),
    hodList: appSelectHodList(state),
  };
}

const mapDispatchToProps = {
  setZoneData,
  fetchHods: appFetchHodList,
  pushnotification: appShowPushNotification,
};

export default connect(mapStateToProps, mapDispatchToProps)(Information);

const Wrapper = styled.div`
  padding: 20px;
  position: relative;
`;

const Content = styled.div`
  ${"" /* margin: 24px 32px; */}
`;

const Heading = styled.h1`
  margin: 0 0 20px;
  color: wheat;
  font-size: 26px;
  font-weight: normal;
  color: white;
`;

const ButtonOuter = styled.div`
  background: #111728;
  display: flex;
  justify-content: flex-end;
  padding: 10px 0;
  left: 0;
  width: 100%;
`;

export const ErrorHighlighter = styled.p`
  margin: 1rem;
  color: red;
`;
