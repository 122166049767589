import React, { Component } from "react";
import { connect } from "react-redux";
import Table from "../ZoneManagement/userTable";
import AddZoneForm from "../ZoneManagement/AddZoneForm"
import { ManagementSection, BannerHeading } from "./styles";
import Panel from "../common/panelUser";
import  { Redirect } from 'react-router-dom'

import { selectZone } from "../../modals/ZoneManagement/selectors";
import { ZoneSetNameFilter } from "../../modals/ZoneManagement/actions";
import {
    fetchZone,
    deleteZone,
    addZone,
    updateZone,
} from "../../modals/ZoneManagement/thunk";
import {
    appFetchAllUnits,
    appFetchAllRoles,
} from "../../modals/app/thunk";

class ZoneManagement extends Component {
    state = {
        panelOpenType: "",
        activeUser: null,
    };
    componentDidCatch(error, errorInfo) {
        console.log(error, errorInfo, "==>");
    }

    componentDidMount() {
        const { fetchZone } = this.props;
        fetchZone();
    }

    toggleUserPanel = (panelOpenType) => {
        console.log("panel function inside ==>");
        this.props.history.push('/add-zone')

        console.log('Panel Open Type :', panelOpenType);
    };

    editZone = (zone) => {
        this.setState({ panelOpenType: "edit", activeUser: zone });
    };

    handleDelete = (status) => {
        this.setState({ showconfirmation: status });
    };

    confirmDelete = (zone) => {
        console.log(zone.zone_id);
        this.props.history.push('/preview-zone/'+zone.zone_id);
    };

    render() {
        const { panelOpenType, activeUser } = this.state;
        const {
            addZone,
            deleteZone,
            updateZone,
            fetchUnits,
            fetchRoles,
            zone,
            setSearchFilter,
        } = this.props;
        return (
            <ManagementSection>
                <BannerHeading>Zone Management</BannerHeading>
                <Table
                    action={this.toggleUserPanel}
                    rowData={zone.zone}

                    requiredElements={[
                        "name",
                        "unit_name",
                        "severity",
                        "camera_count",
                        "rule_count"

                    ]}
                    columnsElements={[
                        "Name",
                        "Deaprtment",
                        "Severity",
                        "No of Cameras",
                        "No of Rules"
                    ]}
                    panelType={panelOpenType}
                    editZone={this.editZone}
                    deleteZone={this.confirmDelete}
                    setSearchFilter={setSearchFilter}
                    perPage={10}
                />
                <Panel
                    panelType={panelOpenType}
                    isPanelOpen={Boolean(panelOpenType)}
                    Heading={
                        panelOpenType === "edit"
                            ? "Update Zone details"
                            : panelOpenType === "add"
                                ? "Add Zone"
                                : panelOpenType === "searchBy"
                                    ? "Search By"
                                    : null
                    }
                    closeAction={this.toggleUserPanel}
                >

                    {panelOpenType && (
                        <AddZoneForm
                            cancelAction={this.toggleUserPanel}
                            addZone={addZone}
                            deleteZone={deleteZone}
                            fetchUnits={fetchUnits}
                            fetchRoles={fetchRoles}
                            preLoadedValues={activeUser}
                            isEditing={panelOpenType === "edit"}
                            updateZone={updateZone}
                        />
                    )}
                </Panel>
            </ManagementSection>
        );
    }
}
const mapStateToProps = (state) => ({
    zone: selectZone(state),
});

const mapDispatchToProps = {
    addZone: addZone,
    updateZone: updateZone,
    fetchZone: fetchZone,
    deleteZone: deleteZone,
    fetchUnits: appFetchAllUnits,
    fetchRoles: appFetchAllRoles,
    setSearchFilter: ZoneSetNameFilter,
};

export default connect(mapStateToProps, mapDispatchToProps)(ZoneManagement);
