import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import "./dashboard.css";
import Card from "./card/Card";
import DashboardCard from "./card/dashboardCard";
import Spinner from "../common/Spinner";
import BarDefaultSMS from "../BarDefaultSMS/BarDefaultSMS";
import Axios from "axios";
import {
  DashboardSection,
  ZoneOptions,
  MapImage,
  MainMapView,
} from "./styles";
import WipScheduler from "./scheduleWIP";
import {
  appFetchAllEquipments,
  zoneScheduleWIP,
  appFetchACamerasData,
  zoneFecthWIPdetails,
} from "../../modals/app/thunk";
import SearchBar from "../common/searchBar";
import SortBy from "../common/sortBy";
import {
  appSelectNotifications,
} from "../../modals/app/selectors";
import { appSetZonesFilter } from "../../modals/app/actions";
import Map from "../assets/Plant-map.jpg";

import Red from "../../assets/red.png";
import Yellow from "../../assets/yellow.png";
import Amber from "../../assets/amber.png";
import Blue from "../../assets/blue.png";
import _ from 'lodash';
import { Modal } from "antd";
const sortList = [{
  label: 'Latest',
  value: 'generated_time'
},{
  label: 'Anomaly name',
  value: 'name'
},{
  label: 'Anomaly count',
  value: 'notification_count'
},{
  label: 'Location',
  value: 'location'
},{
  label: 'Camera',
  value: 'camera_rule'
},{
  label: 'Severity(High to Low)',
  value: 'high'
},{
  label: 'Severity(Low to High)',
  value: 'low'
}];
export function Tab({
  fetchACameras,
  fetchEquipment,
  setSearchFilter,
  history,
  createWIP,
  getWipDetails,
}) {
  const [schedulingZone, setSechudulingZone] = useState({});
  const [equipments, setEquipments] = useState([]);
  const [isMapView, setIsMapView] = useState(false);
  const [localvalue, setLocalvalue] = useState("");
  const [zones,setZones] = useState([]);
  const [zoneItems, setZoneItems] = useState([]);
  const [severity, setSeverity] = useState(null);
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [sortOptions, setSortOptions] = useState(sortList);

  const [isShowModal, setIsShowModal] = useState(false);
  const [apiError, setAPIError] = useState(null);
  const [acameras, setAcameras] = useState([]);
  const [selectedValue, setSelectedValue] = useState("generated_time");
  //const acameras = useSelector((state) => state.appData.acameras);
  

  useEffect(() => {
    if(apiError && isShowModal != true) {
      setIsShowModal(true);
    }
  }, [apiError]);

  useEffect(() => {
    if(isShowModal) {
      Modal.error({
        wrapClassName: 'error-modal',
        title: 'Oops!',
        content: "We're sorry, but something went wrong.",
        onOk() {
          setIsShowModal(false);
        }
      });
    }
  },[isShowModal]);

  useEffect( () => {
	  if(localStorage.getItem("role_id")  == undefined)
		history.push("/login")
	else
      setLocalvalue(localStorage.getItem("role_id") );
   }, []);

  useState(() => {
    fetchACameras().then((res) =>{
      setAcameras(res);
      setIsDataLoaded(true);
    });
  }, []);

  const filterCamerasItems = (searchText) => {
    searchText = searchText.toLowerCase();
    if(zones && zones.length > 0) {
      const result = zones.filter(item => ((item.name.toLowerCase()).includes(searchText)
        || (String(item.notification_count).toLowerCase()).includes(searchText)
        || (String(item.notification_id).toLowerCase()).includes(searchText)));
        setZoneItems(result);
    }
  }

  const handleSeverity = (severity) => {
    severity = severity.toLowerCase();
    if(zones && zones.length > 0) {
      if (severity !== 'all') {
        const result = zones.filter(item => item.severity && item.severity.toLowerCase() === severity);
        setZoneItems(result);
        setSeverity(severity);
      } else {
        setZoneItems(zones);
        setSeverity(null);
      }
    }
  }

  const searchFilter = (text) => {
    //setSearchFilter(text);
    filterCamerasItems(text);
  };

  useEffect(() => {
    if(acameras && acameras.length > 0) {
      const zoneId = localStorage.getItem("zone_id");
      const zoneData = acameras.filter(item => item.zone_id == zoneId);
      const items = _.orderBy(zoneData, "generated_time", "desc");
      setZones(items);
      setZoneItems(items);
      setSelectedValue("generated_time");
    }else{
      setZones([]);
    }
  }, [localStorage.getItem("TabId"), acameras]);

  const handleSort = (text) => {
    let items;
    setSelectedValue(text);
    if(text == 'low') {
      items = _.orderBy(zoneItems, 'severity', 'desc');
    } else {
      text = text == 'high' ? 'severity' : text;
      let filterBy = text == 'generated_time' ? 'desc' : 'asc';
      items = _.orderBy(zoneItems, text, filterBy);
    }
    setZoneItems(items);
  };  

  return (
    <DashboardSection>
      <BarDefaultSMS />
      {!isMapView ? (
        <ZoneOptions>
          <div className="iiq-searchbar flex">
            <SearchBar changeAction={searchFilter} />
            {selectedValue && 
              <SortBy value={selectedValue} changeAction={handleSort} options={sortOptions} type="sort"/>
            }
          </div>
          <div className="flex" style={{
            justifyContent: 'right',
            width: '100%'
          }}>
            <img src={Red} width={15} height={15} onClick={() => handleSeverity('high')}/>
            <h4
              style={{
                color: "white",
                marginLeft: "5px",
                marginRight: "10px",
                fontSize: "15px",
                marginTop : '8px',
                cursor: 'pointer',
                borderBottom: severity == "high" ? "1px solid #fff" : 0
              }}
              onClick={() => handleSeverity('high')}
            >
              High
            </h4>
            <img src={Amber} width={15} height={15} onClick={() => handleSeverity('medium')}/>
            <h4
              style={{
                color: "white",
                marginLeft: "5px",
                marginRight: "10px",
                fontSize: "15px",
                marginTop : '8px',
                cursor: 'pointer',
                borderBottom: severity == "medium" ? "1px solid #fff" : 0
              }}
              onClick={() => handleSeverity('medium')}
            >
              Medium
            </h4>

            <img src={Yellow} width={15} height={15} onClick={() => handleSeverity('low')}/>
            <h4
              style={{
                color: "white",
                marginLeft: "5px",
                marginRight: "10px",
                fontSize: "15px",
                marginTop : '8px',
                cursor: 'pointer',
                borderBottom: severity == "low" ? "1px solid #fff" : 0
              }}
              onClick={() => handleSeverity('low')}
            >
              Low
            </h4>
            <img src={Blue} width={15} height={15} onClick={() => handleSeverity('all')}/>
            <h4
              style={{
                color: "white",
                marginLeft: "5px",
                marginRight: "10px",
                fontSize: "15px",
                marginTop : '8px',
                cursor: 'pointer',
                borderBottom: !severity ? "1px solid #fff" : 0
              }}
              onClick={() => handleSeverity('all')}
            >
              All
            </h4>
          </div>
        </ZoneOptions>
      ) : null}
      {isMapView ? (
        <MainMapView>
          <MapImage src={Map} />
        </MainMapView>
      ) : zoneItems && zoneItems.length > 0 ? (
        <>
          <div className="cards flex">
            {zoneItems.map((card) => {
              // return (
              //   <Card
              //     key={card.zone_id}
              //     card={card}
              //     severity={card.severity}
              //     toggleWipScheduler={setSechudulingZone}
              //   />
              // );
              return (
                <DashboardCard
                  key={card.camera_id}
                  card={card}
                  severity={card.severity}
                  toggleWipScheduler={setSechudulingZone}
                />
              )
            })}
          </div>
          <WipScheduler
            schedulingZone={schedulingZone}
            toggleWipScheduler={setSechudulingZone}
            fetchEquipment={fetchEquipment}
            equipments={equipments}
            createWIP={createWIP}
            getWipDetails={getWipDetails}
          />
        </>
      ) : (
        <div>
          { zones && zones.length > 0 ? 
          <div style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: '#ffffff',
            fontSize: '18px'
          }}> No {severity && severity} severity records! </div>
          :
          <div>
            { isDataLoaded ?
              <div style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                color: '#ffffff',
                fontSize: '18px'
              }}> No records! </div>
            :
              <Spinner />
            }
          </div>
        }
        </div>
      )}
    </DashboardSection>
  );
}

const mapStateToProps = (state, ownProps) => ({
  notifications: appSelectNotifications(state),
  apiCallsInProgress: state.apiCallsInProgress,
});

const mapDispatchToProps = {
  fetchACameras: appFetchACamerasData,
  fetchEquipment: appFetchAllEquipments,
  setSearchFilter: appSetZonesFilter,
  createWIP: zoneScheduleWIP,
  getWipDetails: zoneFecthWIPdetails,
};

export default connect(mapStateToProps, mapDispatchToProps)(Tab);
