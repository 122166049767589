import React, { Component } from "react";
import { Cell } from "../columns/styles";
import {
  TableRowsContainer,
  TableRow,
  RowActionsWrapper,
  RowActionsAligner,
} from "./styles";
import { Switch } from "antd";

class Row extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activePage: 15
    };
  }

  handlePageChange(pageNumber) {
    console.log(`active page is ${pageNumber}`);
    this.setState({ activePage: pageNumber });
  }


  render() {
    const {
      rowData,
      requiredElements = [
        "zone_name",
        "ipaddress",
        "location",
        "unit_id",
      ],
      deleteZone,
      editZone,
    } = this.props;
    return (
      <TableRowsContainer>
        {rowData.map((row) => {
          return (
            <TableRow>
              {requiredElements.map((element, i) => (

                <Cell size={i === 3 ? 15 : 15}>
                  {i === 0 ? (
                   row[element]
                  ) : element === "is_active" ? (
                    <Switch defaultChecked />
                  ) : (
                        `${row[element]}`
                      )}
                </Cell>
              ))}
              { <RowActionsWrapper>
                <RowActionsAligner>
                <a href={'/Viewplantform'} >View</a>
                </RowActionsAligner>
              </RowActionsWrapper> }
            </TableRow>
          );
        })}

      </TableRowsContainer>
    );
  }
}

export default Row;
